package com.lightningtime.views.screens.splitScreens.detailScreens

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningtime.*
import com.lightningtime.draftModel.LazyProperty
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.theming.*
import com.lightningtime.timeManagement.*
import com.lightningtime.views.components.SearchableTab
import com.lightningtime.views.components.queries.SearchField
import com.lightningtime.views.components.queries.queryCondition
import com.lightningtime.views.components.searchableTabs
import com.lightningtime.views.detail
import com.lightningtime.views.screens.splitScreens.tasks.comments.CommentGenerator
import com.lightningtime.views.screens.splitScreens.tasks.comments.parseMentions
import com.lightningtime.views.tooltip
import com.lightningtime.views.screens.splitScreens.tasks.completedStates
import com.lightningtime.views.screens.splitScreens.detailScreens.forms.TaskForm
import kotlin.time.Duration
import kotlin.time.Duration.Companion.ZERO
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds

@Routable("/task/{taskID}")
class DetailedTaskView(val taskID: UUID) :
    DetailScreen {
    override val title: Readable<String>
        get() = shared { task().title }

    override val id: UUID get() = taskID

    /*
    Task Settings
     */
    val task = LazyProperty {
        currentSession().tasks[taskID]() ?: throw NullPointerException("Couldn't Find Task")
    }

    val completed = shared {
        task().state in completedStates
    }.withWrite { completed ->
        val session = handleCurrentSession()
        if (completed) {
            task.value = session.tasks[taskID].modify(
                modification {
                    it.state assign TaskState.Delivered
                    it.emergency assign false
                }
            )!!
        } else {
            task.value = session.tasks[taskID].modify(
                modification { it.state assign TaskState.Active }
            )!!
        }
    }
    val isPullRequest = shared { task().pullRequestLinks.isNotEmpty() }

    /*
    _Timer Things
    */
    private var savedTimer: MutableTimer? = null
    suspend fun createNewTimer(): MutableTimer {
        val task = task.awaitOnce()
        val session = handleCurrentSession()

        var t = Timer(
            user = session.userId,
            organization = task.organization,
            lastStarted = now(),
            accumulatedSeconds = 0L,
            task = taskID,
            project = task.project,
            summary = "",
        )

        t = session.timers.insert(t)() ?: throw NullPointerException("Issue inserting new timer")

        return t.mutable().also { savedTimer = it }
    }

//    suspend fun Timer.mutable(): MutableTimer = MutableTimer(this, task().summary)

    var startup = true
    val timer = shared {
        val selected = TimeInfo.selectedTimer()

        selected?.takeIf { it.parent?.id == taskID }
            ?: (savedTimer
                    ?: TimeInfo.unsubmittedTimers
                        .awaitOnce()
                        .find { it.task == taskID }
                        ?.mutable()
                        ?.also { savedTimer = it }
                )
                .also { taskTimer ->
                    if (startup && selected == null) {
                        val newSelected = taskTimer ?: createNewTimer()

                        TimeInfo.selectedTimer.set(newSelected)
                    }
                    startup = false
                }
    }

    private val previousTimeOnTask = shared {
        currentSession().timeEntries.query(Query(
            condition { it.task eq taskID }
        ))().sumOf { it.durationMilliseconds }
    }

    val totalTimeOnTask: Readable<Duration> = shared {
        return@shared previousTimeOnTask().milliseconds + (timer()?.total?.await() ?: ZERO)
    }

    private val ratio = shared {
        val estimate = task().estimate
        if (estimate == null) 1f
        else (totalTimeOnTask().inWholeMilliseconds / estimate) * 2.7777e-7f // To account for change in units [ms/hour] -> ( (1s/1000ms) * (1h/3600s) )
    }

    /*
    * Bottom Section
    * */

    /*
    Comment Section Stuff
    */

    private val expanded = Property(false)
    private val commentText = Property<String>("")

    private suspend fun ViewWriter.addComment() {
        val session = currentSession()

        val mentions = parseMentions(commentText)

        session.comments.insert(
            CommentGenerator(task(), session.userId).generate(commentText(), mentions)
        )

        commentText.value = ""
    }

    private val comments = shared {
        currentSession().comments.query(Query(
            condition { it.task eq taskID },
            orderBy = sort { it.createdAt.descending() }
        ))()
    }

    private val allMentionedUsers = shared {
        val mentions =
            comments().map { it.mentions }.reduce { accSet, mentions -> accSet + mentions }

        currentSession().users.query(Query(
            Condition.Or(
                mentions.map { mention ->
                    condition { it._id eq mention.userID }
                }
            )
        ))()
    }

    private val autoGeneratedCommentTheme: Theme.() -> Theme = {
        copy(
            background = background.closestColor().highlight(0.2f).withAlpha(0.5f),
            foreground = foreground.closestColor().highlight(0.15f)
        )
    }
    private val userGeneratedCommentTheme: suspend Theme.() -> Theme = {
        copy(
            background = Color.interpolate(
                background.closestColor(),
                appAccentColor(),
                0.4f
            )
        )
    }

    private val commentsTab = SearchableTab(
        name = "Comments",
        icon = Icon.chat,
        searchFields = SearchField.Comments.entries,
        startingField = SearchField.Comments.Content,
        getItems = { query, field ->
            val cond = query?.queryCondition(field) ?: Condition.Always()
            comments().filter { cond(it) }
        },
        renderItem = { comment ->
            col {
                spacing = 0.5.rem

                row {
                    centered - subtext {
                        ::content {
                            val read = comment()
                            "${read.userName}  ( ${read.createdAt.formatRelative()} )"
                        }
                    }

                    centered - onlyWhen { comment().mentions.isNotEmpty() } - row {
                        spacing = 0.5.rem

                        forEachUpdating(
                            shared {
                                comment().mentions.map { mention -> allMentionedUsers().find { it._id == mention.userID } }
                            }
                        ) { user ->
                            emphasized - link {
                                spacing = 0.3.rem

                                subtext { ::content { "@" + user()?.name } }
                            }
                        }
                    }
                }

                themeFromLast { theme ->
                    if (comment().autoGenerated) theme.autoGeneratedCommentTheme()
                    else theme.userGeneratedCommentTheme()
                } - col {
                    text {
                        spacing = 0.65.rem
                        ::content { comment().content }
                    }
                }
            }
        }
    )

    private val timeEntriesTab = SearchableTab(
        name = "Time Entries",
        icon = Icon.timeEntry,
        searchFields = setOf(SearchField.TimeEntries.Summary, SearchField.TimeEntries.User),
        startingField = SearchField.TimeEntries.Summary,
        getItems = { query, field ->
            currentSession().timeEntries.query(Query(
                Condition.And(
                    listOfNotNull(
                        condition { it.task eq taskID },
                        query?.queryCondition(field)
                    )
                ),
                orderBy = sort { it.date.descending() },
            ))()
        },
        renderItem = { entry ->
            fun TextView.info(info: TimeEntry.() -> String) {
                ::content { entry().info() }
            }

            card - col {
                row {
                    expanding - centered - col {
                        spacing = 0.5.rem

                        detail("User") { entry().userName ?: "Not Found" }
                        detail("Date") { entry().date.formatRelative() }
                    }
                    centered - embedded - emphasized - h6 { info { durationMilliseconds.milliseconds.format() } }
                }

                embedded - col {
                    emphasized - text("Summary")
                    text { info { summary } }
                }
            }
        }
    )

    override fun ViewWriter.render() {
        col {
            weight(1.5f) - onlyWhen { !expanded() } - col {

                spacing = 0.8.rem

                row {
                    centered - expanding - row {
                        spacing = 0.5.rem

                        centered - emphasized - checkbox {
                            checked bind completed
                        } in tooltip("Mark Task Delivered")

                        centered - expanding - h3 { ::content { task().title } }
                    }

                    row {
                        spacing = 0.5.rem

                        timerControlPanel(timer, ::createNewTimer)
                    }
                }

                estimateThreatTheme(ratio) - row {
                    h6 {
                        val estimateString = shared {
                            (task().estimate?.let {
                                " / " + it.hours.format(
                                    days = false,
                                    seconds = false,
                                    padFirst = false
                                )
                            } ?: "")
                        }
                        ::content {
                            totalTimeOnTask().format(days = false, seconds = false, padFirst = false) + estimateString()
                        }
                    }

                    centered - expanding - progressBar { ::ratio { this@DetailedTaskView.ratio() } }
                }

                expanding - row {
                    expanding - label {
                        content = "Task Details"
                        scrolls - expanding - card - col {
                            spacing = 1.rem

                            sizeConstraints(height = 2.rem) - row {
                                spacing = 0.75.rem

                                important - splitLink {
                                    spacing = 0.25.rem

                                    to = {
                                        val edits = task.awaitOnce()
                                        TaskForm(edits._id)
                                            .setEditing(edits)
                                            .apply {
                                                isPullRequest.set(this@DetailedTaskView.isPullRequest.awaitOnce())
                                            }
                                    }

                                    icon {
                                        spacing = 0.rem
                                        source = Icon.edit
                                    }
                                } in tooltip("Edit Task Details")

                                centered - h3("Details")
                            }

                            embedded - onlyWhen { isPullRequest() } - col {
                                val prLinks = shared { task().pullRequestLinks.toList() }

                                emphasized - text("Pull Request Links")

//                                separator()

                                col {
                                    spacing = 0.5.rem

                                    forEachUpdating(prLinks) { prLink ->
                                        row {
                                            spacing = 0.1.rem
                                            centered - externalLink {
                                                spacing = 0.1.rem

                                                text {
                                                    ::content { SpecialCharacters.bulletPoint + " " + prLink() }
                                                    wraps = false
                                                    ellipsis = true
                                                }

                                                ::to { prLink() }
                                                newTab = true
                                            }
                                        }
                                    }
                                }
                            }

                            embedded - col {
                                detail("State") { task().state.format() }
                                detail("Emergency") { task().emergency.toString().replaceFirstChar { it.titlecase() } }
                                detail("Priority") { task().priority.toString() }
                                detail("Estimate") { task().estimate?.let { "$it hours" } ?: "No Estimate" }
                                detail("Assigned To") { task().userName ?: "No User Name" }
                                detail("Project") { task().projectName ?: "No Project Name" }
                                detail("Organization") { task().organizationName ?: "No Organization Name" }
                                detail("Created") { task().createdAt.format() }
                                detail("Created By") { task().creatorName ?: "No Creator Name" }
                            }

                            embedded - col {
                                val tags = shared { task().tags.toList() }

                                emphasized - text("Tags")

                                onlyWhen { tags().isNotEmpty() } - StaticColDivider(
                                    expand = true,
                                ).run {
                                    forEachUpdating(tags) {
                                        items.add {
                                            text {
                                                wraps = true
                                                ::content { SpecialCharacters.bulletPoint + " " + it() }
                                            }
                                        }
                                    }
                                    build()
                                }
                                onlyWhen { tags().isEmpty() } - text("None")
                            }

                            space()

                            danger - button {
                                spacing = 0.5.rem
                                centered - row {
                                    icon {
                                        source = Icon.deleteForever
                                    }
                                    centered - text("Delete Task")
                                }
                                onClick {
                                    confirmDanger(
                                        "Delete Task",
                                        "Are you sure you want to delete this task and its associated comments? (This cannot be undone)",
                                        "I'm Sure"
                                    ) {
                                        println("Deleting Task $taskID ('${task().summary}')")
                                        split.reset()

                                        val session = currentSession()

                                        session.tasks[taskID].delete()

                                        session.comments.skipCache.bulkDelete(
                                            condition { it.task eq taskID }
                                        )
                                        session.comments.totallyInvalidate()

                                        println("Deleted!")
                                    }
                                }
                            }
                        }
                    }

                    expanding - label {
                        ::exists { timer.isSelected() }

                        content = "Timer Summary"

                        fieldTheme - expanding - textArea {
                            hint = "Summary"
                            content bind timer.summary
                        }
                    }
                }
            }

            onlyWhen { expanded() } - row {
                h3 { ::content { task().title } }

                separator()

                estimateThreatTheme(ratio) - centered - expanding - progressBar { ::ratio { ratio() } }

                sizeConstraints(minWidth = 5.rem) - centered - h6 {
                    ::content { totalTimeOnTask().format() }
                }
            }

            unpadded - button {
                spacing = 0.1.rem

                row {
                    spacing = 0.5.rem
                    centered - expanding - col { separator() }
                    sizeConstraints(height = 1.2.rem) - centered - icon {
                        ::source {
                            if (expanded()) Icon.chevronDown
                            else Icon.chevronUp
                        }
                    }
                    centered - expanding - col { separator() }
                }
                onClick { expanded.toggle() }
            }

            weight(1f) - col {
                row {
                    expanding - card - rounded - col {
                        val showPinnedComment = Property(true)

                        sizeConstraints(height = 1.25.rem) - row {
                            atStart - subtext("Description")

//                            space()
//                            separator()
//                            space()
//
//                            centered - subtext { ::content { "Not A User" } }  // TODO: Change to pin comment's user when models are updated

                            expanding - space()

                            button {
                                spacing = 0.rem
                                icon {
                                    ::source {
                                        if (showPinnedComment()) Icon.hide
                                        else Icon.show
                                    }
                                }
                                onClick { showPinnedComment.toggle() }
                            }
                        }

                        scrolls - sizeConstraints(maxHeight = 9.rem) - onlyWhen { showPinnedComment() } - text { ::content { task().description } }
                    }
                }


                expanding - rounded - stack {
                    card - col {
                        searchableTabs(commentsTab, timeEntriesTab)

                        sizeConstraints(maxHeight = 5.rem) - fieldTheme - onlyWhen { expanded() && commentsTab.selected() } - row {
                            expanding - textArea {
                                hint = "Add to the conversation.."
                                content bind commentText

                                reactiveScope {
                                    if (expanded()) requestFocus()
                                }

                            }
                            removeOutline - sizeConstraints(width = 3.rem) - button {
                                spacing = 0.rem
                                centered - icon { source = Icon.send }
                                onClick {
                                    if (commentText().isNotBlank()) addComment()
                                }
                            }
                        }
                    }

                    atBottomEnd - padded - row {
                        spacing = 0.5.rem

                        important - button {
                            ::exists { !expanded() && commentsTab.selected() }
                            icon { source = Icon.chat }
                            onClick { expanded.value = true }
                        }
                    }
                }
            }
        }
    }
}
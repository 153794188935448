package com.lightingtime.permissions

import kotlinx.serialization.Serializable

@Serializable
enum class UserRole {
    Owner,
    InternalTeamMember,
    Contractor,
    Client,
    ClientNoBilling,
    ClientTesting,
    ExternalTeamMember,
}
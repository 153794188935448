@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareProjectFields() {
    val props: Array<SerializableProperty<Project, *>> = arrayOf(Project__id, Project_organization, Project_name, Project_rate, Project_notes, Project_createdAt, Project_projectTags)
    Project.serializer().properties { props }
}
val <K> DataClassPath<K, Project>._id: DataClassPath<K, UUID> get() = this[Project__id]
val <K> DataClassPath<K, Project>.organization: DataClassPath<K, UUID> get() = this[Project_organization]
val <K> DataClassPath<K, Project>.name: DataClassPath<K, String> get() = this[Project_name]
val <K> DataClassPath<K, Project>.rate: DataClassPath<K, Float?> get() = this[Project_rate]
val <K> DataClassPath<K, Project>.notes: DataClassPath<K, String> get() = this[Project_notes]
val <K> DataClassPath<K, Project>.createdAt: DataClassPath<K, Instant> get() = this[Project_createdAt]
val <K> DataClassPath<K, Project>.projectTags: DataClassPath<K, Set<String>> get() = this[Project_projectTags]
inline val Project.Companion.path: DataClassPath<Project, Project> get() = path<Project>()


object Project__id: SerializableProperty<Project, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Project): UUID = receiver._id
    override fun setCopy(receiver: Project, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("_id")
}
object Project_organization: SerializableProperty<Project, UUID> {
    override val name: String = "organization"
    override fun get(receiver: Project): UUID = receiver.organization
    override fun setCopy(receiver: Project, value: UUID) = receiver.copy(organization = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("organization")
}
object Project_name: SerializableProperty<Project, String> {
    override val name: String = "name"
    override fun get(receiver: Project): String = receiver.name
    override fun setCopy(receiver: Project, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("name")
}
object Project_rate: SerializableProperty<Project, Float?> {
    override val name: String = "rate"
    override fun get(receiver: Project): Float? = receiver.rate
    override fun setCopy(receiver: Project, value: Float?) = receiver.copy(rate = value)
    override val serializer: KSerializer<Float?> = Float.serializer().nullable
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("rate")
}
object Project_notes: SerializableProperty<Project, String> {
    override val name: String = "notes"
    override fun get(receiver: Project): String = receiver.notes
    override fun setCopy(receiver: Project, value: String) = receiver.copy(notes = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("notes")
}
object Project_createdAt: SerializableProperty<Project, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Project): Instant = receiver.createdAt
    override fun setCopy(receiver: Project, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("createdAt")
}
object Project_projectTags: SerializableProperty<Project, Set<String>> {
    override val name: String = "projectTags"
    override fun get(receiver: Project): Set<String> = receiver.projectTags
    override fun setCopy(receiver: Project, value: Set<String>) = receiver.copy(projectTags = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Project.serializer().tryFindAnnotations("projectTags")
}

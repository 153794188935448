package com.lightningtime.views.components

import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.ScreenTransition
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningtime.theming.embedded
import com.lightningtime.theming.lightOutline
import com.lightningtime.views.components.queries.SearchField
import com.lightningtime.views.components.queries.searchBar
import com.lightningtime.views.tools.tabs.TabManager
import com.lightningtime.views.tools.tabs.TabScreen

class SearchableTabManager(
    tabs: Collection<SearchableTab<*, out SearchField<*>>>,
): TabManager<SearchableTab<*, out SearchField<*>>>(tabs) {
    internal val query = Property("")
    private val fields = shared { selectedTab().searchFields.toList() }
    private val selectedSearchType = shared {
        selectedTab().searchType()
    }.withWrite { selectedTab().setSearchType(it) }

    var transition: (SearchableTab<*, out SearchField<*>>) -> ScreenTransition = { ScreenTransition.Fade }

    init {
        tabScreens.forEach { it.manager = this }
    }

    private val breakpoint = 75.rem

    internal fun ViewWriter.render() {
        expanding - col {
            sizeConstraints(height = 3.rem) - row {
                spacing = 0.5.rem

                row {
                    spacing = 0.5.rem

                    tabBar {
                        spacing = 0.75.rem
                        ::showTitle { WindowInfo().width > breakpoint }
                    }
                }

                space()

                expanding - searchBar(query, selectedSearchType, fields)
            }

            expanding - mainTabBody(transition)
        }
    }
}

fun ViewWriter.searchableTabs(vararg tabs: SearchableTab<*, out SearchField<*>>, setup: SearchableTabManager.() -> Unit = {}) =
    SearchableTabManager(tabs.toList()).run { setup() ; render() }

class SearchableTab<T, SEARCH: SearchField<in T>>(
    name: String,
    override val icon: Icon? = null,
    val searchFields: Collection<SEARCH>,
    val startingField: SEARCH? = null,
    val getItems: suspend CalculationContext.(query: Readable<String>?, field: Readable<SEARCH>) -> List<T>,
    val renderItem: ViewWriter.(Readable<T>) -> Unit
): TabScreen {
    override val title: Readable<String> = Constant(name)

    internal val searchType = Property(startingField ?: searchFields.first())

    internal fun setSearchType(type: SearchField<*>) {
        searchType.value = (type as SEARCH)
    }

    internal var manager: SearchableTabManager? = null
    private val query: Property<String>? = manager?.query

    val selected: Readable<Boolean> = shared { manager?.selectedTab() === this@SearchableTab }

    private val items = shared(true) {
        getItems(query, searchType)
    }

    override fun ViewWriter.render() {
        expanding - embedded - col {
            expanding - recyclerView {
                children(items, renderItem)
            }
        }
    }
}
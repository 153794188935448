package com.lightningtime.views.tools.splitscreens

import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.ScreenTransition
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningtime.views.emptyView

interface SplitScreenManager: Screen {
    val splitScreenNavigator: SplitScreenNavigator<*>

    val breakpoint: Dimension
    val separator: ViewWriter.() -> Unit get() = { separator() }

    val primary: Screen

    val default: Screen get() = object: Screen {
        override fun ViewWriter.render() {
            emptyView { centered - h3("Nothing Selected") }
        }
    }

    val swapTransition: (Screen) -> ScreenTransition get() = { ScreenTransition.Fade }

    var narrow: Boolean
        get() = splitScreenNavigator.narrow
        set(value) { splitScreenNavigator.narrow = value }

    private fun ViewWriter.renderWide() {
        row {
            expanding - with(primary) { render() }

            with(separator) { invoke(this@renderWide) }

            expanding - swapView {
                swapping(
                    transition = swapTransition,
                    current = {
                        splitScreenNavigator.selected.await() ?: default
                    },
                    views = {
                        with(it) { render() }
                    }
                )
            }
        }
    }

    private fun ViewWriter.renderNarrow() {
        with(primary) { render() }
    }

    override fun ViewWriter.render() {
        stack {
            val split = split()

            if (narrow) split.renderNarrow()
            else split.renderWide()

            reactiveScope {
                val width = WindowInfo().width

                if (width > breakpoint && narrow) {
                    narrow = false
                    native.clearNViews()

                    split.renderWide()
                }
                if (width < breakpoint && !narrow) {
                    this@SplitScreenManager.splitScreenNavigator.reset()
                    narrow = true
                    native.clearNViews()

                    split.renderNarrow()
                }
            }
        }
    }
}

interface SplitScreen<NAVIGATES: Screen>: Screen {
    val split: SplitScreenNavigator<NAVIGATES>

    class SplitLink<NAVIGATE: Screen?>(var to: suspend () -> NAVIGATE) {
        var spacing: Dimension = 1.rem
    }

    @ViewDsl
    fun ViewWriter.splitLink(setup: SplitLink<NAVIGATES?>.() -> Unit) = button {
        val slink = SplitLink<NAVIGATES?> { null }.apply {
            spacing = native.spacing.takeUnless { it == 0.px } ?: 1.rem
            setup()
        }
        spacing = slink.spacing

        onClick {
            val screen = slink.to()
            if (screen == null) split.reset()
            else split.navigate(screen)
        }
    }
}
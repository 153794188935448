package com.lightningtime.views.screens.splitScreens.detailScreens.forms

import com.lightningtime.validation.Validator
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailScreen

// TODO: Make FormScreens open as dialogs in narrow views

abstract class FormScreen<T>: DetailScreen, Validator() {
    var editing: Boolean = false

    abstract fun setEditing(original: T): FormScreen<T>
}
package com.lightningtime.views.screens.splitScreens.tasks

import com.lightingtime.*
import com.lightingtime.Project
import com.lightingtime.Task
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningtime.*
import com.lightningtime.draftModel.LazyProperty
import com.lightningtime.draftModel.withTransform
import com.lightningtime.sdk.currentSession
import com.lightningtime.theming.*
import com.lightningtime.views.tools.splitscreens.SplitScreen
import com.lightningtime.views.tools.splitscreens.SplitScreenNavigator
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailScreen
import com.lightningtime.views.tooltip
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedProjectView
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedTaskView
import com.lightningtime.views.screens.splitScreens.detailScreens.forms.TaskForm
import com.lightningtime.views.screens.splitScreens.splitNavigator
import com.lightningtime.timeManagement.TimeInfo
import com.lightningtime.views.components.queries.*
import kotlinx.serialization.Serializable

@Routable("tasks/tree")
class TaskTree: SplitScreen<DetailScreen> {
    override val split: SplitScreenNavigator<DetailScreen> = splitNavigator

    @Serializable
    enum class TaskSort { Priority, Creation, Name }

    val query = Property<String>("")
    val searchField = Property(SearchField.Tasks.Summary)

    val filterByUser = LazyProperty<UUID?> { currentSession().userId }
    val filterByState = PersistentProperty("state-filter", activeStates)
    val filterByProject = Property<UUID?>(null)
//    val filterByCompletion = Property<Boolean?>(null)   //true: show only completed, false: show only active, null: show both

    val sortBy = PersistentProperty("task-sort", TaskSort.Priority)
    val prioritizeEmergencies = PersistentProperty("prioritize-emergencies", true)
    val showRecents = PersistentProperty("show-recents", true)
    val sortDescending = PersistentProperty("sort-descending", true)

    private val favoriteProjects = shared {
        currentSession().self().projectFavorites
    }
    val filteredTasks = shared {
        currentSession().tasks.query(Query(
            Condition.Or(listOfNotNull(
                if (showRecents() && (filterByProject() == null)) Condition.Or(
                    listOf(
                        condition { task -> task._id inside TimeInfo.unsubmittedTimers().mapNotNull { it.task } },
                        condition { task -> task._id inside TimeInfo.timeEntriesFromPastWeek().mapNotNull { it.task } }
                    )
                ) else null,
                Condition.And(listOfNotNull(
                    query.queryCondition(searchField),
                    filterByUser()?.let { user -> condition { it.user eq user } },
                    filterByState.debounce(800)().let { states -> condition { it.state inside states } },
                    filterByProject()?.let { project ->
                        if (project == MockProjects.favorites._id) {
                            val favorites = favoriteProjects()
                            condition { it.project inside favorites }
                        }
                        else condition { it.project eq project }
                    }

//                    filterByCompletion()?.let { completed -> when(completed){
//                        true -> condition { it.state inside setOf(TaskState.Hold, TaskState.Cancelled, TaskState.Delivered, TaskState.Approved) }
//                        false -> condition { it.state inside setOf(TaskState.Active, TaskState.PullRequest, TaskState.Testing) }
//                    }},
                ))
            )),
            orderBy = when (sortBy()) {
                TaskSort.Priority -> sort { it.priority.apply { if (sortDescending()) descending() else ascending() } }
                TaskSort.Creation -> sort { it.createdAt.apply { if (sortDescending()) descending() else ascending() } }
                TaskSort.Name -> sort { it.summary.apply { if (sortDescending()) descending() else ascending() } }
            },
            limit = 200
        ))()
    }

    val formattedItems = shared {
        val session = currentSession()
        val tasks = if (prioritizeEmergencies()) {
            filteredTasks().sortedByDescending { it.emergency }
        } else filteredTasks()

        val projIDs = tasks.map { it.project }.toSet()
        var projects = session.projects.query(Query(
            condition {
                it._id inside projIDs
            }
        ))()

        val recentsGroup = if (showRecents()) {
            val timerTasks = TimeInfo.unsubmittedTimers().mapNotNull { it.task }
            val entryTasks = TimeInfo.timeEntriesFromPastWeek().mapNotNull { it.task } - timerTasks.toSet()   // Removing duplicates

            val filtered = tasks.filter { it._id in timerTasks } + tasks.filter { it._id in entryTasks }.take(5)

            projects = projects + MockProjects.recents

            mapOf( MockProjects.recents._id to filtered )
        } else emptyMap()

        val faves = favoriteProjects()
        val projectGroups = recentsGroup + tasks.groupBy { it.project }.toList().sortedByDescending { (id, _) -> id in faves }

        return@shared projectGroups.flatMap { (project, tasks) ->
            val p = projects.find { it._id == project }
            listOfNotNull<TaskTreeItem>(p) + tasks
        }
    }

    override fun ViewWriter.render() {
        col {
            sizeConstraints(height = 3.rem) - row {
                centered - h2("TaskTree")

                weight(1f) - space()

                weight(2f) - centered - searchBar(query, searchField, SearchField.Tasks.entries)

                centered - menuButton {
                    spacing = 0.1.rem
                    preferredDirection = PopoverPreferredDirection.belowLeft
                    icon { source = Icon.filterList }

                    fun ViewWriter.labeledRadioButton(text: String, setup: RadioButton.() -> Unit) {
                        row {
                            radioButton(setup)
                            centered - expanding - text(text)
                        }
                    }

                    opensMenu {
                        centered - row {
                            col {
                                emphasized - h3("Filter By")

                                separator()

                                col {
                                    label {
                                        content = "User"
                                        val users = shared {
                                            listOf(null) + currentSession().users.query(Query(
                                                orderBy = sort { it.name.ascending() }
                                            ))()
                                        }
                                        val map = shared { users().associateBy { it?._id } }
                                        fieldTheme - select {
                                            bind(
                                                edits = filterByUser.withTransform(
                                                    inputTransform = { it?._id },
                                                    outputTransform = { selected -> map()[selected] }
                                                ),
                                                data = users,
                                                render = { it?.name ?: "All Users" }
                                            )
                                        }
                                    }
                                    label {
                                        content = "Project"

                                        val allProjects = shared {
                                            val session = currentSession()
                                            listOf(null, MockProjects.favorites) + session.projects.query(Query(
                                                orderBy = sort { it.name.ascending() },
                                                limit = 250
                                            ))()
                                        }

                                        fieldTheme - select {
                                            bind(
                                                edits = filterByProject.withTransform<UUID?, Project?>(
                                                    inputTransform = { it?._id },
                                                    outputTransform = { selected ->
                                                        if (selected == null) null
                                                        else allProjects().find { it?._id == selected }
                                                    }
                                                ),
                                                data = allProjects,
                                                render = { it?.name ?: "All Projects" }
                                            )
                                        }
                                    }
                                    label {
                                        content = "State"

//                                    labeledRadioButton("Active") { checked bind filterByCompletion.equalTo(false) }
//                                    labeledRadioButton("Completed") { checked bind filterByCompletion.equalTo(true) }
//                                    labeledRadioButton("Both") { checked bind filterByCompletion.equalTo(null) }

                                        dividedCol(2, ColBuildStyle.ByColumn) {
                                            for (state in TaskState.entries) {
                                                items.add {
                                                    row {
                                                        checkbox { checked bind filterByState.contains(state) }
                                                        centered - text(state.format())
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            separator()

                            col {
                                emphasized - h3("Sort By")

                                separator()

                                col {
                                    for (sort in TaskSort.entries) {
                                        labeledRadioButton(sort.toString()) { checked bind sortBy.equalTo(sort) }
                                    }
                                }

                                space()

                                label {
                                    content = "Sort Direction"

                                    fieldTheme - button {
                                        spacing = 0.75.rem
                                        centered - row {
                                            spacing = 0.25.rem
                                            sizeConstraints(height = 1.25.rem) - centered - icon {
                                                ::source {
                                                    if (sortDescending()) Icon.downArrow
                                                    else Icon.upArrow
                                                }
                                            }

                                            centered - text {
                                                ::content {
                                                    if (sortDescending()) "Descending"
                                                    else "Ascending"
                                                }
                                            }

                                            space()
                                        } in tooltip {
                                            if (sortDescending()) "Switch to Ascending"
                                            else "Switch to Descending"
                                        }

                                        onClick {
                                            sortDescending.toggle()
                                        }
                                    }
                                }

                                separator()

                                row {
                                    card - switch { checked bind prioritizeEmergencies }
                                    centered - text("Emergencies First")
                                }
                                row {
                                    card - switch { checked bind showRecents }
                                    centered - text("Show Recents")
                                }
                            }
                        }
                    }
                }
            }

            separator()

            expanding - recyclerView {
                val rowSpacing = 0.3.rem
                spacing = rowSpacing

                children(formattedItems) { item ->
                    val iconned = shared {
                        val readItem = item()
                        (readItem is Task) && (readItem.emergency || readItem.state in completedStates)
                    }

                    val indentWidth = 2.rem
                    fun ViewWriter.indent(level: suspend () -> Int) {
                        row {
                            spacing = 0.rem
                            forEachUpdating(shared { (1..level()).toList() }) {
                                sizeConstraints(width = indentWidth) - col()
                            }
                        }
                    }

                    row {
                        spacing = 0.rem

                        val numIndents = 1
                        onlyWhen { item() is Task } - indent { if (iconned()) numIndents - 1 else numIndents }

                        maybeThemeFromLast { theme ->
                            val i = item()
                            if (i._id == split.selected()?.id) theme.selected()
                            else if (i is Project) emphasizedTheme(theme)
                            else null
                        } - expanding - row {

                            val buttonSpacing = 0.6.rem
                            spacing = 0.rem

                            expanding - splitLink {
                                spacing = buttonSpacing

                                to = {
                                    when (val read = item()) {
                                        is Task -> DetailedTaskView(read._id)
                                        is Project -> DetailedProjectView(read._id)
                                    }
                                }

                                row {
                                    spacing = buttonSpacing

                                    sizeConstraints(width = (indentWidth - buttonSpacing)) - centered - onlyWhen { iconned() } - icon {
                                        ::source {
                                            val task = item()
                                            if (task is Task) {
                                                if (task.emergency) Icon.warning
                                                else if (task.state in completedStates) Icon.done
                                                else Icon.empty
                                            } else Icon.warning
                                        }
                                    }

                                    expanding - centered - h6 { ::content { item().title } }
                                }
                            }

                            centered - onlyWhen {
                                val read = item()
                                (read._id != MockProjects.recents._id) and (read is Project || read._id == split.selected()?.id)
                            } - splitLink {
                                spacing = buttonSpacing

                                to = { TaskForm(item()._id) }

                                row {
                                    spacing = 0.5.rem
                                    icon {
                                        spacing = 0.rem
                                        source = Icon.add
                                    }
                                    centered - text("Create Task")
//                                            text { ::content {
//                                        when (val read = item()) {
//                                            is Project -> "Create Task"
//                                            is Task -> "Create Subtask"
//                                        }
//                                    } }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
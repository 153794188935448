package com.lightningtime.views.screens

import com.lightningkite.kiteui.navigation.*
import com.lightningtime.views.screens.LandingScreen
import com.lightningtime.views.screens.SettingsScreen
import com.lightningtime.views.screens.auth.LoginScreen
import com.lightningtime.views.screens.auth.PasscodeScreen
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedProjectView
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedTaskView
import com.lightningtime.views.screens.splitScreens.detailScreens.TimersScreen
import com.lightningtime.views.screens.splitScreens.detailScreens.forms.ProjectForm
import com.lightningtime.views.screens.splitScreens.detailScreens.forms.TaskForm
import com.lightningtime.views.screens.splitScreens.projects.ProjectsScreen
import com.lightningtime.views.screens.splitScreens.tasks.TaskTree
import com.lightningtime.views.screens.splitScreens.tasks.TasksScreen
import com.lightningtime.views.screens.timeEntry.TimeEntryHistoryScreen
import com.lightningtime.views.screens.timeEntry.TimeEntryScreen
import com.lightningkite.kiteui.navigation.Screen.Empty
import com.lightningkite.UUID


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 0) return@label null
            LandingScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "settings") return@label null
            SettingsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "login") return@label null
            LoginScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "passcode") return@label null
            PasscodeScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "project") return@label null
            DetailedProjectView(
                projectID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "task") return@label null
            DetailedTaskView(
                taskID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "time-entry") return@label null
            if (it.segments[1] != "timers") return@label null
            TimersScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "projects") return@label null
            if (it.segments[1] != "form") return@label null
            ProjectForm(
                projectID = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "tasks") return@label null
            if (it.segments[1] != "form") return@label null
            TaskForm(
                parentID = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "projects") return@label null
            ProjectsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "tasks") return@label null
            if (it.segments[1] != "tree") return@label null
            TaskTree(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "tasks") return@label null
            TasksScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "time-entry") return@label null
            if (it.segments[1] != "history") return@label null
            TimeEntryHistoryScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "time-entry") return@label null
            TimeEntryScreen(
            ).apply {
            }
        },
    ),
    renderers = mapOf(
        LandingScreen::class to label@{
            if (it !is LandingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        SettingsScreen::class to label@{
            if (it !is SettingsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("settings"),
                parameters = p
            ), listOf())
        },
        LoginScreen::class to label@{
            if (it !is LoginScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "login"),
                parameters = p
            ), listOf())
        },
        PasscodeScreen::class to label@{
            if (it !is PasscodeScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "passcode"),
                parameters = p
            ), listOf())
        },
        DetailedProjectView::class to label@{
            if (it !is DetailedProjectView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("project", UrlProperties.encodeToString(it.projectID)),
                parameters = p
            ), listOf())
        },
        DetailedTaskView::class to label@{
            if (it !is DetailedTaskView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("task", UrlProperties.encodeToString(it.taskID)),
                parameters = p
            ), listOf())
        },
        TimersScreen::class to label@{
            if (it !is TimersScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("time-entry", "timers"),
                parameters = p
            ), listOf())
        },
        ProjectForm::class to label@{
            if (it !is ProjectForm) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("projects", "form", UrlProperties.encodeToString(it.projectID)),
                parameters = p
            ), listOf())
        },
        TaskForm::class to label@{
            if (it !is TaskForm) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tasks", "form", UrlProperties.encodeToString(it.parentID)),
                parameters = p
            ), listOf())
        },
        ProjectsScreen::class to label@{
            if (it !is ProjectsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("projects"),
                parameters = p
            ), listOf())
        },
        TaskTree::class to label@{
            if (it !is TaskTree) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tasks", "tree"),
                parameters = p
            ), listOf())
        },
        TasksScreen::class to label@{
            if (it !is TasksScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tasks"),
                parameters = p
            ), listOf())
        },
        TimeEntryHistoryScreen::class to label@{
            if (it !is TimeEntryHistoryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("time-entry", "history"),
                parameters = p
            ), listOf())
        },
        TimeEntryScreen::class to label@{
            if (it !is TimeEntryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("time-entry"),
                parameters = p
            ), listOf())
        },
    ),
    fallback = Empty
)

package com.lightningtime.views.screens.timeEntry

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.separator
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.minus
import com.lightningtime.theming.lightOutline
import com.lightningtime.views.screens.splitScreens.detailScreens.TimersScreen
import com.lightningtime.views.tools.tabs.TabManager
import com.lightningtime.views.tools.tabs.tabs

@Routable("time-entry")
class TimeEntryScreen: Screen {
    private val tabs = listOf(TimersScreen(), TimeEntryHistoryScreen())

    override fun ViewWriter.render() {
        tabs(tabs) {
            col {
                row {
                    for (tab in tabScreens) {
                        lightOutline - tab(tab)
                    }
                }

                expanding - lightOutline - mainTabBody()
            }
        }
    }
}
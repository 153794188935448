package com.lightningtime

import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.spacing
import com.lightningkite.kiteui.views.direct.stack
import kotlin.math.ceil

interface ColDivider {
    val buildStyle: ColBuildStyle
    fun ViewWriter.build()
}

enum class ColBuildStyle {
    LeftToRight, ByColumn
}

class StaticColDivider(
    val numCols: Int = 2,
    override val buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight,
    val expand: Boolean = false,
    val spacing: Dimension? = null
): ColDivider {
    val items = ArrayList<ViewWriter.() -> Unit>()

    override fun ViewWriter.build() = when (buildStyle) {
        ColBuildStyle.ByColumn -> row {
            val splitContents = items.chunked( (items.size + numCols - 1)/numCols )

            if (items.isNotEmpty()) repeat(numCols) { index ->
                if (expand) expanding
                col {
                    this@StaticColDivider.spacing?.let { spacing = it }

                    splitContents[index].forEach { it.invoke(this@build) }
                }
            }
        }

        ColBuildStyle.LeftToRight -> row {
            val splitContents = items.withIndex().groupBy { it.index % numCols }

            if (items.isNotEmpty()) repeat(numCols) { index ->
                if (expand) expanding
                col {
                    this@StaticColDivider.spacing?.let { spacing = it }

                    splitContents[index]?.forEach { it.value.invoke(this@build) }
                }
            }
        }
    }
}

class ReactiveColDivider<T>(
    val numCols: Int,
    override val buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight,
    val items: Readable<Iterable<T>>,
    val render: ViewWriter.(T) -> Unit
): ColDivider {
    override fun ViewWriter.build() {
        stack {
            val split = split()

            reactiveScope {
                native.clearNViews()

                StaticColDivider(numCols, buildStyle).apply {
                    items.addAll(this@ReactiveColDivider.items.await().map { { render(it) } })
                    split.build()
                }
            }
        }
    }
}

fun <T> ViewWriter.dividedColUpdating(
    numCols: Int,
    items: Readable<Iterable<T>>,
    buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight,
    render: ViewWriter.(T) -> Unit
) {
    with(ReactiveColDivider(numCols, buildStyle, items, render)) { build() }
}

//class DynamicColDivider(
//    val colWidth: Dimension? = null,
//    override val buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight
//): ColDivider {
//    override val items = ArrayList<ViewWriter.() -> Unit>()
//
//    override fun ViewWriter.build() = when(buildStyle) {
//        ColBuildStyle.LeftToRight -> row {
//            val split = split()
//
//            reactiveScope {
//                val width = WindowInfo
//            }
//        }
//
//        ColBuildStyle.ByColumn -> {
//        }
//    }
//}

@ViewDsl
fun ViewWriter.dividedCol(
    numCols: Int = 2,
    buildStyle: ColBuildStyle = ColBuildStyle.LeftToRight,
    setup: StaticColDivider.() -> Unit
) = StaticColDivider(numCols, buildStyle).run {
    setup()
    build()
}


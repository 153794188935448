package com.lightningtime.views.screens.splitScreens.detailScreens

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.spacing
import com.lightningkite.lightningdb.*
import com.lightningtime.draftModel.*
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.theming.*
import com.lightningtime.timeManagement.*
import com.lightningtime.toggle
import com.lightningtime.validation.*
import com.lightningtime.views.emptyView
import com.lightningtime.views.screens.splitScreens.splitNavigator
import com.lightningtime.views.tools.tabs.TabScreen
import com.lightningtime.views.tooltip
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.time.Duration.Companion.seconds

@Routable("time-entry/timers")
class TimersScreen : DetailScreen, TabScreen {
    override val id: UUID? = null
    override val icon: Icon = Icon.clock

    val timers = TimeInfo.unsubmittedTimers

    private val projects = shared {
        val referencedProjects = timers().mapNotNull { it.project }
        listOf(null) + currentSession().projects.query(
            Query(
                condition { it._id inside referencedProjects },
                orderBy = sort { it.name.ascending() }
            )
        )()
    }

    private val tasks = shared {
        val referencedProjects = timers().mapNotNull { it.project }
        currentSession().tasks.query(
            Query(
                condition { it.project inside referencedProjects },
                orderBy = sort { it.summary.ascending() },
                limit = Int.MAX_VALUE
            )
        )()
    }

    private suspend fun saveTimeEntry(timer: MutableTimer) {
        val session = handleCurrentSession()

        val saved = timer.savedTimer.awaitOnce()

        session.timeEntries.insert(
            TimeEntry(
                task = saved.task,
                project = saved.project!!,
                organization = saved.organization,
                user = session.userId,
                summary = saved.summary,
                durationMilliseconds = (saved.accumulatedSeconds * 1000).toInt(),
                date = now().toLocalDateTime(TimeZone.currentSystemDefault()).date
            )
        )() ?: throw NullPointerException("TimeEntry not created successfully")

        saved.task?.let {
            session.comments.insert(
                Comment(
                    user = session.userId,
                    task = it,
                    project = saved.project!!,
                    organization = saved.organization,
                    mentions = emptySet(),
                    autoGenerated = true,
                    content = """
                            Time Entry [${saved.accumulatedSeconds.seconds.format()}]
                            ${saved.summary}
                        """.trimIndent()
                )
            )
        }

        timer.delete()
    }

    private val expandList = ArrayList<Property<Boolean>>()

    private fun ViewWriter.timerCard(timer: Readable<MutableTimer>) = validating {
        card - col {
            row {
                expanding - stack {
                    spacing = 0.rem

                    gravity(Align.Start, Align.Center) - button {
                        spacing = 0.rem

                        h3 { ::content { timer().name() ?: "Blank Timer" } }

                        onClick {
                            timer().parentScreen?.let { splitNavigator.externalNavigate(it) }
                        }
                    }
                }

                timerControlPanel(timer)
            }

            val expanded = Property(false).also { expandList.add(it) }

            onlyWhen { expanded() } - col {

                row {
                    val project = timer.getProperty { project }.withTransform<UUID?, Project?>(
                        inputTransform = { it?._id },
                        outputTransform = { id -> projects().find { it?._id == id } }
                    ).validateNotNull()

                    expanding - label {
                        content = "Project"

                        fieldTheme - validate(project) - select {
                            bind(
                                edits = project,
                                data = projects,
                                render = { it?.name ?: "Unselected" }
                            )
                        }
                    }

                    expanding - label {
                        content = "Task"

                        val filteredTasks = shared {
                            val proj = timer().project.await()
                            listOf(null) + tasks().filter { it.project == proj }
                        }

                        val task = timer.getProperty { task }.withTransform<UUID?, Task?>(
                            inputTransform = { it?._id },
                            outputTransform = { id -> filteredTasks().find { it?._id == id } }
                        )

                        fieldTheme - select {
                            ::enabled { project.valid() }

                            bind(
                                edits = task,
                                data = filteredTasks,
                                render = { it?.summary ?: "Unselected" }
                            )
                        }
                    }
                }

                label {
                    content = "Summary"

                    val summary = timer.summary.validate { it.isNotBlank() }

                    fieldTheme - validate(summary) - textArea {
                        content bind summary
                    }
                }

                sizeConstraints(height = 3.5.rem) - row {
                    lightOutline - button {
                        centered - row {
                            centered - icon { source = Icon.deleteForever }
                            centered - h6("Delete Timer")
                        }
                        onClick {
                            val read = timer()

                            if (read.accumulatedSeconds > 60)
                                confirmDanger(
                                    "Delete Timer",
                                    "Are you sure you want to delete this timer? It has more than 1 minute logged on it.",
                                    "I'm sure"
                                ) { read.delete() }
                            else read.delete()
                        }
                    }

                    expanding - space()

                    important - button {
                        ::enabled { allValid() }

                        centered - h6("Submit")

                        onClick {
                            val read = timer()
                            read.draft.commit()
                            saveTimeEntry(read)
                        }
                    }
                }
            }

            button {
                spacing = 0.rem
                row {
                    spacing = 0.5.rem

                    centered - expanding - col { separator() }

                    sizeConstraints(height = 1.rem) - centered - icon {
                        ::source {
                            if (expanded()) Icon.chevronUp
                            else Icon.chevronDown
                        }
                    }

                    centered - expanding - col { separator() }
                }

                onClick { expanded.toggle() }
            }
        }
    }

    override fun ViewWriter.render() {
        removeOutline - col {
            row {
                centered - expanding - h2("Timers")

                val allExpanded = Property(false)
                sizeConstraints(aspectRatio = Pair(1, 1)) - lightOutline - button {
                    spacing = 0.5.rem
                    centered - sizeConstraints(height = 1.5.rem) - icon {
                        ::source { if (allExpanded()) Icon.collapseAll else Icon.expandAll }
                    }

                    onClick {
                        allExpanded.toggle()
                        allExpanded().let { expanded -> expandList.forEach { it.value = expanded } }
                    }
                } in tooltip(PopoverPreferredDirection.belowRight) {
                    if (allExpanded()) "Collapse All" else "Expand All"
                }

                sizeConstraints(height = 3.rem) - important - button {
                    centered - row {
                        centered - icon { source = Icon.addClock }

                        centered - bold - text("Add Timer")
                    }

                    onClick {
                        val session = currentSession()
                        val self = session.self()

                        val newTimer = session.timers.insert(
                            Timer(
                                user = self._id,
                                organization = self.organization
                            )
                        )() ?: throw NullPointerException("Problem inserting new timer")

                        TimeInfo.selectedTimer set newTimer.mutable("Blank Timer")
                    }
                }
            }

            separator()

            expanding - scrolls - col {
                ::exists { timers().isNotEmpty() }

                forEachUpdating(timers.map { it.mutable() }) { timerCard(it) }
            }

            expanding - emptyView {
                ::exists { timers().isEmpty() }

                centered - h3("No timers yet")
            }
        }
    }
}
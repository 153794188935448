package com.lightningtime.validation

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.themeFromLast


@ViewModifierDsl3
fun ViewWriter.validate(validated: Validated): ViewWrapper = themeFromLast { theme ->
    if (validated.valid()) theme
    else validated.validator.invalidTheme(theme)
}

@ViewModifierDsl3
fun ViewWriter.validate(validated: Readable<Validated>): ViewWrapper = themeFromLast { theme ->
    val v = validated()
    if (v.valid()) theme
    else v.validator.invalidTheme(theme)
}

@ViewModifierDsl3
fun ViewWriter.validate(validCondition: ValidCondition, invalidTheme: Theme.() -> Theme = { warning() }): ViewWrapper = themeFromLast { theme ->
    if (validCondition.valid()) theme
    else theme.invalidTheme()    // TODO: This should be changed to invalid once invalid themes are added to KiteUI themes
}

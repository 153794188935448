@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareUserFields() {
    val props: Array<SerializableProperty<User, *>> = arrayOf(User__id, User_email, User_organization, User_name, User_webPreferences, User_theme, User_themeColor, User_summaryTime, User_isSuperUser, User_role, User_currentTask, User_projectFavorites, User_limitToProjects, User_active)
    User.serializer().properties { props }
}
val <K> DataClassPath<K, User>._id: DataClassPath<K, UUID> get() = this[User__id]
val <K> DataClassPath<K, User>.email: DataClassPath<K, String> get() = this[User_email]
val <K> DataClassPath<K, User>.organization: DataClassPath<K, UUID> get() = this[User_organization]
val <K> DataClassPath<K, User>.name: DataClassPath<K, String> get() = this[User_name]
val <K> DataClassPath<K, User>.webPreferences: DataClassPath<K, String> get() = this[User_webPreferences]
val <K> DataClassPath<K, User>.theme: DataClassPath<K, String> get() = this[User_theme]
val <K> DataClassPath<K, User>.themeColor: DataClassPath<K, String> get() = this[User_themeColor]
val <K> DataClassPath<K, User>.summaryTime: DataClassPath<K, String> get() = this[User_summaryTime]
val <K> DataClassPath<K, User>.isSuperUser: DataClassPath<K, Boolean> get() = this[User_isSuperUser]
val <K> DataClassPath<K, User>.role: DataClassPath<K, UserRole?> get() = this[User_role]
val <K> DataClassPath<K, User>.currentTask: DataClassPath<K, UUID?> get() = this[User_currentTask]
val <K> DataClassPath<K, User>.projectFavorites: DataClassPath<K, Set<UUID>> get() = this[User_projectFavorites]
val <K> DataClassPath<K, User>.limitToProjects: DataClassPath<K, Set<UUID>?> get() = this[User_limitToProjects]
val <K> DataClassPath<K, User>.active: DataClassPath<K, Boolean> get() = this[User_active]
inline val User.Companion.path: DataClassPath<User, User> get() = path<User>()


object User__id: SerializableProperty<User, UUID> {
    override val name: String = "_id"
    override fun get(receiver: User): UUID = receiver._id
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("_id")
}
object User_email: SerializableProperty<User, String> {
    override val name: String = "email"
    override fun get(receiver: User): String = receiver.email
    override fun setCopy(receiver: User, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("email")
}
object User_organization: SerializableProperty<User, UUID> {
    override val name: String = "organization"
    override fun get(receiver: User): UUID = receiver.organization
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(organization = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("organization")
}
object User_name: SerializableProperty<User, String> {
    override val name: String = "name"
    override fun get(receiver: User): String = receiver.name
    override fun setCopy(receiver: User, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("name")
}
object User_webPreferences: SerializableProperty<User, String> {
    override val name: String = "webPreferences"
    override fun get(receiver: User): String = receiver.webPreferences
    override fun setCopy(receiver: User, value: String) = receiver.copy(webPreferences = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("webPreferences")
}
object User_theme: SerializableProperty<User, String> {
    override val name: String = "theme"
    override fun get(receiver: User): String = receiver.theme
    override fun setCopy(receiver: User, value: String) = receiver.copy(theme = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("theme")
}
object User_themeColor: SerializableProperty<User, String> {
    override val name: String = "themeColor"
    override fun get(receiver: User): String = receiver.themeColor
    override fun setCopy(receiver: User, value: String) = receiver.copy(themeColor = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("themeColor")
}
object User_summaryTime: SerializableProperty<User, String> {
    override val name: String = "summaryTime"
    override fun get(receiver: User): String = receiver.summaryTime
    override fun setCopy(receiver: User, value: String) = receiver.copy(summaryTime = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("summaryTime")
}
object User_isSuperUser: SerializableProperty<User, Boolean> {
    override val name: String = "isSuperUser"
    override fun get(receiver: User): Boolean = receiver.isSuperUser
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(isSuperUser = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("isSuperUser")
}
object User_role: SerializableProperty<User, UserRole?> {
    override val name: String = "role"
    override fun get(receiver: User): UserRole? = receiver.role
    override fun setCopy(receiver: User, value: UserRole?) = receiver.copy(role = value)
    override val serializer: KSerializer<UserRole?> = UserRole.serializer().nullable
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("role")
}
object User_currentTask: SerializableProperty<User, UUID?> {
    override val name: String = "currentTask"
    override fun get(receiver: User): UUID? = receiver.currentTask
    override fun setCopy(receiver: User, value: UUID?) = receiver.copy(currentTask = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("currentTask")
}
object User_projectFavorites: SerializableProperty<User, Set<UUID>> {
    override val name: String = "projectFavorites"
    override fun get(receiver: User): Set<UUID> = receiver.projectFavorites
    override fun setCopy(receiver: User, value: Set<UUID>) = receiver.copy(projectFavorites = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("projectFavorites")
}
object User_limitToProjects: SerializableProperty<User, Set<UUID>?> {
    override val name: String = "limitToProjects"
    override fun get(receiver: User): Set<UUID>? = receiver.limitToProjects
    override fun setCopy(receiver: User, value: Set<UUID>?) = receiver.copy(limitToProjects = value)
    override val serializer: KSerializer<Set<UUID>?> = SetSerializer(ContextualSerializer(UUID::class)).nullable
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("limitToProjects")
}
object User_active: SerializableProperty<User, Boolean> {
    override val name: String = "active"
    override fun get(receiver: User): Boolean = receiver.active
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("active")
}

package com.lightningtime.views.screens.splitScreens.detailScreens

import com.lightningkite.UUID
import com.lightningtime.views.tools.splitscreens.SplitScreen
import com.lightningtime.views.tools.splitscreens.SplitScreenNavigator
import com.lightningtime.views.screens.splitScreens.splitNavigator

interface DetailScreen: SplitScreen<DetailScreen> {
    override val split: SplitScreenNavigator<DetailScreen> get() = splitNavigator
    val id: UUID?
}
package com.lightningtime.views.screens.splitScreens.projects

import com.lightingtime.*
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.spacing
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningtime.sdk.currentSession
import com.lightningtime.theming.downArrow
import com.lightningtime.theming.embedded
import com.lightningtime.theming.emphasized
import com.lightningtime.theming.upArrow
import com.lightningtime.toggle
import com.lightningtime.views.components.queries.*
import com.lightningtime.views.tools.splitscreens.SplitScreen
import com.lightningtime.views.tools.splitscreens.SplitScreenNavigator
import com.lightningtime.views.detail
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailScreen
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedProjectView
import com.lightningtime.views.screens.splitScreens.detailScreens.forms.ProjectForm
import com.lightningtime.views.screens.splitScreens.splitNavigator
import com.lightningtime.views.tooltip

class ProjectsListScreen: SplitScreen<DetailScreen> {
    override val split: SplitScreenNavigator<DetailScreen> = splitNavigator

    private val searchField = Property(SearchField.Projects.Name)
    private val query = Property("")

    enum class ProjectSort {
        Creation, Name
    }
    private val sortType = Property(ProjectSort.Creation)
    private val sortDescending = Property(true)

    private val projects = shared {
        val session = currentSession()

        session.projects.query(Query(
            query.queryCondition(searchField) ?: Condition.Always(),
            orderBy = when(sortType()) {
                ProjectSort.Creation -> sort { it.createdAt.apply { if (sortDescending()) descending() else ascending() } }
                ProjectSort.Name -> sort { it.name.apply { if (sortDescending()) descending() else ascending() } }
            }
        ))()
    }

    override fun ViewWriter.render() {
        col {
            sizeConstraints(height = 3.rem) - row {

                card - emphasized - splitLink {
                    spacing = 0.75.rem

                    centered - row {
                        spacing = 0.75.rem
                        centered - icon { source = Icon.add }
                        centered - h6("Create Project")
                    }

                    to = { ProjectForm() }
                }

                expanding - centered - searchBar(query, searchField, SearchField.Projects.entries)

                centered - menuButton {
                    spacing = 0.rem
                    preferredDirection = PopoverPreferredDirection.belowLeft
                    icon(Icon.settings, "Change project list settings")

                    opensMenu {

                        col {
                            spacing = 1.5.rem

                            fun ViewWriter.setting(name: String, setup: ViewWriter.() -> Unit) =
                                sizeConstraints(width = 11.rem, height = 5.rem) - label {
                                    content = name

                                    fieldTheme - expanding - setup()
                                }
                            val settingSpacing = 0.5.rem

                            setting("Sort By") {
                                select {
                                    spacing = settingSpacing
                                    bind(
                                        edits = sortType,
                                        data = Constant(ProjectSort.entries),
                                        render = { it.toString() }
                                    )
                                }
                            }

                            setting("Sort Direction") {
                                button {
                                    spacing = settingSpacing
                                    centered - row {
                                        spacing = 0.25.rem
                                        sizeConstraints(height = 1.25.rem) - centered - icon {
                                            ::source {
                                                if (sortDescending()) Icon.downArrow
                                                else Icon.upArrow
                                            }
                                        }

                                        centered - text {
                                            ::content {
                                                if (sortDescending()) "Descending"
                                                else "Ascending"
                                            }
                                        }

                                        space()
                                    } in tooltip {
                                        if (sortDescending()) "Switch to Ascending"
                                        else "Switch to Descending"
                                    }

                                    onClick {
                                        sortDescending.toggle()
                                    }
                                }
                            }
                        }
                    }
                }
            }

            separator()

            expanding - recyclerView {
                spacing = 0.5.rem

                children(projects) { project ->

                    themeFromLast {
                        if (split.selected()?.id == project()._id) it.selected()
                        else it.card()
                    } -
                    splitLink {
                        col {
                            row {
                                expanding - centered - emphasized - h4{ ::content { project().name } }

                                embedded - detail("Rate") { "$${project().rate ?: 0}" }
                            }

                            scrollsHorizontally - row {
                                val tags = shared { project().projectTags.toList() }
                                ::exists { tags().isNotEmpty() }

                                forEachUpdating(tags) { tag ->
                                    subtext {
                                        spacing = 0.5.rem
                                        ::content { tag() }
                                    }
                                }
                            }
                        }

                        to = {
                            val p = project()
                            DetailedProjectView(p._id).also { it.project.value = p }
                        }
                    }
                }
            }
        }
    }
}
package com.lightningtime.sdk

import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.LSError
import com.lightningkite.lightningserver.StringArrayFormat
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.navigation.PlatformNavigator
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.uuid
import kotlinx.serialization.*
import kotlinx.serialization.json.Json
import net.thauvin.erik.urlencoder.UrlEncoderUtil
//import net.thauvin.erik.urlencoder.UrlEncoderUtil
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.reflect.typeOf

val json = Json {
    serializersModule = ClientModule
    ignoreUnknownKeys = true
}
val stringArrayFormat = StringArrayFormat(ClientModule)
inline fun <reified T> T.urlify(): String = stringArrayFormat.encodeToString(this)

suspend inline fun <reified T> RequestResponse.readJson() = json.decodeFromString<T>(text())
suspend fun RequestResponse.discard() = Unit
suspend inline fun <reified T> T.toJsonRequestBody() = json.encodeToString(this)

class LsErrorException(val status: Short, val error: LSError): IllegalStateException(error.message)

object HttpClient {
    val GET: HttpMethod = HttpMethod.GET
    val POST: HttpMethod = HttpMethod.POST
    val PATCH: HttpMethod = HttpMethod.PATCH
    val DELETE: HttpMethod = HttpMethod.DELETE
    val PUT: HttpMethod = HttpMethod.PUT
}

val queuedRequests = HashMap<String, HashMap<String, HashMap<String, BulkHandler>>>()
class BulkHandler(val request: BulkRequest, val response: Continuation<BulkResponse>)
suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () ->String)? = null,
    headers: HttpHeaders = httpHeaders(),
    bodyJson: String?
): OUT {
    val tokenReal = token?.invoke()
    return suspendCoroutineCancellable<BulkResponse> {
        val pathStartsAt = if(url.startsWith("http")) url.indexOf('/', 8) else 0
        val domain = url.substring(
            0,
            pathStartsAt
        )
        val path = url.substring(
            pathStartsAt
        )
        val id = uuid().toString()
        val bulk = BulkHandler(
            request = BulkRequest(
                path,
                method = method.name,
                body = bodyJson
            ),
            response = it
        )
        val qr = {
            val domainQueuedRequests = queuedRequests.getOrPut(domain) { HashMap() }
            domainQueuedRequests.getOrPut(tokenReal ?: "") { HashMap() }
        }
        val q = qr()
        q.put(id, bulk)
        if (q.size == 1) {
            launchGlobal {
                delay(50L)
                val todo = qr()
                run {
                    val domainQueuedRequests = queuedRequests.getOrPut(domain) { HashMap() }
                    domainQueuedRequests.remove(tokenReal ?: "")
                }
                println("MAKING FETCH FOR ${todo.values.joinToString { it.request.path }}")
                com.lightningkite.kiteui.fetch(
                    url = "$domain/meta/bulk",
                    method = HttpMethod.POST,
                    headers = headers.apply {
                        tokenReal?.let { append("Authorization", "Bearer ${it}") }
                    },
                    body = RequestBodyText(json.encodeToString(todo.mapValues { it.value.request }), "application/json")
                ).let { it: RequestResponse ->
                    println("HANDLING RESPONSE FOR ${todo.values.joinToString { it.request.path }}")
                    if (!it.ok) {
                        val failed = Exception(it.text())
                        todo.values.forEach { it.response.resumeWithException(failed) }
                    } else {
                        val responses = json.decodeFromString<Map<String, BulkResponse>>(it.text())
                        todo.forEach {
                            responses[it.key]?.let { response ->
                                it.value.response.resume(response)
                            } ?: it.value.response.resumeWithException(Exception("Bulk key ${it.key} not found"))
                        }
                    }
                }
            }
        }
        return@suspendCoroutineCancellable {}
    }.let { it: BulkResponse ->
        try {
            if (it.error == null && OUT::class == Unit::class) Unit as OUT
            else if (it.result != null) json.decodeFromString(it.result!!)
            else {
                throw LsErrorException(it.error?.http?.toShort() ?: 0.toShort(), it.error ?: LSError(0))
            }
        } catch (e: Exception) {
            e.printStackTrace()
            throw e
        }
    }
}

suspend inline fun <reified IN, reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    headers: HttpHeaders = httpHeaders(),
    body: IN,
): OUT = com.lightningkite.kiteui.fetch(
    url = url,
    method = method,
    headers = headers.apply {
        token?.let { append("Authorization", "Bearer ${it()}") }
    },
    body = RequestBodyText(json.encodeToString(body), "application/json")
).let { it: RequestResponse ->
    if (it.ok && OUT::class == Unit::class) Unit as OUT
    else if (it.ok) it.readJson()
    else {
        throw IllegalStateException(it.text())
    }
}

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    headers: HttpHeaders = httpHeaders(),
): OUT = com.lightningkite.kiteui.fetch(
    url = url,
    method = method,
    headers = headers.apply {
        append("Content-Type", "application/json")
        token?.let { append("Authorization", "Bearer ${it()}") }
    },
    body = null
).let {
    if (it.ok && OUT::class == Unit::class) Unit as OUT
    else if (it.ok) it.readJson()
    else {
        throw IllegalStateException(it.text())
    }
}


inline fun <reified IN, reified OUT> multiplexedSocket(
    socketUrl: String,
    path: String,
    token: String?,
): TypedWebSocket<IN, OUT> = multiplexSocket(
    url = "$socketUrl/?path=multiplex${token?.let { "?jwt=${UrlEncoderUtil.encode(it)}" } ?: ""}",
    path = path,
    params = emptyMap(),
    json = json,
    pingTime = 5_000,
).typed(
    json,
    json.serializersModule.serializer(typeOf<IN>()) as KSerializer<IN>,
    json.serializersModule.serializer(typeOf<OUT>()) as KSerializer<OUT>
)

//suspend inline fun <reified IN, reified OUT> fetch(
//    url: String,
//    method: HttpMethod = HttpMethod.GET,
//    noinline token: (suspend () ->String)? = null,
//    headers: HttpHeaders = httpHeaders(),
//    body: IN
//): OUT = fetch(url, method, token, headers, json.encodeToString(body))
//
//suspend inline fun <reified OUT> fetch(
//    url: String,
//    method: HttpMethod = HttpMethod.GET,
//    noinline token: (suspend () -> String)? = null,
//    headers: HttpHeaders = httpHeaders()
//): OUT = fetch(url, method, token, headers, null)

//suspend inline fun <reified IN, reified OUT> multiplexedSocket(
//    socketUrl: String,
//    path: String,
//    token: String?,
//): TypedWebSocket<IN, OUT> = multiplexSocket(
//    url = "$socketUrl/?path=multiplex${token?.let { "?jwt=${UrlEncoderUtil.encode(it)}" } ?: ""}",
//    path = path,
//    params = emptyMap(),
//    json = json
//).typed(json, json.serializersModule.serializer(typeOf<IN>()) as KSerializer<IN>, json.serializersModule.serializer(typeOf<OUT>()) as KSerializer<OUT>)


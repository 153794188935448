package com.lightningtime.theming

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.themeFromLast
import com.lightningkite.kiteui.views.tweakTheme
import kotlin.math.min


suspend fun emphasizedTheme(theme: Theme): Theme = theme.copy(
    foreground = appAccentColor.await(),
    elevation = 0.rem
)

@ViewModifierDsl3
val ViewWriter.emphasized: ViewWrapper
    get() = tweakTheme { emphasizedTheme(it) }

@ViewModifierDsl3
val ViewWriter.embedded: ViewWrapper
    get() = themeFromLast { it.copy(
        background = it.background.closestColor().highlight(-0.05f),
        elevation = it.elevation/4
    ) }

@ViewModifierDsl3
val ViewWriter.rounded: ViewWrapper
    get() = tweakTheme {
        it.copy(
            cornerRadii = CornerRadii.ForceConstant(1.rem),
        )
    }

@ViewModifierDsl3
fun ViewWriter.estimateThreatTheme(ratio: Readable<Float>): ViewWrapper = tweakTheme { theme ->
    val r = ratio()
    if (r > 1) theme.copy(
        foreground = Color.interpolate(appAccentColor(), AppColors.dangerColor, min(r - 1, 1f))
    )
    else emphasizedTheme(theme)
}

@ViewModifierDsl3
val ViewWriter.removeOutline: ViewWrapper get() = tweakTheme {
    it.copy(
        outlineWidth = 0.px
    )
}

fun lightOutlineTheme(theme: Theme): Theme = theme.copy(
    outlineWidth = 1.px,
    outline = Color.interpolate(theme.background.closestColor(), theme.foreground.closestColor(), 0.3f)
)

@ViewModifierDsl3
val ViewWriter.lightOutline: ViewWrapper get() = themeFromLast {
    lightOutlineTheme(it)
}
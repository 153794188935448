package com.lightningtime.views.screens

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.h2
import com.lightningkite.kiteui.views.direct.h3
import com.lightningtime.sdk.currentSession
import com.lightningtime.views.emptyView
import com.lightningtime.views.screens.auth.LoginScreen
import com.lightningtime.views.screens.splitScreens.tasks.TasksScreen

@Routable("/")
object LandingScreen: Screen {
    override fun ViewWriter.render() {
        expanding - emptyView {
            centered - h2("Authenticating")

            launch {
                try {
                    val self = currentSession().self()
                    navigator.reset(TasksScreen())
                } catch (e: Exception) {
                    println(e)
                    println("Moved to login")
                    navigator.reset(LoginScreen())
                }
            }
        }
    }
}
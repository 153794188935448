package com.lightningtime.timeManagement

import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningtime.theming.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

fun ViewWriter.displayDuration(duration: suspend () -> Duration) {
    row {
        spacing = 0.2.rem

        fun ViewWriter.displayUnit(unit: Duration.() -> Long) {
            centered - sizeConstraints(
                width = 2.2.rem
            ) - h3 {
                align = Align.Center
                ::content { duration().unit().toString().padStart(2, '0') }
            }
        }

        displayUnit { inWholeHours }
        centered - h3(":")
        displayUnit { inWholeMinutes % 60 }
        centered - h3(":")
        displayUnit { inWholeSeconds % 60 }
    }
}

fun ViewWriter.displayDuration(timer: Readable<MutableTimer>) = displayDuration { timer().total() }

fun ViewWriter.editableTimer(timer: Readable<MutableTimer>) {
    fun timerBox(
        unitsToDuration: Long.() -> Duration,
        durationToUnitsTransform: (Duration) -> Long
    ) {
        expanding - fieldTheme - centered - textField {
            spacing = 0.5.rem
            align = Align.Center

            content bind shared {
                durationToUnitsTransform(timer().unsaved()).toString().padStart(2, '0')
            }.withWrite {
                val duration = timer().unsaved()
                val withoutUnits = duration - durationToUnitsTransform(duration).unitsToDuration()

                val newDurationInUnits = it.toLongOrNull()?.unitsToDuration() ?: 0.seconds

                timer().unsaved.value = withoutUnits + newDurationInUnits
            }

            action = Action(
                title = "Save Changes",
                icon = Icon.save,
                onSelect = {
                    timer().resume()
                }
            )
        }
    }

    fieldTheme - sizeConstraints(
        width = 10.rem,
        height = 3.rem
    ) - centered - onlyWhen { !timer().editing() } - button {
        centered - displayDuration(timer)

        onClick {
            timer().editing.set(true)
        }
    }

    centered - sizeConstraints(
        width = 10.rem,
        height = 3.rem
    ) - onlyWhen { timer().editing() } - row {
        spacing = 0.5.rem

        timerBox(
            { hours },
            { it.inWholeHours }
        )
        centered - h3(":")
        timerBox(
            { minutes },
            { it.inWholeMinutes % 60 },
        )
        centered - h3(":")
        timerBox(
            { seconds },
            { it.inWholeSeconds % 60 },
        )
    }
}

fun ViewWriter.timerPlayPauseButton(timer: Readable<MutableTimer>) {
    centered - button {
        spacing = 0.1.rem

        centered - icon {
            ::source {
                if (timer().lastStarted() == null || !timer().isSelected()) Icon.play
                else Icon.pause
            }
        }

        onClick {
            if (timer.isSelected()) {
                val t = timer()
                if (t.lastStarted() == null) t.resume()
                else t.pause()
            }
            else {
                TimeInfo.selectedTimer set timer()
            }
        }
    }
}

fun ViewWriter.timerControlPanel(timer: Readable<MutableTimer>) {
    sizeConstraints(height = 3.rem) - row {
        editableTimer(timer)

        sizeConstraints(height = 3.rem, width = 3.rem) - lightOutline - timerPlayPauseButton(timer)
    }
}

fun ViewWriter.timerControlPanel(timer: Readable<MutableTimer?>, default: suspend () -> MutableTimer) {
    sizeConstraints(height = 3.rem) - row {
        ::exists { timer() != null }

        editableTimer(timer.waitForNotNull)

        sizeConstraints(height = 3.rem, width = 3.rem) - lightOutline - timerPlayPauseButton(timer.waitForNotNull)
    }

    sizeConstraints(height = 3.rem) - stack {
        ::exists.invoke { timer() == null }

        lightOutline - centered - button {
            centered - row {
                spacing = 0.5.rem

                sizeConstraints(height = 1.5.rem) - icon { source = Icon.addClock }

                centered - h6("Start New Timer")
            }

            onClick {
                TimeInfo.selectedTimer set default()
            }
        }
    }
}
package com.lightningtime.draftModel

import com.lightningkite.kiteui.reactive.*

fun <T, V> Writable<T>.withTransform(
    inputTransform: suspend (V) -> T,
    outputTransform: suspend (T) -> V
): Writable<V> = shared {
    outputTransform(this@withTransform.await())
}.withWrite {
    this@withTransform set inputTransform(it)
}

fun <T, V> Writable<T>.withLongTransform(
    inputTransform: suspend (V) -> T,
    outputTransform: suspend (T) -> V
) = object: Writable<V> {
    val late = LateInitProperty<Unit>().apply { value = Unit }

    val shared = shared {
        late.await()
        outputTransform(this@withLongTransform.await())
    }

    override val state: ReadableState<V> get() = shared.state
    override fun addListener(listener: () -> Unit): () -> Unit = shared.addListener(listener)

    fun setLoading() = late.unset()
    fun setComplete() { late.value = Unit }

    override suspend fun set(value: V) {
        setLoading()

        this@withLongTransform set inputTransform(value)

        setComplete()
    }
}
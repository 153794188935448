package com.lightningtime.views.screens.splitScreens.detailScreens.forms

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Query
import com.lightningtime.SpecialCharacters
import com.lightningtime.draftModel.*
import com.lightningtime.format
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.theming.edit
import com.lightningtime.theming.lightOutline
import com.lightningtime.theming.removeOutline
import com.lightningtime.validation.validate
import com.lightningtime.validation.interceptWrite
import com.lightningtime.views.screens.splitScreens.tasks.comments.CommentGenerator
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedTaskView


@Routable("tasks/form/{parentID}")
class TaskForm(val parentID: UUID) : FormScreen<Task>() {
    override val id: UUID get() = parentID

    val parent = LazyProperty {
        val session = currentSession()
        session.tasks[parentID]() ?: session.projects[parentID]()!!
    }

    val projectName = LazyProperty {
        when (val p = parent()) {
            is Task -> p.projectName
            is Project -> p.name
        }
    }

    val organizationName = LazyProperty {
        when (val p = parent()) {
            is Task -> p.organizationName
            is Project -> currentSession().organizations[p.organization]()?.name
        }
    }

    val task = LazyProperty {
        val session = currentSession()

        Task(
            project = when (val p = parent()) {
                is Task -> p.project
                is Project -> p._id
            },
            projectName = projectName.awaitOnce(),
            organization = when (val p = parent()) {
                is Task -> p.organization
                is Project -> p.organization
            },
            organizationName = organizationName.awaitOnce(),
            user = session.userId,
            state = TaskState.Active,
            summary = "",
            description = "",
            estimate = null,
            emergency = false,
            priority = 0.0,
            createdBy = session.userId,
            pullRequestLinks = emptySet()
        )
    }

    private val draft = DraftModel(task)

    override fun setEditing(original: Task): TaskForm {
//        println("Setting editing")

        editing = true
        task.value = original
        parent.value = original
        projectName.value = original.projectName
        organizationName.value = original.organizationName

//        println("Finished setting editing")
        return this
    }

    val isPullRequest = Property(false)

    private suspend fun submitTask() {
        draft.commit()

        val session = handleCurrentSession()
        val readTask = task()
        val isPullRequest = isPullRequest()

        val task = session.tasks.insert(readTask)()!!

        session.comments.insert(
//            listOf(
                CommentGenerator(task, session.userId).autoGenerate(
                    mentions = setOfNotNull(if (task.user != null) Mention(task.user!!) else null)
                ) {
                    appendLine("Creation details for \"${task.summary}\": \n")
                    appendLine("Assigned To: ${task.userName ?: "Nobody"}")
                    if (isPullRequest) {
                        appendLine("PR Links:")
                        for (link in task.pullRequestLinks) {
                            appendLine(" - $link")
                        }
                    }
                    appendLine("Emergency: ${task.emergency}")
                    appendLine("Priority: ${task.priority}")
                    appendLine("Estimate: ${task.estimate?.let { "$it hours" } ?: "No Estimate" }")

                    appendLine("Tags: ")
                    for (tag in task.tags) {
                        appendLine(" - $tag")
                    }

                    appendLine("\nDescription: \n${task.description}")
                },
//                CommentGenerator(task, session.userId).generate(task.description)
//            )
        )
    }

    private suspend fun submitChanges() {
        val session = handleCurrentSession()
        val task = task()
        session.comments.insert(
            CommentGenerator(task, session.userId).autoGenerate {
                appendLine("Task Details Changed")
                for (property in draft.editedProperties) {
                    var old = property.original()
                    var new = property.current()

                    if (old != new) {
                        val propertyName = property.toString().replaceFirstChar { it.uppercaseChar() }

                        if (property.propertyPath == Task.path.user) {
                            old = task.userName
                            new = new?.let { session.users[it as UUID]()?.name } ?: "Nobody"
                        }

                        appendLine("${SpecialCharacters.bulletPoint} $propertyName: $old ${SpecialCharacters.rightArrow} $new")
                    }
                }
            }
        )

        draft.commit()

        session.tasks[task._id].modify(draft.serverModification())
            ?: throw NullPointerException("Task changes not saved correctly")
    }

    private suspend fun submit() {
        if (editing) submitChanges()
        else submitTask()

        val t = task()
        split.replace(
            DetailedTaskView(t._id).also { it.task.value = t }
        )
    }

    override val title: Readable<String> = if (editing) shared { "Editing " + task().summary } else Constant("New Task")

    override fun ViewWriter.render() {
        scrolls - col {
            row {
                if (editing) centered - icon(Icon.edit, "Editing Task")

                centered - expanding - h2 { ::content { draft.await(Task.path.summary).ifBlank { "New Task" } } }

                button {
                    spacing = 0.1.rem
                    icon(Icon.close, "Exit Form")
                    onClick { split.goBack() }
                }
            }

            row {
                subtext {
                    ::content {
                        "Organization: " + organizationName()
                    }
                }

                separator()

                subtext {
                    ::content {
                        "Project: " + projectName()
                    }
                }

                separator()

                subtext {
                    ::content {
                        "Created By: " + currentSession().self().name
                    }
                }

            }

            separator()

            val maxChars: Int = 75
            val summary = draft.prop(Task.path.summary).validate(editing) { it.isNotBlank() && it.length <= maxChars }

            suspend fun setPullRequest(boolean: Boolean) {
                if (boolean) {
                    isPullRequest.value = true
                    draft.prop(Task.path.state).set(TaskState.PullRequest)
                    draft.collectionProp(Task.path.pullRequestLinks).set(setOf(""))

                    val p = parent()
                    if (p is Task && !editing) summary.set("Pull Request for ${p.summary}")
                } else {
                    isPullRequest.value = false
                    draft.prop(Task.path.state).set(TaskState.Active)
                    draft.collectionProp(Task.path.pullRequestLinks).set(setOf())

                    if (parent() is Task && !editing) summary.set("")
                }
            }

            padded - row {
                row {
                    spacing = 0.5.rem
                    centered - fieldTheme - checkbox {
                        checked bind draft.prop(Task.path.emergency)
                    }
                    centered - text("Emergency")
                }

                space()

                row {
                    spacing = 0.5.rem

                    centered - fieldTheme - checkbox {
                        checked bind isPullRequest.withWrite { setPullRequest(it) }
                    }

                    centered - text("Pull Request")
                }
            }

            separator()

            padded - col {
                spacing = 0.5.rem

                label {
                    content = "Summary"

                    col {
                        fieldTheme - validate(summary) - textField {
                            content bind summary

                            hint = "Keep it short and sweet ($maxChars characters max)"
//                            requestFocus()  // TODO: There's a bug with requestFocus that causes a jarring transition
                        }

                        atEnd - subtext { ::content { "Remaining: ${maxChars - summary().length}" } }
                    }
                }

                label {
                    content = "Description"

                    col {
                        val description = draft.prop(Task.path.description)
                            .also { println("Description State: " + it.state) }
                            .validate { it.isNotBlank() }

                        fieldTheme - validate(description) - textArea {
                            content bind description
                        }
                    }
                }

                space()

                label {
                    content = "State"

                    fieldTheme - select {
                        bind(
                            edits = draft.prop(Task.path.state).interceptWrite {
                                setPullRequest(it == TaskState.PullRequest)
                                set(it)
                            },
                            data = Constant(TaskState.entries),
                            render = {
                                it.format()
                            }
                        )
                    }
                }

                space()

                row {
                    weight(2f) - label {
                        content = "Assigned To"

                        val users = shared { listOf(null) + currentSession().users.query(Query())() }

                        val selectedUser: Writable<User?> = draft.prop(Task.path.user).withTransform(
                            inputTransform = { it?._id },
                            outputTransform = { users().find { user -> user?._id == it } }
                        )

                        fieldTheme - select {
                            bind(
                                edits = selectedUser,
                                data = users,
                                render = { it?.name ?: "Nobody" }
                            )
                        }
                    }

                    expanding - label {
                        content = "Estimate"

                        fieldTheme - numberField {
                            content bind draft.prop(Task.path.estimate).withTransform(
                                inputTransform = { it?.toInt() },
                                outputTransform = { it?.toDouble() }
                            )
                            keyboardHints = KeyboardHints.integer
                        }
                    }

                    expanding - label {
                        content = "Priority"

                        fieldTheme - numberField {
                            content bind draft.prop(Task.path.priority).withTransform(
                                inputTransform = { it ?: 0.0 },
                                outputTransform = { it }
                            )
                        }
                    }

                }

                space()

                col {
                    text("Tags")

                    val tags = draft.collectionProp(Task.path.tags)

                    lightOutline - col {
                        col {
                            ::exists { tags().isNotEmpty() }

                            forEachUpdating(
                                dynamicValidation {
                                    tags.map(onRefresh = ::refreshDynamicConditions) { tag ->
                                        val modified = tag
                                            .withTransform({ it.trim() }, { it })
                                            .validate { str -> str.isNotBlank() && str.all { it.isLowerCase() || it.isWhitespace() } }
                                            .dynamic()

                                        tag to modified
                                    }
                                }
                            ) { (original, validated) ->
                                sizeConstraints(height = 2.rem) - row {
                                    spacing = 0.5.rem

                                    expanding - centered - fieldTheme - validate(validated) - textField {
                                        hint = "Only Lowercase"
                                        content bind validated.flatten()
                                    }

                                    removeOutline - centered - button {
                                        spacing = 0.rem
                                        centered - icon(Icon.delete, "Remove Tag")
                                        onClick { tags.remove(original) }
                                    }
                                }
                            }
                        }

                        removeOutline - sizeConstraints(height = 2.rem) - button {
                            spacing = 0.rem
                            centered - icon(Icon.add, "Add Tag")
                            onClick { tags.add(Property("")) }
                        }
                    }
                }

                onlyWhen { isPullRequest() } - col {
                    space()

                    text("Pull Request Links")

                    val pullRequestLinks = draft.collectionProp(Task.path.pullRequestLinks)

                    lightOutline - col {
                        col {
                            val linkRegex = Regex("^(http|https)://.*$")
                            forEachUpdating(
                                dynamicValidation {
                                    pullRequestLinks.map(onRefresh = ::refreshDynamicConditions) { link ->
                                        link to link.validate { linkRegex.matches(it) }.dynamic()
                                    }
                                }
                            ) { (original, validated) ->
                                row {
                                    sizeConstraints(height = 2.rem) - expanding - fieldTheme - validate(validated) - textField {
                                        content bind validated.flatten()
                                    }

                                    removeOutline - onlyWhen { pullRequestLinks().size > 1 } - button {
                                        spacing = 0.rem
                                        centered - icon { source = Icon.delete }
                                        onClick {
                                            pullRequestLinks.remove(original)
                                        }
                                    }
                                }
                            }
                        }

                        removeOutline - sizeConstraints(height = 2.rem) - button {
                            spacing = 0.rem
                            centered - icon { source = Icon.add }
                            onClick {
                                pullRequestLinks.add(Property(""))
                            }
                        }
                    }
                }

                space()

                atEnd - padded - important - button {
                    ::enabled { allValid() && draft.changesMade() }

                    centered - text(
                        if (editing) "Save Changes"
                        else "Submit"
                    )

                    onClick(::submit)
                }

                space()
            }
        }
    }
}
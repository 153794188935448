package com.lightningtime.views.tools.splitscreens

import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.ScreenStack
import com.lightningkite.kiteui.reactive.*

class SplitScreenNavigator<NAVIGATES: Screen> {
    var rootNavigator: ScreenStack = ScreenStack.main
    private val splitStack = Property<List<NAVIGATES>>(emptyList())

    val selected: Readable<NAVIGATES?> = shared { splitStack().lastOrNull() }

    private suspend fun inSplit(): Boolean = rootNavigator.currentScreen.awaitOnce() is SplitScreenManager

    var narrow: Boolean = false
        set(value) {
            if (value) splitStack.value = emptyList()
            field = value
        }

    var primary: Screen? = null

    fun navigate(screen: NAVIGATES) {
        if (narrow) rootNavigator.navigate(screen)
        else {
            splitStack.value += screen
        }
    }

    suspend fun externalNavigate(screen: NAVIGATES) {
        if (narrow or !inSplit()) rootNavigator.navigate(screen)
        else splitStack.value += screen
    }

    fun replace(screen: NAVIGATES) {
        if (narrow) rootNavigator.replace(screen)
        else {
            splitStack.value = splitStack.value.dropLast(1) + screen
        }
    }

    val canGoBack: Readable<Boolean> = shared {
        if (narrow) rootNavigator.canGoBack()
        else splitStack().size > 1
    }

    fun goBack(): Boolean {
        if (narrow) return rootNavigator.goBack()

        if (splitStack.value.size <= 1) {
            reset()
            return false
        }

        splitStack.value = splitStack.value.dropLast(1)
        return true
    }

    fun reset() {
        if (narrow) {
            if (primary != null) rootNavigator.replace(primary!!)
            else {
                if (goBack()) return

                rootNavigator.clear()
            }
        }
        splitStack.value = emptyList()
    }
}


@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareTimeEntryFields() {
    val props: Array<SerializableProperty<TimeEntry, *>> = arrayOf(TimeEntry__id, TimeEntry_task, TimeEntry_taskSummary, TimeEntry_project, TimeEntry_projectName, TimeEntry_organization, TimeEntry_organizationName, TimeEntry_user, TimeEntry_userName, TimeEntry_summary, TimeEntry_durationMilliseconds, TimeEntry_date)
    TimeEntry.serializer().properties { props }
}
val <K> DataClassPath<K, TimeEntry>._id: DataClassPath<K, UUID> get() = this[TimeEntry__id]
val <K> DataClassPath<K, TimeEntry>.task: DataClassPath<K, UUID?> get() = this[TimeEntry_task]
val <K> DataClassPath<K, TimeEntry>.taskSummary: DataClassPath<K, String?> get() = this[TimeEntry_taskSummary]
val <K> DataClassPath<K, TimeEntry>.project: DataClassPath<K, UUID> get() = this[TimeEntry_project]
val <K> DataClassPath<K, TimeEntry>.projectName: DataClassPath<K, String?> get() = this[TimeEntry_projectName]
val <K> DataClassPath<K, TimeEntry>.organization: DataClassPath<K, UUID> get() = this[TimeEntry_organization]
val <K> DataClassPath<K, TimeEntry>.organizationName: DataClassPath<K, String?> get() = this[TimeEntry_organizationName]
val <K> DataClassPath<K, TimeEntry>.user: DataClassPath<K, UUID> get() = this[TimeEntry_user]
val <K> DataClassPath<K, TimeEntry>.userName: DataClassPath<K, String?> get() = this[TimeEntry_userName]
val <K> DataClassPath<K, TimeEntry>.summary: DataClassPath<K, String> get() = this[TimeEntry_summary]
val <K> DataClassPath<K, TimeEntry>.durationMilliseconds: DataClassPath<K, Int> get() = this[TimeEntry_durationMilliseconds]
val <K> DataClassPath<K, TimeEntry>.date: DataClassPath<K, LocalDate> get() = this[TimeEntry_date]
inline val TimeEntry.Companion.path: DataClassPath<TimeEntry, TimeEntry> get() = path<TimeEntry>()


object TimeEntry__id: SerializableProperty<TimeEntry, UUID> {
    override val name: String = "_id"
    override fun get(receiver: TimeEntry): UUID = receiver._id
    override fun setCopy(receiver: TimeEntry, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("_id")
}
object TimeEntry_task: SerializableProperty<TimeEntry, UUID?> {
    override val name: String = "task"
    override fun get(receiver: TimeEntry): UUID? = receiver.task
    override fun setCopy(receiver: TimeEntry, value: UUID?) = receiver.copy(task = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("task")
}
object TimeEntry_taskSummary: SerializableProperty<TimeEntry, String?> {
    override val name: String = "taskSummary"
    override fun get(receiver: TimeEntry): String? = receiver.taskSummary
    override fun setCopy(receiver: TimeEntry, value: String?) = receiver.copy(taskSummary = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("taskSummary")
}
object TimeEntry_project: SerializableProperty<TimeEntry, UUID> {
    override val name: String = "project"
    override fun get(receiver: TimeEntry): UUID = receiver.project
    override fun setCopy(receiver: TimeEntry, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("project")
}
object TimeEntry_projectName: SerializableProperty<TimeEntry, String?> {
    override val name: String = "projectName"
    override fun get(receiver: TimeEntry): String? = receiver.projectName
    override fun setCopy(receiver: TimeEntry, value: String?) = receiver.copy(projectName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("projectName")
}
object TimeEntry_organization: SerializableProperty<TimeEntry, UUID> {
    override val name: String = "organization"
    override fun get(receiver: TimeEntry): UUID = receiver.organization
    override fun setCopy(receiver: TimeEntry, value: UUID) = receiver.copy(organization = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("organization")
}
object TimeEntry_organizationName: SerializableProperty<TimeEntry, String?> {
    override val name: String = "organizationName"
    override fun get(receiver: TimeEntry): String? = receiver.organizationName
    override fun setCopy(receiver: TimeEntry, value: String?) = receiver.copy(organizationName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("organizationName")
}
object TimeEntry_user: SerializableProperty<TimeEntry, UUID> {
    override val name: String = "user"
    override fun get(receiver: TimeEntry): UUID = receiver.user
    override fun setCopy(receiver: TimeEntry, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("user")
}
object TimeEntry_userName: SerializableProperty<TimeEntry, String?> {
    override val name: String = "userName"
    override fun get(receiver: TimeEntry): String? = receiver.userName
    override fun setCopy(receiver: TimeEntry, value: String?) = receiver.copy(userName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("userName")
}
object TimeEntry_summary: SerializableProperty<TimeEntry, String> {
    override val name: String = "summary"
    override fun get(receiver: TimeEntry): String = receiver.summary
    override fun setCopy(receiver: TimeEntry, value: String) = receiver.copy(summary = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("summary")
}
object TimeEntry_durationMilliseconds: SerializableProperty<TimeEntry, Int> {
    override val name: String = "durationMilliseconds"
    override fun get(receiver: TimeEntry): Int = receiver.durationMilliseconds
    override fun setCopy(receiver: TimeEntry, value: Int) = receiver.copy(durationMilliseconds = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("durationMilliseconds")
}
object TimeEntry_date: SerializableProperty<TimeEntry, LocalDate> {
    override val name: String = "date"
    override fun get(receiver: TimeEntry): LocalDate = receiver.date
    override fun setCopy(receiver: TimeEntry, value: LocalDate) = receiver.copy(date = value)
    override val serializer: KSerializer<LocalDate> = ContextualSerializer(LocalDate::class)
    override val annotations: List<Annotation> = TimeEntry.serializer().tryFindAnnotations("date")
}

package com.lightningtime.views.screens.splitScreens.tasks.comments

import com.lightingtime.*
import com.lightningkite.UUID

class CommentGenerator(val infoSource: HasTaskHierarchyInfo, val creator: UUID) {
    private fun makeComment(content: String, mentions: Set<Mention>, autoGenerated: Boolean) = Comment(
        user = creator,
        task = infoSource.taskID,
        project = infoSource.projectID,
        organization = infoSource.organizationID,
        mentions = mentions,
        autoGenerated = autoGenerated,
        content = content
    )

    fun generate(content: String, mentions: Set<Mention> = emptySet()) = makeComment(content, mentions, autoGenerated = false)

    suspend fun autoGenerate(mentions: Set<Mention> = emptySet(), buildContent: suspend StringBuilder.() -> Unit): Comment = makeComment(
        content = buildString { buildContent() },
        mentions = mentions,
        autoGenerated = true
    )
}
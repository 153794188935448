package com.lightningtime.views

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.hintPopover
import com.lightningkite.kiteui.views.direct.*
import com.lightningtime.theming.emphasized

@ViewModifierDsl3
fun ViewWriter.tooltip(
    tip: String,
    preferredDirection: PopoverPreferredDirection = PopoverPreferredDirection.belowRight,
): ViewWrapper = hintPopover(preferredDirection) { subtext(tip) }

@ViewModifierDsl3
fun ViewWriter.tooltip(
    preferredDirection: PopoverPreferredDirection = PopoverPreferredDirection.belowRight,
    tip: suspend () -> String
): ViewWrapper = hintPopover(preferredDirection) { subtext { ::content { tip() } } }

@ViewDsl
fun ViewWriter.emptyView(dismissRatio: Float = 0.5f, contents: ContainingView.() -> Unit){
    tweakTheme {
        it.copy(
            foreground = Color.interpolate(it.foreground.closestColor(), it.background.closestColor(), dismissRatio)
        )
    } - expanding - stack {
        contents()
    }
}

fun ViewWriter.detail(label: String, content: suspend () -> String) {
    row {
        spacing = 0.5.rem
        centered - emphasized - text(label)
        centered - subtext(":")
        centered - expanding - text { ::content { content() } ; wraps = true }
    }
}

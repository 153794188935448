package com.lightningtime.draftModel

import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.DataClassPath

sealed interface DraftProperty<M, T>: Draft<T>, Listenable {
    val model: Writable<M>
    val propertyPath: DataClassPath<M, T>
}

@Suppress("UNCHECKED_CAST")
class SimpleProperty<M, T>(
    override val model: Writable<M>,
    override val propertyPath: DataClassPath<M, T>,
): DraftProperty<M, T>, DraftWritable<T, T> {
    override val current: Readable<T> get() = writable

    override val original: Readable<T> = shared { propertyPath.get(model()) as T }

    override val writable: LazyProperty<T> = LazyProperty { original() }

    override fun cancel() { if (changesMade.state != ReadableState(false)) writable.reset() }
    override suspend fun commit() = model.set(propertyPath.set(model.awaitOnce(), current.awaitOnce()))

    override fun toString(): String = this.propertyPath.toString()

    var value
        get() = writable.value
        set(value) {
            writable.value = value
        }

    override suspend fun set(value: T) {
        writable.value = value
    }
}

class CollectionProperty<M, T, COLLECTION: Collection<T>>(
    override val model: Writable<M>,
    override val propertyPath: DataClassPath<M, COLLECTION>,
    val collectionTransform: (List<T>) -> COLLECTION
): DraftProperty<M, COLLECTION>, DraftWritable<COLLECTION, List<Property<T>>>{

    val list: LazyProperty<List<Property<T>>> = LazyProperty {
        original().map { Property(it) }
    }
    override val writable: Writable<List<Property<T>>> get() = list

    override val original: Readable<COLLECTION> = shared { propertyPath.get(model()) as COLLECTION }
    override val current: Readable<COLLECTION> = shared { collectionTransform(list().map { it.await() }) }

    override fun cancel() {
        launchGlobal {
            if (changesMade.awaitOnce()) list.value = original.awaitOnce().map { Property(it) }
        }
    }

    override suspend fun commit() = model.set(propertyPath.set(model(), current()))

    override fun toString(): String = this.propertyPath.toString()

    fun remove(element: Property<T>) {
        list.value -= element
    }
    suspend fun remove(element: Readable<Property<T>>) {
        list.value = list.awaitOnce() - element.awaitOnce()
    }

    fun add(element: Property<T>): Property<T> {
        list.value += element
        return element
    }
    override val changesMade: Readable<Boolean>
        get() = shared { original().toList() != list().map { it.await() } }

    fun clear() { list.value = emptyList() }

    infix fun set(value: COLLECTION) {
        list.value = value.map { Property(it) }
    }
}



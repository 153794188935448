package com.lightningtime.views.components.queries

import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.spacing
import com.lightningkite.kiteui.views.l2.icon
import com.lightningtime.theming.tune

fun <SEARCH: SearchField<*>> ViewWriter.searchBar(
    query: Property<String>,
    searchType: Writable<SEARCH>,
    searchFields: Collection<SEARCH>
) = searchBar(query, searchType, Constant(searchFields.toList()))

fun <SEARCH: SearchField<*>> ViewWriter.searchBar(
    query: Property<String>,
    searchType: Writable<SEARCH>,
    searchFields: Readable<List<SEARCH>>
) {
    fieldTheme - row {
        spacing = 0.5.rem
        centered - icon { source = Icon.search }

        expanding - textField {
            ::hint { "Search by ${searchType().displayName}..." }

            content bind query
        }

        menuButton {
            preferredDirection = PopoverPreferredDirection.leftCenter
            spacing = 0.rem

            centered - icon(Icon.tune, "Change Search Settings")

            opensMenu {
                sizeConstraints(width = 10.rem) - label {
                    content = "Search By"

                    fieldTheme - select {
                        spacing = 0.25.rem
                        bind(
                            edits = searchType,
                            data = searchFields,
                            render = { it.toString() }
                        )
                    }
                }
            }
        }

        button {
            spacing = 0.rem
            icon(Icon.close, "Clear Query")
            onClick {
                query.value = ""
            }
        }
    }
}
@file:SharedCode
@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)

//TODO: add some sort of notifications for following a task.

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline

sealed interface TaskTreeItem: HasId<UUID> {
    val title: String
}

interface HasTaskHierarchyInfo {
    val taskID: UUID
    val projectID: UUID
    val organizationID: UUID
}

@JvmInline
@Serializable
value class Mention(val userID: UUID)

annotation class Denormalized

@Serializable
enum class TaskState {
    Cancelled,
    Hold,
    Active,
    PullRequest,
    Testing,
    Approved,
    Delivered,
}

@Serializable
@GenerateDataClassPaths
data class Attachment(
    val name: String,
    val file: ServerFile
)

@AdminTitleFields(["name"])
@AdminSearchFields(["name"])
@AdminTableColumns(["name", "owner"])
@Serializable
@GenerateDataClassPaths
data class Organization(
    override val _id: UUID = uuid(),
    val name: String,
    val createdAt: Instant = now(),
    //TODO: Payment Information
) : HasId<UUID>

@AdminTitleFields(["name"])
@AdminSearchFields(["name"])
@AdminTableColumns(["name", "organization"])
@Serializable
@GenerateDataClassPaths
data class Project(
    override val _id: UUID = uuid(),
    @Index @References(Organization::class) val organization: UUID,     // Can organizationName be denormalized here
    val name: String,
    val rate: Float? = null,
    val notes: String = "",
    val createdAt: Instant = now(),
    val projectTags: Set<String> = setOf()
) : HasId<UUID>, TaskTreeItem {
    override val title: String
        get() = name
}

@AdminTitleFields(["summary"])
@AdminSearchFields(["summary"])
@AdminTableColumns(["projectName", "summary"])
@Serializable
@GenerateDataClassPaths
data class Task(
    override val _id: UUID = uuid(),
    @Index @References(Project::class) val project: UUID,
    @Denormalized val projectName: String? = null,
    @Index @References(Organization::class) val organization: UUID,
    @Denormalized val organizationName: String? = null,
    @Index @References(User::class) val user: UUID? = null,
    @Denormalized val userName: String? = null,
    @Index val state: TaskState,
    val summary: String, //client can change
    val description: String, //client can change
    val attachments: List<Attachment> = emptyList(), //client can change
    val estimate: Int? = null,
    val emergency: Boolean, //client can change
    val priority: Double = 0.0, //client can change
    val tags: Set<String> = setOf(),
    // val lastViewed: Instant? = null,         I would like to have a lastViewed value to track unread comments
    @Index val createdAt: Instant = now(),
    @References(User::class) val createdBy: UUID,
    @Denormalized val creatorName: String? = null,
    val pullRequestLinks: Set<String> = setOf(),
) : HasId<UUID>, TaskTreeItem, HasTaskHierarchyInfo {
    override val title: String
        get() = summary
    override val taskID: UUID
        get() = _id
    override val projectID: UUID
        get() = project
    override val organizationID: UUID
        get() = organization
}

@AdminTitleFields(["userName", "taskSummary"])
@AdminSearchFields(["userName", "taskSummary"])
@Serializable
@GenerateDataClassPaths
data class Comment(
    override val _id: UUID = uuid(),
    @References(User::class) val user: UUID,
    @Denormalized val userName: String? = null,
    @References(Task::class) val task: UUID,
    @Denormalized val taskSummary: String? = null,
    @Index @References(Project::class) val project: UUID,
    @Denormalized val projectName: String? = null,
    @Index @References(Organization::class) val organization: UUID,
    @Denormalized val organizationName: String? = null,
    val createdAt: Instant = now(),
    val content: String,
    val autoGenerated: Boolean = false,
    val mentions: Set<Mention> = emptySet(),
) : HasId<UUID>, HasTaskHierarchyInfo {
    override val taskID: UUID
        get() = task
    override val projectID: UUID
        get() = project
    override val organizationID: UUID
        get() = organization
}

@AdminTitleFields(["userName", "taskSummary", "date"])
@AdminSearchFields(["userName", "taskSummary", "summary"])
@AdminTableColumns(["userName", "projectName", "taskSummary", "date", "durationMilliseconds"])
@Serializable
@GenerateDataClassPaths
data class TimeEntry(
    override val _id: UUID = uuid(),
    @Index @References(Task::class) val task: UUID? = null,
    @Denormalized val taskSummary: String? = null,
    @Index @References(Project::class) val project: UUID,
    @Denormalized val projectName: String? = null,
    @Index @References(Organization::class) val organization: UUID,
    @Denormalized val organizationName: String? = null,
    @Index @References(User::class) val user: UUID,
    @Denormalized val userName: String? = null,
    val summary: String,
    val durationMilliseconds: Int,
    @Index val date: LocalDate,
) : HasId<UUID>, HasTaskHierarchyInfo {
    override val taskID: UUID
        get() = task!!  //TODO: Remove !! once task is no longer nullable
    override val projectID: UUID
        get() = project
    override val organizationID: UUID
        get() = organization
}


@GenerateDataClassPaths
@Serializable
data class Timer(
    override val _id: UUID = uuid(),
    @References(User::class) val user: UUID,
    @References(User::class) val organization: UUID,
    val lastStarted: Instant? = null,
    val accumulatedSeconds: Long = 0,   // If possible, I would like this to match the accumulated time in TimeEntry.
    val task: UUID? = null,
    val project: UUID? = null,
    @Denormalized val taskSummary: String? = null,
    @Denormalized val projectName: String? = null,
    val summary: String = "",
    val date: LocalDate = Clock.default.todayIn(TimeZone.currentSystemDefault()),
) : HasId<UUID>


@AdminTitleFields(["email"])
@AdminSearchFields(["name", "email"])
@AdminTableColumns(["name", "email"])
@Serializable
@GenerateDataClassPaths
data class User(
    override val _id: UUID = uuid(),
    @Unique override val email: String,
    @References(Organization::class) val organization: UUID,
    val name: String,
    val webPreferences: String,
    val theme: String = "default",
    val themeColor: String = "fcb912",
    val summaryTime: String = "Day",
    val isSuperUser: Boolean,
    val role: UserRole? = null,
    @References(Task::class) val currentTask: UUID? = null,
    @MultipleReferences(Project::class) val projectFavorites: Set<UUID> = emptySet(),
    @MultipleReferences(Project::class) val limitToProjects: Set<UUID>? = null,
    val active: Boolean,
) : HasId<UUID>, HasEmail


package com.lightningtime.views.screens.splitScreens.projects

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.navigation.Screen
import com.lightningtime.narrowBreakpoint
import com.lightningtime.views.tools.splitscreens.SplitScreenManager
import com.lightningtime.views.tools.splitscreens.SplitScreenNavigator
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailScreen
import com.lightningtime.views.screens.splitScreens.splitNavigator

@Routable("/projects")
class ProjectsScreen: SplitScreenManager {
    override val splitScreenNavigator: SplitScreenNavigator<DetailScreen> = splitNavigator.also { it.primary = primary }

    override val breakpoint: Dimension = narrowBreakpoint

    override val primary: Screen = ProjectsListScreen()
}
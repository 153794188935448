package com.lightningtime.timeManagement

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.delay
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.HasId
import com.lightningkite.now
import com.lightningtime.draftModel.DraftModel
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.validation.interceptWrite
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailScreen
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedProjectView
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedTaskView
import kotlin.time.Duration.Companion.ZERO
import kotlin.time.Duration.Companion.seconds

class MutableTimer(
    val base: Timer,
    name: String? = null,
): HasId<UUID> {
    override val _id: UUID get() = base._id

    val name = Property(name)

    enum class ParentType { Task, Project }
    class TimerParent(val id: UUID, val type: ParentType)

    var parent =
        base.task?.let { TimerParent(it, ParentType.Task) }
        ?: base.project?.let { TimerParent(it, ParentType.Project) }
        private set

    private suspend fun setName(source: TimerParent?) {
        name.value = when (source?.type) {
            ParentType.Task -> handleCurrentSession().tasks[source.id]()?.summary
            ParentType.Project -> handleCurrentSession().projects[source.id]()?.name
            null -> "Blank Project"
        }
    }

    private suspend fun setParent(new: TimerParent?) {
        val current = this.parent

        if (current?.id == new?.id) return

        setName(new)

        parent = new
    }

    val parentScreen: DetailScreen? get() {
        val p = parent
        return when (p?.type) {
            ParentType.Task -> DetailedTaskView(p.id)
            ParentType.Project -> DetailedProjectView(p.id)
            null -> null
        }
    }


    private val _timer = Property(base)
    val savedTimer: Readable<Timer> = _timer

    val draft = DraftModel(_timer)

    val lastStarted = draft.prop(Timer.path.lastStarted)

    val summary = draft.prop(Timer.path.summary)

    private val _task = draft.prop(Timer.path.task)
    val task = _task.interceptWrite { id ->
        if (id == null) {
            _project.awaitOnce()?.let { setName(TimerParent(it, ParentType.Project)) }
        } else setParent(TimerParent(id, ParentType.Task))

        value = id
        autosave()
    }

    private val _project = draft.prop(Timer.path.project)
    val project = _project.interceptWrite { id ->
        _task.value = null

        setParent(
            id?.let { TimerParent(it, ParentType.Project) }
        )

        value = id
        autosave()
    }

    var accumulatedSeconds = base.accumulatedSeconds
        set(value) {
            savedDuration = value.seconds
            draft.prop(Timer.path.accumulatedSeconds).value = value
            field = value
        }


    var savedDuration = base.accumulatedSeconds.seconds
        private set
    val unsaved = Property(0.seconds)
    val total = shared { savedDuration + unsaved() }

    val editing = Property(false).interceptWrite {
        if (it) {
            lastStarted set null

            unsaved.value = total()
            accumulatedSeconds = 0
        }

        value = it
    }

    val isSelected = shared {
        TimeInfo.selectedTimer()?._id == _id
    }

    private suspend fun save() {
        if (editing.awaitOnce()) return
        accumulatedSeconds += unsaved.awaitOnce().inWholeSeconds
        unsaved.value = ZERO

        handleCurrentSession().timers[_id]
            .modify(draft.serverModification()) ?: throw NullPointerException("Timer not saved correctly")

        draft.commit()

        println("Timer Saved!")
    }

    suspend fun autosave() {
        println("Autosaving")
        lastStarted set now()
        this.save()
    }

    suspend fun resume() {
        if (lastStarted.awaitOnce() == null) lastStarted set now()
        editing set false
        this.save()
    }

    suspend fun pause() {
        lastStarted set null
        this.save()
    }

    private val removers = ArrayList<() -> Unit>()
    private val context = object : CalculationContext {
        override fun onRemove(action: () -> Unit) {
            removers.add(action)
        }
    }
    init {
        val debounced = summary.debounce(5000)
        var startup = true
        var summaryIgnoredOnce = false

        context.reactiveScope {
            rerunOn(debounced)

            if (startup) startup = false
            else if (!summaryIgnoredOnce) summaryIgnoredOnce = true
            else autosave()
        }
    }

    suspend fun remove() {
        if (isSelected()) TimeInfo.selectedTimer set null
        TimeInfo.mutableTimerMap.remove(_id)

        removers.invokeAllSafe()
    }

    suspend fun delete() {
        remove()

        handleCurrentSession().timers[_id].delete()

        println("Deleted timer $_id")
    }
}

fun Timer.mutable(name: String? = null): MutableTimer =
    TimeInfo.mutableTimerMap.getOrPut(_id) {
        MutableTimer(
            this,
            name ?: taskSummary ?: projectName ?: "Blank Timer"
        )
    }

suspend fun Readable<MutableTimer?>.isSelected(): Boolean = this.await()?.isSelected?.await() ?: false

val Readable<MutableTimer?>.summary: Writable<String> get() = shared {
    awaitNotNull().summary()
}.withWrite {
    this@summary.awaitOnce()?.summary?.set(it)
}

fun <T> Readable<MutableTimer?>.getProperty(property: MutableTimer.() -> Writable<T>): Writable<T> = shared {
    awaitNotNull().property().await()
}.withWrite {
    this@getProperty.awaitOnce()?.property()?.set(it)
}

package com.lightningtime.validation

import com.lightningkite.kiteui.reactive.*
import kotlin.jvm.JvmInline

@JvmInline
value class ValidCondition(val condition: Readable<Boolean>): Readable<Boolean> by condition, (suspend (Any?) -> Boolean) {
    suspend fun valid(): Boolean = condition.await()
    override suspend fun invoke(value: Any?): Boolean = valid()

    companion object {
        val Valid = ValidCondition(Constant(true))
        val Invalid = ValidCondition(Constant(false))
    }
}


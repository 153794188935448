package com.lightningtime.sdk

import com.lightningkite.kiteui.reactive.PersistentProperty
import kotlinx.serialization.Serializable


@Serializable
enum class ApiOption(val apiName: String, val http: String, val ws: String) {
    Dev("    ", "TODO", "TODO"),
    Local("Local", "http://localhost:8080", "ws://localhost:8080"),
    Production("Production", "https://time.cs.lightningkite.com", "wss://time.cs.lightningkite.com")
    ;

    val api get() = LiveApi(http, ws)
    fun next(): ApiOption = entries[(ordinal + 1) % entries.size]
}

val selectedApi = PersistentProperty<ApiOption>("apiOption", ApiOption.Production)
package com.lightningtime.views.screens.splitScreens.tasks

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningtime.narrowBreakpoint
import com.lightningtime.views.tools.splitscreens.SplitScreenManager
import com.lightningtime.views.tools.splitscreens.SplitScreenNavigator
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailScreen
import com.lightningtime.views.screens.splitScreens.splitNavigator

@Routable("/tasks")
class TasksScreen : SplitScreenManager {
    override val splitScreenNavigator: SplitScreenNavigator<DetailScreen> = splitNavigator.also { it.primary = primary }

    override val breakpoint: Dimension = narrowBreakpoint

    override val primary: Screen = TaskTree()

//    override val swapTransition: (Screen) -> ScreenTransition = { ScreenTransition.Pop }
}
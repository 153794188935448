package com.lightningtime.views.components

import com.lightingtime.Timer
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.spacing
import com.lightningkite.kiteui.views.l2.icon
import com.lightningtime.sdk.currentSession
import com.lightningtime.theming.*
import com.lightningtime.timeManagement.*
import com.lightningtime.views.screens.splitScreens.detailScreens.TimersScreen
import com.lightningtime.views.screens.splitScreens.splitNavigator
import com.lightningtime.views.tooltip
import kotlin.time.Duration

val selectedTimerNav = NavCustom(
    title = { "Selected Timer" },
    square = {
        val selected = TimeInfo.selectedTimer

        val selectedExists = shared { selected() != null }
        val timerParentScreenSelected = shared {
            selected()?.let { sel ->
                val parent = sel.parent
                val current = splitNavigator.selected.await()

                if (parent == null) {
                    current == null
                }
                else {
                    if (current == null) false
                    else current.id == parent.id
                }
            } ?: false
        }

        lightOutline - menuButton {
            preferredDirection = PopoverPreferredDirection.belowCenter

            centered - row {
                icon {
                    ::source { if (selectedExists()) Icon.clock else Icon.disabledClock }
                }
                onlyWhen { selectedExists() && !timerParentScreenSelected() } - displayDuration { selected()?.total() ?: Duration.ZERO }
            }

            opensMenu {
                sizeConstraints(width = 20.rem) - label {
                    content = "Active Timer"

                    onlyWhen { selectedExists() } - col {
                        spacing = 0.5.rem

                        button {
                            spacing = 0.rem
                            emphasized - atStart - text {
                                ::content { selected()?.name() ?: "No Name" }
                            }

                            onClick {
                                selected()?.parentScreen?.let {
                                    splitNavigator.navigate(it)
                                }
                            }
                        }

                        lightOutline - col {
                            row {
                                editableTimer(selected.waitForNotNull)

                                lightOutline - expanding - row {
                                    spacing = 0.5.rem

                                    expanding - removeOutline - timerPlayPauseButton(selected.waitForNotNull)

                                    expanding - removeOutline - button {
                                        spacing = 0.1.rem
                                        centered - icon(Icon.close, "Remove Current Timer")

                                        onClick { TimeInfo.selectedTimer set null }
                                    } in tooltip("Clear Selected Timer", PopoverPreferredDirection.belowLeft)
                                }
                            }

                            fieldTheme - textArea {
                                content bind selected.summary
                            }
                        }
                    }

                    important - onlyWhen { !selectedExists() } - button {
                        spacing = 0.5.rem

                        centered - row {
                            spacing = 0.5.rem

                            icon { source = Icon.addClock }
                            centered - bold - text("Add Blank Timer")
                        }

                        onClick {
                            val session = currentSession()
                            val self = session.self()

                            val timer = session.timers.insert(
                                Timer(
                                    user = self._id,
                                    organization = self.organization
                                )
                            )() ?: throw NullPointerException("Problem inserting new timer")

                            TimeInfo.selectedTimer set timer.mutable("Blank Timer")
                        }
                    }

                    lightOutline - button {
                        spacing = 0.5.rem

                        centered - row {
                            spacing = 0.5.rem

                            sizeConstraints(height = 2.rem) - centered - icon {
                                source = Icon.clock
                            }
                            centered - text("View All Timers")
                        }

                        onClick {
                            splitNavigator.externalNavigate(TimersScreen())
                        }
                    }
                }
            }
        }
    }
)

package com.lightningtime

import com.lightingtime.prepareModels
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.DefaultSerializersModule
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.l2.*
import com.lightningkite.lightningdb.ClientModule
import com.lightningtime.theming.*
import com.lightningtime.views.components.selectedTimerNav
import com.lightningtime.views.screens.auth.AuthScreen
import com.lightningtime.views.screens.AutoRoutes
import com.lightningtime.views.screens.splitScreens.tasks.TasksScreen
import com.lightningtime.views.screens.splitScreens.detailScreens.TimersScreen
import com.lightningtime.views.screens.splitScreens.projects.ProjectsScreen
import com.lightningtime.views.screens.splitScreens.splitNavigator
import com.lightningtime.views.components.loggedTimeNav
import com.lightningtime.views.screens.SettingsScreen
import com.lightningtime.views.screens.timeEntry.TimeEntryScreen
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.plus

//val appTheme = Property<Theme>(Theme.flat(hue = Angle(0.6), saturation = 0.05f).copy(
//    cornerRadii = CornerRadii.RatioOfSpacing(0.1f)
//))

val narrowBreakpoint = 65.rem

fun ViewWriter.app() {
    prepareModels()
    DefaultSerializersModule = ClientModule

    appNavFactory.value = ViewWriter::appNavHamburger

    rootTheme = {
//        println("Recalculating root theme")
        appTheme()()
    }

    appNav(AutoRoutes) {
        splitNavigator.rootNavigator = navigator

        appName = "LightningTime"
        navItems = listOf(
            NavLink("Tasks", Icon.checklist) { TasksScreen() },
            NavLink("Projects", Icon.target) { ProjectsScreen() },
            NavLink("Time Entry", Icon.clock) { TimeEntryScreen() },
            NavLink("Settings", Icon.settings) { SettingsScreen() }
        )

        ::exists {
            navigator.currentScreen() !is AuthScreen
        }

        ::actions {
            listOf(
                selectedTimerNav,
                loggedTimeNav
            )
        }
    }
}

package com.lightningtime.views.screens.auth

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.delay
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningtime.sdk.selectedApi
import com.lightningtime.sdk.sessionToken
import com.lightningkite.kiteui.views.direct.*
import com.lightningtime.views.screens.splitScreens.tasks.TasksScreen

@Routable("/auth/passcode")
class PasscodeScreen: Screen, AuthScreen {
    var enteredEmail: String? = null
    var passcodeToken: String? = null

    val enteredPasscode = Property("")
    val invalid = shared { enteredPasscode().length != 6 }

    override fun ViewWriter.render() {
        if (enteredEmail == null) navigator.reset(LoginScreen())

        suspend fun confirmPasscode() {
            val api = selectedApi().api
            val proof = api.emailProof.proveEmailOwnership(FinishProof(passcodeToken!!, enteredPasscode()))
            val session = api.userAuth.logIn(listOf(proof))
            sessionToken set session.session
            delay(2000)

            navigator.reset(TasksScreen())
        }

        stack {
            centered - card - sizeConstraints(width = 25.rem) - col {
                spacing = 1.5.rem

                bold - h2("Pin Sent!")

                text {
                    content = "To log in enter the 6-digit pin sent to \n\"$enteredEmail\""
                }

                themeFromLast { theme ->
                    if (invalid()) theme.warning()
                    else theme.field()
                } -
                textField {
                    hint = "Passcode"
                    keyboardHints = KeyboardHints.id
                    content bind enteredPasscode

                    action = Action(
                        title = "Log In",
                        icon = Icon.login,
                        onSelect = { if (!invalid()) confirmPasscode() }
                    )
                }

                important - button {
                    spacing = 0.5.rem
                    centered - text("Submit")
                    onClick(::confirmPasscode)
                }
            }
        }
    }
}
package com.lightningtime.draftModel

import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.lightningdb.DataClassPath
import com.lightningkite.lightningdb.HasId
import kotlin.jvm.JvmName

@JvmName("listCollectionProp")
fun <M, T> DraftModel<M>.collectionProp(
    propertyPath: DataClassPath<M, List<T>>,
): CollectionProperty<M, T, List<T>> = collectionProp(propertyPath) { it }

@JvmName("setCollectionProp")
fun <M, T> DraftModel<M>.collectionProp(
    propertyPath: DataClassPath<M, Set<T>>,
): CollectionProperty<M, T, Set<T>> = collectionProp(propertyPath) { it.toSet() }

fun <ID, MODEL: HasId<ID>> MODEL.getDraft(map: MutableMap<ID, DraftModel<MODEL>>): DraftModel<MODEL> =
    map.getOrPut(_id) {
        DraftModel(Property(this))
    }
@file:SharedCode
package com.lightningtime.sdk

import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.*
import com.lightningkite.lightningdb.*
import com.lightningkite.kiteui.*
import kotlinx.datetime.*
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightingtime.Comment
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.lightningdb.Partial
import com.lightningkite.UUID
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningdb.CollectionUpdates
import com.lightingtime.Organization
import com.lightingtime.Project
import com.lightingtime.Task
import com.lightingtime.TimeEntry
import com.lightingtime.Timer
import com.lightingtime.User
import kotlin.String
import com.lightningkite.lightningserver.auth.old.EmailPinLogin
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import kotlin.Boolean
import com.lightningkite.lightningserver.auth.proof.SecretMetadata
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.lightningkite.lightningserver.auth.subject.Session
import com.lightningkite.lightningserver.auth.proof.OtpSecret

class LiveApi(val httpUrl: String, val socketUrl: String): Api {
    override val comment: Api.CommentApi = LiveCommentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val organization: Api.OrganizationApi = LiveOrganizationApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val project: Api.ProjectApi = LiveProjectApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val task: Api.TaskApi = LiveTaskApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val timeEntry: Api.TimeEntryApi = LiveTimeEntryApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val timer: Api.TimerApi = LiveTimerApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val user: Api.UserApi = LiveUserApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val auth: Api.AuthApi = LiveAuthApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val emailProof: Api.EmailProofApi = LiveEmailProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val oneTimePasswordProof: Api.OneTimePasswordProofApi = LiveOneTimePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userAuth: Api.UserAuthApi = LiveUserAuthApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userSession: Api.UserSessionApi = LiveUserSessionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val otpSecretForUser: Api.OtpSecretForUserApi = LiveOtpSecretForUserApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override suspend fun uploadFileForRequest(): UploadInformation = fetch(
        url = "$httpUrl/upload-early",
        method = HttpMethod.GET,
    )
    override suspend fun getServerHealth(userAccessToken: suspend () -> String): ServerHealth = fetch(
        url = "$httpUrl/meta/health",
        method = HttpMethod.GET,
            token = userAccessToken,
    )
    override suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "$httpUrl/meta/bulk",
        method = HttpMethod.POST,
        body = input
    )
    class LiveCommentApi(val httpUrl: String, val socketUrl: String): Api.CommentApi {
        override suspend fun default(userAccessToken: suspend () -> String): Comment = fetch(
            url = "$httpUrl/comments/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Comment>, userAccessToken: suspend () -> String): List<Comment> = fetch(
            url = "$httpUrl/comments/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Comment>, userAccessToken: suspend () -> String): List<Partial<Comment>> = fetch(
            url = "$httpUrl/comments/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Comment = fetch(
            url = "$httpUrl/comments/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Comment>, userAccessToken: suspend () -> String): List<Comment> = fetch(
            url = "$httpUrl/comments/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Comment, userAccessToken: suspend () -> String): Comment = fetch(
            url = "$httpUrl/comments/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Comment, userAccessToken: suspend () -> String): Comment = fetch(
            url = "$httpUrl/comments/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Comment>, userAccessToken: suspend () -> String): List<Comment> = fetch(
            url = "$httpUrl/comments/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Comment, userAccessToken: suspend () -> String): Comment = fetch(
            url = "$httpUrl/comments/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Comment>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/comments/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Comment>, userAccessToken: suspend () -> String): EntryChange<Comment> = fetch(
            url = "$httpUrl/comments/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Comment>, userAccessToken: suspend () -> String): Comment = fetch(
            url = "$httpUrl/comments/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Comment>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/comments/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/comments/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Comment>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/comments/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Comment>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/comments/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Comment>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/comments/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Comment>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/comments/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Comment>, CollectionUpdates<Comment, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/comments/rest",
                token = userToken,
            )
    }
    class LiveOrganizationApi(val httpUrl: String, val socketUrl: String): Api.OrganizationApi {
        override suspend fun default(userAccessToken: suspend () -> String): Organization = fetch(
            url = "$httpUrl/organizations/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Organization>, userAccessToken: suspend () -> String): List<Organization> = fetch(
            url = "$httpUrl/organizations/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Organization>, userAccessToken: suspend () -> String): List<Partial<Organization>> = fetch(
            url = "$httpUrl/organizations/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Organization = fetch(
            url = "$httpUrl/organizations/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Organization>, userAccessToken: suspend () -> String): List<Organization> = fetch(
            url = "$httpUrl/organizations/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Organization, userAccessToken: suspend () -> String): Organization = fetch(
            url = "$httpUrl/organizations/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Organization, userAccessToken: suspend () -> String): Organization = fetch(
            url = "$httpUrl/organizations/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Organization>, userAccessToken: suspend () -> String): List<Organization> = fetch(
            url = "$httpUrl/organizations/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Organization, userAccessToken: suspend () -> String): Organization = fetch(
            url = "$httpUrl/organizations/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Organization>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/organizations/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Organization>, userAccessToken: suspend () -> String): EntryChange<Organization> = fetch(
            url = "$httpUrl/organizations/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Organization>, userAccessToken: suspend () -> String): Organization = fetch(
            url = "$httpUrl/organizations/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Organization>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/organizations/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/organizations/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Organization>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/organizations/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Organization>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/organizations/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Organization>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/organizations/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Organization>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/organizations/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Organization>, CollectionUpdates<Organization, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/organizations/rest",
                token = userToken,
            )
    }
    class LiveProjectApi(val httpUrl: String, val socketUrl: String): Api.ProjectApi {
        override suspend fun default(userAccessToken: suspend () -> String): Project = fetch(
            url = "$httpUrl/projects/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Project>, userAccessToken: suspend () -> String): List<Project> = fetch(
            url = "$httpUrl/projects/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Project>, userAccessToken: suspend () -> String): List<Partial<Project>> = fetch(
            url = "$httpUrl/projects/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Project>, userAccessToken: suspend () -> String): List<Project> = fetch(
            url = "$httpUrl/projects/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Project, userAccessToken: suspend () -> String): Project = fetch(
            url = "$httpUrl/projects/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Project, userAccessToken: suspend () -> String): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Project>, userAccessToken: suspend () -> String): List<Project> = fetch(
            url = "$httpUrl/projects/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Project, userAccessToken: suspend () -> String): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Project>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/projects/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Project>, userAccessToken: suspend () -> String): EntryChange<Project> = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Project>, userAccessToken: suspend () -> String): Project = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Project>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/projects/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/projects/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Project>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/projects/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Project>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/projects/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Project>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/projects/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Project>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/projects/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Project>, CollectionUpdates<Project, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/projects/rest",
                token = userToken,
            )
    }
    class LiveTaskApi(val httpUrl: String, val socketUrl: String): Api.TaskApi {
        override suspend fun default(userAccessToken: suspend () -> String): Task = fetch(
            url = "$httpUrl/tasks/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Task>, userAccessToken: suspend () -> String): List<Task> = fetch(
            url = "$httpUrl/tasks/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Task>, userAccessToken: suspend () -> String): List<Partial<Task>> = fetch(
            url = "$httpUrl/tasks/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Task = fetch(
            url = "$httpUrl/tasks/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Task>, userAccessToken: suspend () -> String): List<Task> = fetch(
            url = "$httpUrl/tasks/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Task, userAccessToken: suspend () -> String): Task = fetch(
            url = "$httpUrl/tasks/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Task, userAccessToken: suspend () -> String): Task = fetch(
            url = "$httpUrl/tasks/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Task>, userAccessToken: suspend () -> String): List<Task> = fetch(
            url = "$httpUrl/tasks/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Task, userAccessToken: suspend () -> String): Task = fetch(
            url = "$httpUrl/tasks/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Task>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/tasks/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Task>, userAccessToken: suspend () -> String): EntryChange<Task> = fetch(
            url = "$httpUrl/tasks/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Task>, userAccessToken: suspend () -> String): Task = fetch(
            url = "$httpUrl/tasks/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Task>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/tasks/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/tasks/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Task>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/tasks/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Task>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/tasks/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Task>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/tasks/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Task>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/tasks/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Task>, CollectionUpdates<Task, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/tasks/rest",
                token = userToken,
            )
    }
    class LiveTimeEntryApi(val httpUrl: String, val socketUrl: String): Api.TimeEntryApi {
        override suspend fun default(userAccessToken: suspend () -> String): TimeEntry = fetch(
            url = "$httpUrl/time-entries/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<TimeEntry>, userAccessToken: suspend () -> String): List<TimeEntry> = fetch(
            url = "$httpUrl/time-entries/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<TimeEntry>, userAccessToken: suspend () -> String): List<Partial<TimeEntry>> = fetch(
            url = "$httpUrl/time-entries/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): TimeEntry = fetch(
            url = "$httpUrl/time-entries/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<TimeEntry>, userAccessToken: suspend () -> String): List<TimeEntry> = fetch(
            url = "$httpUrl/time-entries/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: TimeEntry, userAccessToken: suspend () -> String): TimeEntry = fetch(
            url = "$httpUrl/time-entries/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: TimeEntry, userAccessToken: suspend () -> String): TimeEntry = fetch(
            url = "$httpUrl/time-entries/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<TimeEntry>, userAccessToken: suspend () -> String): List<TimeEntry> = fetch(
            url = "$httpUrl/time-entries/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: TimeEntry, userAccessToken: suspend () -> String): TimeEntry = fetch(
            url = "$httpUrl/time-entries/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<TimeEntry>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/time-entries/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<TimeEntry>, userAccessToken: suspend () -> String): EntryChange<TimeEntry> = fetch(
            url = "$httpUrl/time-entries/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<TimeEntry>, userAccessToken: suspend () -> String): TimeEntry = fetch(
            url = "$httpUrl/time-entries/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<TimeEntry>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/time-entries/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/time-entries/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<TimeEntry>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/time-entries/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<TimeEntry>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/time-entries/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<TimeEntry>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/time-entries/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<TimeEntry>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/time-entries/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<TimeEntry>, CollectionUpdates<TimeEntry, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/time-entries/rest",
                token = userToken,
            )
    }
    class LiveTimerApi(val httpUrl: String, val socketUrl: String): Api.TimerApi {
        override suspend fun default(userAccessToken: suspend () -> String): Timer = fetch(
            url = "$httpUrl/timers/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Timer>, userAccessToken: suspend () -> String): List<Timer> = fetch(
            url = "$httpUrl/timers/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Timer>, userAccessToken: suspend () -> String): List<Partial<Timer>> = fetch(
            url = "$httpUrl/timers/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Timer = fetch(
            url = "$httpUrl/timers/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Timer>, userAccessToken: suspend () -> String): List<Timer> = fetch(
            url = "$httpUrl/timers/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Timer, userAccessToken: suspend () -> String): Timer = fetch(
            url = "$httpUrl/timers/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Timer, userAccessToken: suspend () -> String): Timer = fetch(
            url = "$httpUrl/timers/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Timer>, userAccessToken: suspend () -> String): List<Timer> = fetch(
            url = "$httpUrl/timers/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Timer, userAccessToken: suspend () -> String): Timer = fetch(
            url = "$httpUrl/timers/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Timer>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/timers/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Timer>, userAccessToken: suspend () -> String): EntryChange<Timer> = fetch(
            url = "$httpUrl/timers/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Timer>, userAccessToken: suspend () -> String): Timer = fetch(
            url = "$httpUrl/timers/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Timer>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/timers/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/timers/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Timer>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/timers/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Timer>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/timers/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Timer>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/timers/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Timer>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/timers/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Timer>, CollectionUpdates<Timer, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/timers/rest",
                token = userToken,
            )
    }
    class LiveUserApi(val httpUrl: String, val socketUrl: String): Api.UserApi {
        override suspend fun default(userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<User>, userAccessToken: suspend () -> String): List<User> = fetch(
            url = "$httpUrl/users/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<User>, userAccessToken: suspend () -> String): List<Partial<User>> = fetch(
            url = "$httpUrl/users/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<User>, userAccessToken: suspend () -> String): List<User> = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: User, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: User, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<User>, userAccessToken: suspend () -> String): List<User> = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: User, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<User>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>, userAccessToken: suspend () -> String): EntryChange<User> = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<User>, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<User>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/users/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<User>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/users/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<User>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/users/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<User>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/users/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/users/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<User>, CollectionUpdates<User, UUID>> =
            multiplexedSocket(
                socketUrl = socketUrl,
                path = "/users/rest",
                token = userToken,
            )
    }

    class LiveAuthApi(val httpUrl: String, val socketUrl: String) : Api.AuthApi {
        override suspend fun refreshToken(userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/auth/refresh-token",
            method = HttpMethod.GET,
            token = userAccessToken,
        )

        override suspend fun getSelf(userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/auth/self",
            method = HttpMethod.GET,
            token = userAccessToken,
        )

        override suspend fun anonymousToken(): String = fetch(
            url = "$httpUrl/auth/anonymous",
            method = HttpMethod.GET,
        )

        override suspend fun emailLoginLink(input: String): Unit = fetch(
            url = "$httpUrl/auth/login-email",
            method = HttpMethod.POST,
            body = input
        )

        override suspend fun emailPINLogin(input: EmailPinLogin): String = fetch(
            url = "$httpUrl/auth/login-email-pin",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveEmailProofApi(val httpUrl: String, val socketUrl: String): Api.EmailProofApi {
        override suspend fun beginEmailOwnershipProof(input: String): String = fetch(
            url = "$httpUrl/auth/proof/email/start",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = fetch(
            url = "$httpUrl/auth/proof/email/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveOneTimePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.OneTimePasswordProofApi {
        override suspend fun establishAnOneTimePassword(input: EstablishOtp): String = fetch(
            url = "$httpUrl/auth/proof/otp/establish",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun confirmOneTimePassword(input: String): Unit = fetch(
            url = "$httpUrl/auth/proof/otp/existing",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun disableOneTimePassword(): Boolean = fetch(
            url = "$httpUrl/auth/proof/otp/existing",
            method = HttpMethod.DELETE,
        )
        override suspend fun checkOneTimePassword(): SecretMetadata? = fetch(
            url = "$httpUrl/auth/proof/otp/existing",
            method = HttpMethod.GET,
        )
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/auth/proof/otp/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveUserAuthApi(val httpUrl: String, val socketUrl: String): Api.UserAuthApi {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/user/login",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun openSession(input: String): String = fetch(
            url = "$httpUrl/auth/user/open-session",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun createSubSession(input: SubSessionRequest, userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/auth/user/sub-session",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = fetch(
            url = "$httpUrl/auth/user/token",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getTokenSimple(input: String): String = fetch(
            url = "$httpUrl/auth/user/token/simple",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getSelf(userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/auth/user/self",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
    }
    class LiveUserSessionApi(val httpUrl: String, val socketUrl: String): Api.UserSessionApi {
        override suspend fun query(input: Query<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Partial<Session<User, UUID>>> = fetch(
            url = "$httpUrl/auth/user/sessions/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Session<User, UUID>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Session<User, UUID>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Session<User, UUID>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/user/sessions/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>, userAccessToken: suspend () -> String): EntryChange<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/user/sessions/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/user/sessions/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Session<User, UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/user/sessions/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/auth/user/sessions/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/auth/user/sessions/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/user/sessions/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun terminateSession(userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/user/sessions/terminate",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun terminateOtherSession(sessionId: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/user/sessions/${sessionId.urlify()}/terminate",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
    }
    class LiveOtpSecretForUserApi(val httpUrl: String, val socketUrl: String): Api.OtpSecretForUserApi {
        override suspend fun query(input: Query<OtpSecret<UUID>>, userAccessToken: suspend () -> String): List<OtpSecret<UUID>> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<OtpSecret<UUID>>, userAccessToken: suspend () -> String): List<Partial<OtpSecret<UUID>>> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): OtpSecret<UUID> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<OtpSecret<UUID>>, userAccessToken: suspend () -> String): List<OtpSecret<UUID>> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: OtpSecret<UUID>, userAccessToken: suspend () -> String): OtpSecret<UUID> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: OtpSecret<UUID>, userAccessToken: suspend () -> String): OtpSecret<UUID> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<OtpSecret<UUID>>, userAccessToken: suspend () -> String): List<OtpSecret<UUID>> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: OtpSecret<UUID>, userAccessToken: suspend () -> String): OtpSecret<UUID> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<OtpSecret<UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret<UUID>>, userAccessToken: suspend () -> String): EntryChange<OtpSecret<UUID>> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<OtpSecret<UUID>>, userAccessToken: suspend () -> String): OtpSecret<UUID> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<OtpSecret<UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<OtpSecret<UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret<UUID>>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<OtpSecret<UUID>>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret<UUID>>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/proof/otp/secrets/user/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
}


package com.lightningtime

import com.lightingtime.TaskState
import com.lightingtime.utils.Regexes
import com.lightningkite.kiteui.reactive.*

fun ImmediateWritable<Boolean>.toggle() { value = !value }
suspend fun Writable<Boolean>.toggle() = set(!awaitOnce())

object SpecialCharacters {
    val zeroWidthSpace = '\u200B'
    val rightArrow = '\u2192'
    val bulletPoint = '\u2022'
}

fun String.allCapsToTitle(): String = lowercase().replace(Regexes.lowercaseBoundary) { it.value.uppercase() }

fun String.camelToTitle(): String =
    replace(Regexes.camelBordersRegex) {
        it.groupValues
            .drop(1)
            .filter { it.isNotBlank() }
            .joinToString(" ")
    }.replaceFirstChar { it.uppercase() }

fun TaskState.format(): String = toString().camelToTitle()

package com.lightningtime.validation

import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.reactive.*
import com.lightningtime.draftModel.DraftProperty

fun <T, READABLE: Readable<T>> READABLE.interceptWrite(action: suspend READABLE.(T) -> Unit): Writable<T> =
    object: Writable<T>, Readable<T> by this {
        override suspend fun set(value: T) {
            action(this@interceptWrite, value)
        }
    }

interface Validated {
    val validator: Validator

    val valid: ValidCondition

    fun removeCondition() = validator.removeCondition(valid)
}

interface ValidatedWritable<T>: Validated, Writable<T> {
    suspend fun refresh()
}

fun <T> Writable<T>.withValidation(
    validator: Validator,
    startsValid: Boolean = false,
    validCondition: suspend (T) -> Boolean
): ValidatedWritable<T> = object: ValidatedWritable<T>, Readable<T> by this {
    override val validator: Validator = validator

    private val debug: Console? = null

    private val isValid = Property(startsValid)

    override val valid: ValidCondition =
        if (validCondition is ValidCondition) validCondition
        else validator.addCondition( isValid )

    private suspend fun checkValid(value: T) {
        var result: Boolean? = null

        if (validCondition !is ValidCondition) {
            result = validCondition(value)
            debug?.log("Setting Value: $value")
            isValid.value = result
        }

        debug?.log("Checking validity with value [ $value ] with result [ $result ]")
    }

    override suspend fun refresh() {
        debug?.run {
            log("Refreshing (Current state: ${state})" + if (this@withValidation is DraftProperty<*, *>) "(Property: ${propertyPath})" else "")
        }
        checkValid(this@withValidation.awaitOnce())
        debug?.log("Finished refreshing")
    }

    override suspend fun set(value: T) {
        checkValid(value)
        this@withValidation set value
    }

    init {
        launchGlobal {
            debug?.log("Initial Refresh")
            refresh()
        }
    }
}

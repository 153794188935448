package com.lightningtime.views.screens.splitScreens.detailScreens

import com.lightingtime.*
import com.lightingtime.Project
import com.lightingtime.Task
import com.lightingtime.TimeEntry
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningtime.SpecialCharacters
import com.lightningtime.format
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.theming.*
import com.lightningtime.timeManagement.*
import com.lightningtime.toggle
import com.lightningtime.views.*
import com.lightningtime.views.components.SearchableTab
import com.lightningtime.views.components.queries.*
import com.lightningtime.views.components.searchableTabs
import com.lightningtime.views.screens.splitScreens.tasks.Recents
import com.lightningtime.views.screens.splitScreens.detailScreens.forms.ProjectForm
import com.lightningtime.views.screens.splitScreens.tasks.MockProjects
import kotlin.time.Duration.Companion.milliseconds


@Routable("/project/{projectID}")
class DetailedProjectView(val projectID: UUID): DetailScreen {
    override val id: UUID get() = projectID
    override val title: Readable<String> = Constant("Project")

    private var isMockProject = false

    val project = LateInitProperty<Project>()
    val organization = LateInitProperty<Organization>()

    init {
        MockProjects.entries[projectID]?.let {
            isMockProject = true
            project.value = it
            organization.value = MockProjects.organization
        }
    }

    var savedTimer: MutableTimer? = null
    private suspend fun createNewTimer(): MutableTimer {
        val session = handleCurrentSession()

        var t = Timer(
            user = session.userId,
            organization = project().organization,
            lastStarted = now(),
            accumulatedSeconds = 0L,
            project = projectID,
            summary = "",
        )

        t = session.timers.insert(t)() ?: throw NullPointerException("Issue inserting new timer")

        return t.mutable().also { savedTimer = it }
    }

//    suspend fun Timer.mutable(): MutableTimer = MutableTimer(this, project().name)

    val timer = if (isMockProject) Constant(null) else
        shared {
            val selected = TimeInfo.selectedTimer()

            selected?.takeIf { it.parent?.id == projectID }
                ?: TimeInfo.unsubmittedTimers.awaitOnce()
                    .find { it.project == projectID && it.task == null }
                    ?.mutable()
        }

    private val timeEntries = if (isMockProject) Constant(emptyList()) else
        shared {
            val session = currentSession()
            session.timeEntries.query(Query(
                condition { it.project eq projectID },
                sort { it.date.descending() }
            ))()
        }

    private val taskTab = SearchableTab(
        name = "Tasks",
        icon = Icon.clock,
        searchFields = SearchField.Tasks.entries,
        startingField = SearchField.Tasks.Summary,
        getItems = { query, searchType ->
            currentSession().tasks.query(
                Query(
                    Condition.And(
                        listOfNotNull(
                            condition { it.project eq projectID },
                            query?.queryCondition(searchType)
                        )
                    ),
                    orderBy = sort { it.createdAt.descending() }
                )
            )()
        },
        renderItem = { task ->
            fun TextView.info(info: Task.() -> String) {
                ::content { task().info() }
            }

            card - splitLink {
                spacing = 1.rem

                to = {
                    val t = task()
                    DetailedTaskView(t._id).also { it.task.value = t }
                }

                col {
                    spacing = 0.5.rem
                    h5 { info { summary } }

                    separator()

                    row {
                        expanding - centered - col {
                            spacing = 0.5.rem

                            detail("Assigned To") { task().userName ?: "Nobody" }
                            detail("Created") { task().createdAt.formatRelative() }
                        }
                        centered - embedded - emphasized - h6 {
                            info { state.format() }
                        }
                    }
                }
            }
        }
    )

    private val timeEntryTab = SearchableTab(
        name = "Time Entries",
        icon = Icon.timeEntry,
        searchFields = SearchField.TimeEntries.entries,
        startingField = SearchField.TimeEntries.TaskSummary,
        getItems = { query, field ->
            val queryCondition = query?.queryCondition(field) ?: Condition.Always()

            timeEntries().filter { queryCondition(it) }
        },
        renderItem = { entry ->
            fun TextView.info(info: TimeEntry.() -> String) {
                ::content { entry().info() }
            }

            val showingSummary = Property(false)

            card - stack {
                reactiveScope {
                    rerunOn(entry)
                    showingSummary.value = false
                }
                spacing = 0.rem

                padded - col {
                    spacing = 1.rem

                    col {
                        spacing = 0.5.rem
                        h5 { info { taskSummary ?: projectName ?: "Task/Project Name Not Found" } }

                        separator()

                        row {
                            expanding - centered - col {
                                spacing = 0.5.rem

                                detail("User") { entry().userName ?: "Not Found" }
                                detail("Date") { entry().date.formatRelative() }
                            }
                            centered - embedded - emphasized - h6 { info { durationMilliseconds.milliseconds.format() } }
                        }

                        embedded - onlyWhen { showingSummary() } - col {
                            emphasized - text("Summary")
                            text { info { summary } }
                        }
                    }
                }

                atBottom - button {
                    spacing = 0.rem
                    sizeConstraints(height = 1.rem) - centered - icon {
                        ::source {
                            if (showingSummary()) Icon.chevronUp
                            else Icon.chevronDown
                        }
                    }
                    onClick { showingSummary.toggle() }
                }
            }
        }
    )

    override fun ViewWriter.render() {
        col {
            if (!isMockProject) {
                launch {
                    val session = currentSession()
                    if (!project.state.ready) {
                        project.value = session.projects[projectID]() ?: throw NullPointerException("Project Not Found")
                    }

                    if (!organization.state.ready) {
                        organization.value = session.organizations[project().organization]()
                            ?: throw NullPointerException("Organization Not Found")
                    }
                }
            }

            sizeConstraints(height = 3.rem) - row {
                centered - expanding - h2 { ::content { project().name } }

                if (!isMockProject) row {
                    spacing = 0.5.rem

                    timerControlPanel(timer, ::createNewTimer)
                }
            }

            if (isMockProject) {
                expanding - emptyView { centered - h3("This isn't a real project.") }
                return@col
            }

            val showingDetails = Property(true)

            separator { ::exists { showingDetails() } }

            weight(1.5f) - onlyWhen { showingDetails() } - row {
                expanding - label {
                    content = "Project Details"

                    expanding - card - scrolls - col {
                        sizeConstraints(height = 2.rem) - row {
                            if (!isMockProject) important - splitLink {
                                spacing = 0.rem

                                to = { ProjectForm(projectID).setEditing(project()) }

                                icon(Icon.edit, "Edit Project")

                            } in tooltip("Edit Project")

                            centered - expanding - h3("Details")
                        }

                        col {
                            embedded - col {
                                detail("Rate") { "$${project().rate ?: 0} / Hour" }
                                detail("Total Duration") {
                                    timeEntries().sumOf { it.durationMilliseconds }.milliseconds.format()
                                }
                                detail("Created") { project().createdAt.formatRelative() }
                            }

                            embedded - col {
                                emphasized - text("Notes")

                                text { ::content { project().notes.ifBlank { "None" } } }
                            }

                            embedded - col {
                                val tags = shared { project().projectTags.toList() }

                                emphasized - text("Tags")

                                onlyWhen { tags().isEmpty() } - text("None")
                                onlyWhen { tags().isNotEmpty() } - col {
                                    forEachUpdating(tags) { tag ->
                                        text { ::content { SpecialCharacters.bulletPoint + " " + tag() } }
                                    }
                                }
                            }
                        }
                    }
                }

                expanding - label {
                    ::exists { timer.isSelected() }

                    content = "Timer Summary"

                    fieldTheme - expanding - textArea {
                        hint = "Summary"
                        content bind timer.summary
                    }
                }
            }

            unpadded - button {
                spacing = 0.1.rem

                row {
                    spacing = 0.5.rem

                    expanding - centered - col { separator() }

                    sizeConstraints(height = 1.2.rem) - centered - icon {
                        ::source {
                            if (showingDetails()) Icon.chevronUp
                            else Icon.chevronDown
                        }
                    }

                    expanding - centered - col { separator() }
                }

                onClick { showingDetails.toggle() }
            }

            card - searchableTabs(taskTab, timeEntryTab)
        }
    }
}
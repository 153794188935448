@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareCommentFields() {
    val props: Array<SerializableProperty<Comment, *>> = arrayOf(Comment__id, Comment_user, Comment_userName, Comment_task, Comment_taskSummary, Comment_project, Comment_projectName, Comment_organization, Comment_organizationName, Comment_createdAt, Comment_content, Comment_autoGenerated, Comment_mentions)
    Comment.serializer().properties { props }
}
val <K> DataClassPath<K, Comment>._id: DataClassPath<K, UUID> get() = this[Comment__id]
val <K> DataClassPath<K, Comment>.user: DataClassPath<K, UUID> get() = this[Comment_user]
val <K> DataClassPath<K, Comment>.userName: DataClassPath<K, String?> get() = this[Comment_userName]
val <K> DataClassPath<K, Comment>.task: DataClassPath<K, UUID> get() = this[Comment_task]
val <K> DataClassPath<K, Comment>.taskSummary: DataClassPath<K, String?> get() = this[Comment_taskSummary]
val <K> DataClassPath<K, Comment>.project: DataClassPath<K, UUID> get() = this[Comment_project]
val <K> DataClassPath<K, Comment>.projectName: DataClassPath<K, String?> get() = this[Comment_projectName]
val <K> DataClassPath<K, Comment>.organization: DataClassPath<K, UUID> get() = this[Comment_organization]
val <K> DataClassPath<K, Comment>.organizationName: DataClassPath<K, String?> get() = this[Comment_organizationName]
val <K> DataClassPath<K, Comment>.createdAt: DataClassPath<K, Instant> get() = this[Comment_createdAt]
val <K> DataClassPath<K, Comment>.content: DataClassPath<K, String> get() = this[Comment_content]
val <K> DataClassPath<K, Comment>.autoGenerated: DataClassPath<K, Boolean> get() = this[Comment_autoGenerated]
val <K> DataClassPath<K, Comment>.mentions: DataClassPath<K, Set<Mention>> get() = this[Comment_mentions]
inline val Comment.Companion.path: DataClassPath<Comment, Comment> get() = path<Comment>()


object Comment__id: SerializableProperty<Comment, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Comment): UUID = receiver._id
    override fun setCopy(receiver: Comment, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("_id")
}
object Comment_user: SerializableProperty<Comment, UUID> {
    override val name: String = "user"
    override fun get(receiver: Comment): UUID = receiver.user
    override fun setCopy(receiver: Comment, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("user")
}
object Comment_userName: SerializableProperty<Comment, String?> {
    override val name: String = "userName"
    override fun get(receiver: Comment): String? = receiver.userName
    override fun setCopy(receiver: Comment, value: String?) = receiver.copy(userName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("userName")
}
object Comment_task: SerializableProperty<Comment, UUID> {
    override val name: String = "task"
    override fun get(receiver: Comment): UUID = receiver.task
    override fun setCopy(receiver: Comment, value: UUID) = receiver.copy(task = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("task")
}
object Comment_taskSummary: SerializableProperty<Comment, String?> {
    override val name: String = "taskSummary"
    override fun get(receiver: Comment): String? = receiver.taskSummary
    override fun setCopy(receiver: Comment, value: String?) = receiver.copy(taskSummary = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("taskSummary")
}
object Comment_project: SerializableProperty<Comment, UUID> {
    override val name: String = "project"
    override fun get(receiver: Comment): UUID = receiver.project
    override fun setCopy(receiver: Comment, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("project")
}
object Comment_projectName: SerializableProperty<Comment, String?> {
    override val name: String = "projectName"
    override fun get(receiver: Comment): String? = receiver.projectName
    override fun setCopy(receiver: Comment, value: String?) = receiver.copy(projectName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("projectName")
}
object Comment_organization: SerializableProperty<Comment, UUID> {
    override val name: String = "organization"
    override fun get(receiver: Comment): UUID = receiver.organization
    override fun setCopy(receiver: Comment, value: UUID) = receiver.copy(organization = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("organization")
}
object Comment_organizationName: SerializableProperty<Comment, String?> {
    override val name: String = "organizationName"
    override fun get(receiver: Comment): String? = receiver.organizationName
    override fun setCopy(receiver: Comment, value: String?) = receiver.copy(organizationName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("organizationName")
}
object Comment_createdAt: SerializableProperty<Comment, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Comment): Instant = receiver.createdAt
    override fun setCopy(receiver: Comment, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("createdAt")
}
object Comment_content: SerializableProperty<Comment, String> {
    override val name: String = "content"
    override fun get(receiver: Comment): String = receiver.content
    override fun setCopy(receiver: Comment, value: String) = receiver.copy(content = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("content")
}
object Comment_autoGenerated: SerializableProperty<Comment, Boolean> {
    override val name: String = "autoGenerated"
    override fun get(receiver: Comment): Boolean = receiver.autoGenerated
    override fun setCopy(receiver: Comment, value: Boolean) = receiver.copy(autoGenerated = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("autoGenerated")
}
object Comment_mentions: SerializableProperty<Comment, Set<Mention>> {
    override val name: String = "mentions"
    override fun get(receiver: Comment): Set<Mention> = receiver.mentions
    override fun setCopy(receiver: Comment, value: Set<Mention>) = receiver.copy(mentions = value)
    override val serializer: KSerializer<Set<Mention>> = SetSerializer(Mention.serializer())
    override val annotations: List<Annotation> = Comment.serializer().tryFindAnnotations("mentions")
}

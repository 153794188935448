package com.lightningtime.views.screens.splitScreens.tasks.comments

import com.lightingtime.Mention
import com.lightingtime.User
import com.lightingtime._id
import com.lightingtime.utils.Regexes
import com.lightningkite.UUID
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Condition
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningtime.sdk.currentSession
import com.lightningtime.theming.emphasized
import kotlinx.serialization.Serializable
import kotlin.coroutines.resume
import kotlin.jvm.JvmInline
import kotlinx.serialization.UseContextualSerialization

fun List<Mention>.getUsers(): Readable<List<User>> = shared {
    currentSession().users.query(Query(
        Condition.Or(
            map { mention ->
                condition { it._id eq mention.userID }
            }
        )
    ))()
}

suspend fun ViewWriter.parseMentions(content: Writable<String>): Set<Mention> {
    var string = content()
    val matchResults = Regexes.mentions.findAll(string)

    val names: List<String> = matchResults.map { match ->
        match.groupValues.drop(1).first {it.isNotBlank()}
    }.toList()

    val users = currentSession().users.query(Query())()

    val matchedUsers: MutableList<User> = mutableListOf()
    val unmatchedNames: MutableList<String> = mutableListOf()
    for (name in names) {
        val foundUser: User? = users.find { it.name.contains(name, ignoreCase = true) }

        if (foundUser == null) unmatchedNames.add(name)
        else matchedUsers.add(foundUser)
    }

    if (unmatchedNames.isNotEmpty()) {
        val replacements = fixIncorrectMentions(unmatchedNames, users)

        for ((name, user) in replacements) {
            val user: User = user() ?: continue

            matchedUsers.add(user)

            string = Regexes.mention(name).replace(string) { match ->
                val matchedName = match.groupValues.drop(1).first { it.isNotBlank() }
                val matchedNameRegex = Regex(matchedName)

                matchedNameRegex.replace(match.value, user.name)  // Replaces only the name matched in the capture group
            }
        }
    }

    content.set(string)

    return matchedUsers.map { Mention(it._id) }.toSet()
}

suspend fun ViewWriter.fixIncorrectMentions(names: List<String>, users: List<User>): Map<String, Property<User?>> {
    return suspendCoroutineCancellable { continuation ->
        val replacements: MutableMap<String, Property<User?>> = names.associateWithTo(mutableMapOf()) { Property(null) }

        val dialog = object : Screen {
            override val title: Readable<String> = Constant("Fix Mentions:")

            override fun ViewWriter.render() {
                dismissBackground {
                    centered - card - col {
                        emphasized - h2("These mentions don't match any user")

                        for (name in names) {

                            label {
                                content = "@$name"

                                card - select {
                                    bind(
                                        edits = replacements[name]!!,
                                        data = shared { listOf(null) + users },
                                        render = {
                                            it?.name ?: "Ignore"
                                        }
                                    )
                                }
                            }
                        }

                        atBottomEnd - important - button {
                            centered - text("Submit")
                            onClick {
                                navigator.dismiss()
                            }
                        }
                    }
                }
            }
        }

        navigator.dialog.navigate(dialog)

        val c = CalculationContext.Standard()
        c.reactiveScope {
            if(navigator.dialog.currentScreen.await() != dialog) {
                continuation.resume(replacements)
                c.cancel()
            }
        }

        return@suspendCoroutineCancellable {
            c.cancel()
            navigator.dialog.dismiss()
        }
    }
}


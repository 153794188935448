package com.lightningtime.draftModel

import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.launch
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.DataClassPath
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.ModificationBuilder

class PropertyOverdefinedError(error: String) : ClassCastException(error)

@Suppress("UNCHECKED_CAST")
class DraftModel<M>(
    val model: Writable<M>
) : Draft<M> {
    private val debug: Console? = null

    private val properties = mutableMapOf<DataClassPath<M, *>, DraftProperty<M, *>>()
    public val editedProperties: Set<DraftProperty<M, *>> get() = properties.values.toSet()

    override fun cancel() = properties.values.forEach { it.cancel() }
    override suspend fun commit() = properties.values.forEach { it.commit() }

    override val changesMade: Readable<Boolean> = shared { properties.values.any { it.changesMade() } }

    suspend fun serverModification(): Modification<M> = ModificationBuilder<M>().apply {
        suspend fun <T> ModificationBuilder<M>.assignProperty(property: DraftProperty<M, T>) = modifications.add(
            property.propertyPath.mapModification(
                Modification.Assign(property.current.awaitOnce())
            )
        )
        properties.values.forEach { assignProperty(it) }
    }.build()

    override val original: Readable<M> = model

    override val current: Readable<M> by lazy {
        shared {
            suspend fun <T> M.setProperty(property: DraftProperty<M, T>): M =
                property.propertyPath.set(this, property.current())

            return@shared properties.values.fold(model()) { value, prop -> value.setProperty(prop) }
        }
    }

    fun <T> prop(path: DataClassPath<M, T>): SimpleProperty<M, T> {
        val col = properties.getOrPut(path) {
            debug?.log("Putting Simple Property: $path")
            SimpleProperty(model, path).also { fulfillAwaiting(path, it) }
        }

        return when (col) {
            is CollectionProperty<*, *, *> ->
                throw PropertyOverdefinedError("Cannot retrieve \"$path\" as simple property as it was previously created as a collection property")

            is SimpleProperty<*, *> -> col as? SimpleProperty<M, T>
                ?: throw ClassCastException("Casting error in DraftModel.prop on property \'$path\"")
        }
    }

    fun <T, COLLECTION : Collection<T>> collectionProp(
        path: DataClassPath<M, COLLECTION>,
        collectionTransform: (List<T>) -> COLLECTION,
    ): CollectionProperty<M, T, COLLECTION> {
        val col = properties.getOrPut(path) {
            CollectionProperty(model, path, collectionTransform).also { fulfillAwaiting(path, it) }
        }

        return when (col) {
            is CollectionProperty<*, *, *> -> col as? CollectionProperty<M, T, COLLECTION>
                ?: throw ClassCastException("Casting error in DraftModel.collectionProp on property '$path\"")

            is SimpleProperty<*, *> ->
                throw PropertyOverdefinedError("Cannot retrieve \"$path\" as collection property as it was previously created as a simple property")
        }
    }

    private val awaitingProps = HashMap<DataClassPath<M, *>, LateInitProperty<*>>()

    private fun <T> fulfillAwaiting(path: DataClassPath<M, T>, prop: DraftProperty<M, T>) {
        awaitingProps[path]?.let { lateInitProperty ->
            val late = lateInitProperty as LateInitProperty<T>

            with(CalculationContextStack.current()) {
                prop.addListener {
                    launch {
                        late.value = prop.current.await()
                    }
                }
            }

            awaitingProps.remove(path)
        }
    }

    fun <T> read(
        path: DataClassPath<M, T>
    ): Readable<T> {
        val prop = properties[path]
        return if (prop != null) (prop as DraftProperty<M, T>).current
        else awaitingProps.getOrPut(path) {
            LateInitProperty<T>()
        } as LateInitProperty<T>
    }

    suspend fun <T> await(path: DataClassPath<M, T>): T = read(path).await()
}

package com.lightningtime.timeManagement

import com.lightningkite.now
import com.lightningtime.allCapsToTitle
import kotlinx.datetime.*
import kotlin.time.Duration

fun Duration.format(
    days: Boolean = true,
    hours: Boolean = true,
    minutes: Boolean = true,
    seconds: Boolean = true,
    padFirst: Boolean = true,
): String {
    var firstEncountered = false

    return listOfNotNull(
        inWholeDays.takeIf { days }?.takeIf { it > 0 },
        inWholeHours.takeIf { hours }?.let { if (days) it % 24 else it },
        inWholeMinutes.takeIf { minutes }?.mod(60),
        inWholeSeconds.takeIf { seconds }?.mod(60),
    ).joinToString(":") { it.toString().let { str ->
        if (padFirst || firstEncountered) str.padStart(2, '0')
        else {
            firstEncountered = true
            return@let str
        }
    } }
}

fun Instant.formatRelative(): String = buildString {
    val dateTime = this@formatRelative.toLocalDateTime(TimeZone.currentSystemDefault())
    val now = now().toLocalDateTime(TimeZone.currentSystemDefault())

    if (dateTime.date != now.date) {
        val month = dateTime.month.toString().allCapsToTitle()
        val day = dateTime.dayOfMonth.toString()

        append("$month $day, ")

        if (dateTime.year != now.year) append("${dateTime.year}, ")
    }
    append(
        "${
            (dateTime.hour + 11) % 12 + 1
        }:${
            dateTime.minute.toString().padStart(2, '0')
        } ${
            if (dateTime.hour / 12 < 1) "AM" else "PM"
        }"
    )
}

fun Instant.format(): String = buildString {
    val dateTime = this@format.toLocalDateTime(TimeZone.currentSystemDefault())

    append("${dateTime.month.toString().allCapsToTitle()} ${dateTime.dayOfMonth}, ")
    append("${dateTime.year}, ")
    append(
        "${
            (dateTime.hour + 11) % 12 + 1
        }:${
            dateTime.minute.toString().padStart(2, '0')
        } ${
            if (dateTime.hour / 12 < 1) "AM" else "PM"
        }"
    )
}

fun LocalDate.formatRelative(): String {
    val now = now()
    val today = now.toLocalDateTime(TimeZone.currentSystemDefault()).date

    if (this == today) return "Today"

    return "${month.toString().allCapsToTitle()} $dayOfMonth" + if (year != today.year) ", $year" else ""
}

operator fun ClosedRange<LocalDate>.iterator() = object: Iterator<LocalDate> {
    private var current: LocalDate = start
    override fun hasNext(): Boolean = current <= endInclusive
    override fun next(): LocalDate {
        current += DatePeriod(days = 1)
        return current
    }
}
fun ClosedRange<LocalDate>.toSet(): Set<LocalDate> = buildSet {
    for (date in this@toSet) {
        add(date)
    }
}


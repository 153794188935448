package com.lightningtime.timeManagement

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.delay
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.reactive.awaitOnce
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningtime.draftModel.LazyProperty
import com.lightningtime.sdk.currentSession
import com.lightningtime.validation.interceptWrite
import kotlinx.datetime.Clock
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.time.Duration.Companion.ZERO
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.minutes

object TimeInfo {
    val mutableTimerMap = HashMap<UUID, MutableTimer>()

    val unsubmittedTimers = shared {
        val session = currentSession()
        session.timers.query(Query(
            condition { it.user eq session.userId },
            orderBy = sort {
                it._id.ascending()
            }
        ))().also { timers ->
            val ids = timers.map { it._id }
            for (mutable in mutableTimerMap.values) {
                if (mutable._id !in ids) mutable.remove()
            }
        }
    }

    val selectedTimer = LazyProperty<MutableTimer?> {
        unsubmittedTimers.awaitOnce().firstOrNull { it.lastStarted != null }?.mutable()
    }.interceptWrite { new ->
        val current = awaitOnce()

        if (new?._id != current?._id) {
            current?.pause()
            new?.resume()

            value = new
        }
    }

    val timerContext = launchGlobal {
        var selected = selectedTimer.awaitOnce()
        var lastStarted = selected?.lastStarted?.awaitOnce()

        suspend fun update() {
            selected = selectedTimer.awaitOnce()
            lastStarted = selected?.lastStarted?.awaitOnce()
        }

        while (true) {
            delay(10L)
            update()

            while (lastStarted != null) {
                delay(450L)

                update()
                val sel = selected
                val ls = lastStarted

                if (sel == null || ls == null) break
                else {
                    sel.unsaved.value = now() - ls
                }
            }
        }
    }

    private val now = Clock.System.now()
    val today = now.toLocalDateTime(TimeZone.currentSystemDefault()).date
    val daysPastMonday = (today.dayOfWeek.ordinal - DayOfWeek.MONDAY.ordinal) + 1
    val previousMonday = (now - daysPastMonday.days).toLocalDateTime(TimeZone.currentSystemDefault()).date

    val oneWeekAgo = (now - 7.days).toLocalDateTime(TimeZone.currentSystemDefault()).date

    val timeEntriesFromPastWeek = shared {
        val session = currentSession()
        session.timeEntries.query(Query(
            Condition.And(
                listOf(
                    condition { it.user eq session.userId },
                    condition { it.date inside (oneWeekAgo..today).toSet() }
                )
            )
        ))()
    }

    val timeEntriesFromWorkWeek = shared {
        timeEntriesFromPastWeek().filter { it.date in previousMonday..today }
    }

    val timeEntriesFromToday = shared {
        timeEntriesFromPastWeek().filter { it.date == today }
    }
}
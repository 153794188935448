package com.lightningtime.views.tools.tabs

import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.ScreenTransition
import com.lightningkite.kiteui.models.div
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.spacing
import com.lightningtime.validation.interceptWrite
import com.lightningtime.views.tooltip

open class TabManager<TAB: TabScreen>(
    val tabScreens: Collection<TAB>
) {
    val selectedTab = Property(tabScreens.first())

    inner class TabButton(val screen: TAB) {
        var showIcon: Boolean = true
            set(value) {
                _showIcon.value = value
                field = value
            }
        var showTitle: Boolean = true
            set(value) {
                _showTitle.value = value
                field = value
            }
        var spacing: Dimension? = null

        internal var _onSelect: suspend () -> Unit = {}

        private val _showIcon = Property(showIcon)
        private val _showTitle = Property(showTitle)

        internal fun ViewWriter.build() {
            toggleButton {
                this@TabButton.spacing?.let { this.spacing = it }
                centered - row {
                    this@TabButton.spacing?.let { this.spacing = it }

                    centered - icon {
                        ::exists { _showIcon() }
                        source = screen.icon
                    }

                    centered - text {
                        ::exists { _showTitle() }
                        ::content { screen.title() }
                    }
                }

                checked bind selectedTab.equalTo(screen).interceptWrite {
                    if (it) _onSelect()

                    this@interceptWrite set it
                }
            }
        }
    }

    @ViewDsl
    fun ViewWriter.tab(tabScreen: TAB, setup: TabButton.(TAB) -> Unit = {}) {
        TabButton(tabScreen).apply { setup(screen); build() }
    }

    @ViewDsl
    fun ViewWriter.tabBar(setup: TabButton.(TAB) -> Unit = {}) {
        row {
            for (screen in tabScreens) {
                tab(screen, setup)
            }
        }
    }

    @ViewDsl
    fun ViewWriter.verticalTabBar(setup: TabButton.(TAB) -> Unit = {}) {
        col {
            for (screen in tabScreens) {
                tab(screen, setup)
            }
        }
    }

    @ViewDsl
    fun ViewWriter.mainTabBody(transition: (TAB) -> ScreenTransition = { ScreenTransition.Fade }) {
        swapView {
            swapping(
                current = { selectedTab.await() },
                transition = transition,
                views = {
                    with (it) { render() }
                }
            )
        }
    }
}

fun <T: TabScreen> TabManager<T>.TabButton.onSelect(action: suspend () -> Unit) { this._onSelect = action }

fun <TAB: TabScreen> tabs(tabs: Collection<TAB>, setup: TabManager<TAB>.() -> Unit) = TabManager(tabs).setup()

fun <TAB: TabScreen> tabs(vararg tab: TAB, setup: TabManager<TAB>.() -> Unit) = TabManager(tab.toList()).setup()

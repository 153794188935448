package com.lightningtime.views.screens.splitScreens.tasks

import com.lightingtime.Organization
import com.lightingtime.Project
import com.lightingtime.TaskState
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.uuid
import com.lightningtime.theming.AppColors
import com.lightningtime.theming.appAccentColor
import com.lightningtime.theming.emphasized
import com.lightningtime.theming.emphasizedTheme
import kotlin.math.min

val activeStates = setOf(TaskState.Active, TaskState.Testing, TaskState.PullRequest)
val completedStates = setOf(TaskState.Hold, TaskState.Delivered, TaskState.Cancelled, TaskState.Approved)

object Recents {
    private val orgID = uuid()

}

object MockProjects {
    private val orgID = uuid()
    val organization = Organization(
        _id = orgID,
        name = "Mock Projects"
    )
    val recents = Project(
        _id = uuid(),
        organization = orgID,
        name = "Recent Tasks",
    )
    val favorites = Project(
        _id = uuid(),
        organization = orgID,
        name = "Favorite Projects"
    )

    val projects get() = listOf(recents, favorites)
    val entries get() = projects.associateBy { it._id }
}



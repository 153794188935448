package com.lightningtime.views.screens

import com.lightingtime.*
import com.lightingtime.utils.Regexes
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.spacing
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.modification
import com.lightningtime.draftModel.DraftModel
import com.lightningtime.draftModel.LazyProperty
import com.lightningtime.draftModel.associateBy
import com.lightningtime.draftModel.collectionProp
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.sessionToken
import com.lightningtime.theming.*
import com.lightningtime.validation.validate
import com.lightningtime.validation.validating
import com.lightningtime.validation.interceptWrite
import com.lightningtime.views.screens.auth.LoginScreen

@Routable("/settings")
class SettingsScreen: Screen {

    private val self = LazyProperty { currentSession().self() }
    private val draft: DraftModel<User> = DraftModel(self)


    private fun String.hexFormat(): String = replace("#", "").uppercase()
    private val tempAccentString = LazyProperty { appAccentColor().toAlphalessWeb().hexFormat() }.interceptWrite {
        value = it.hexFormat()
    }
    private val tempAccent = shared {
        Color.fromHexString(tempAccentString())
    }

    private val tempTheme = LazyProperty { appTheme() }
    private val preview = LazyProperty { tempTheme().copy(accent = tempAccent) }

    override fun ViewWriter.render() {
        scrolls - col {
            card - col {
                spacing = 1.5.rem

                row {
                    expanding - centered - h2("Account")

                    important - button {
                        spacing = 0.75.rem

                        centered - text("Log Out")

                        onClick {
                            confirmDanger(
                                title = "Log Out",
                                body = "Are you sure you want to log out?",
                                actionName = "I'm Sure.",
                                action = {
                                    try {
                                        currentSession().nonCached.userSession.terminateSession()
                                    } catch (_: Exception) {
                                    }
                                    sessionToken.set(null)
                                    navigator.reset(LoginScreen())
                                }
                            )
                        }
                    }
                }

                separator()

                validating {
                    row {
                        weight(4f) - label {
                            content = "Name"

                            val name = draft.prop(User.path.name).validate { it.isNotBlank() }

                            fieldTheme - validate(name) - textField {
                                content bind name
                            }
                        }

                        weight(1f) - label {
                            content = "Role"

                            embedded - text { ::content { self().role.toString() } }
                        }
                    }

                    label {
                        content = "Email"

                        val email = draft.prop(User.path.email).validate {
                            it.matches(Regexes.email)
                        }

                        fieldTheme - validate(email) - textField {
                            hint = "example@email.com"
                            keyboardHints = KeyboardHints.email

                            content bind email
                        }
                    }

                    label {
                        content = "Favorite Projects"

                        val projects = shared { currentSession().projects.query(Query())() }
                        val map = projects.associateBy { it._id }

                        val favoriteProjects = draft.collectionProp(User.path.projectFavorites)

                        fieldTheme - col {
                            col {
                                ::exists { favoriteProjects().isNotEmpty() }

                                forEachUpdating(favoriteProjects) {
                                    removeOutline - card - sizeConstraints(height = 2.5.rem) - row {
                                        spacing = 0.5.rem

                                        expanding - centered - h6 {
                                            ::content {
                                                map()[it()()]?.name ?: "Not Found"
                                            }
                                        }

                                        centered - button {
                                            spacing = 0.rem
                                            centered - icon(Icon.close, "Remove favorite")
                                            onClick {
                                                favoriteProjects.remove(it)
                                            }
                                        }
                                    }
                                }
                            }

                            removeOutline - menuButton {
                                spacing = 0.rem
                                requireClick = true
                                preferredDirection = PopoverPreferredDirection.belowCenter

                                centered - icon(Icon.add, "Add new favorite")

                                opensMenu {
                                    sizeConstraints(maxHeight = 20.rem) - scrolls - col {
                                        spacing = 0.25.rem

                                        forEachUpdating(projects) { project ->
                                            button {
                                                spacing = 0.5.rem

                                                text { ::content { project().name } }

                                                onClick {
                                                    val id = project()._id
                                                    if (favoriteProjects().none { it() == id }) favoriteProjects.add(Property(id))
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    atEnd - onlyWhen { draft.changesMade() } - row {
                        card - button {
                            spacing = 0.5.rem
                            centered - icon(Icon.close, "Cancel changes")
                            onClick { draft.cancel() }
                        }
                        card - button {
                            spacing = 0.5.rem
                            centered - row {
                                spacing = 0.5.rem
                                centered - icon { source = Icon.save }
                                centered - text("Save Changes")
                            }
                            onClick {
                                draft.commit()

                                val session = currentSession()
                                session.users[session.userId].modify(draft.serverModification()) ?: throw NullPointerException("Self not modified correctly")
                            }
                        }
                    }
                }
            }

            setTheme {
                preview()().card()
            } - col {
                spacing = 1.5.rem

                h2("App Settings")

                separator()

                row {
                    label {
                        content = "Accent Color"

                        embedded - row {
                            sizeConstraints(aspectRatio = Pair(1, 1)) - themeFromLast {
                                it.copy(
                                    background = tempAccent()
                                )
                            } - stack()

                            row {
                                spacing = 0.5.rem

                                centered - text("#")

                                sizeConstraints(height = 2.rem) - expanding - fieldTheme - textField {
                                    spacing = 0.5.rem
                                    hint = "Hex Code"
                                    content bind tempAccentString
                                }
                            }
                        }
                    }

                    label {
                        content = "Theme"

                        sizeConstraints(minWidth = 15.rem) - fieldTheme - menuButton {
                            requireClick = true

                            centered - row {
                                icon {
                                    ::source { tempTheme().icon }
                                }

                                centered - h6 { ::content { tempTheme().name } }
                            }

                            opensMenu {
                                sizeConstraints(maxHeight = 20.rem) - scrolls - col {
                                    spacing = 0.25.rem

                                    for (theme in AppThemes.entries) {
                                        button {
                                            spacing = 0.5.rem

                                            with(theme) { render() }

                                            onClick {
                                                tempTheme.value = theme
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                atEnd - onlyWhen { (tempAccent() != appAccentColor()) or (tempTheme().name != appTheme().name) } - row {
                    card - button {
                        spacing = 0.5.rem
                        centered - icon(Icon.close, "Cancel changes")
                        onClick {
                            tempAccentString set appAccentColor().toAlphalessWeb()
                            tempTheme.value = appTheme()
                        }
                    }

                    important - button {
                        spacing = 0.5.rem
                        centered - row {
                            spacing = 0.5.rem
                            centered - icon { source = Icon.save }
                            centered - text("Save Changes")
                        }
                        onClick {
                            val c = currentSession()
                            c.users[c.userId].modify(
                                modification {
                                    it.theme assign tempTheme().name
                                    it.themeColor assign tempAccent.await().toAlphalessWeb()
                                }
                            )
                        }
                    }
                }
            }
        }
    }
}
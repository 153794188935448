package com.lightningtime.views.components

import com.lightingtime.TimeEntry
import com.lightingtime.summaryTime
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.NavCustom
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.modification
import com.lightningtime.draftModel.LazyProperty
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.theming.appTheme
import com.lightningtime.theming.lightOutline
import com.lightningtime.theming.timeEntry
import com.lightningtime.validation.interceptWrite
import com.lightningtime.timeManagement.TimeInfo
import com.lightningtime.timeManagement.displayDuration
import kotlin.time.Duration
import kotlin.time.Duration.Companion.ZERO
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

val loggedTimeNav = NavCustom(
    title = { "Logged Time" },
    square = {
        val unsubmittedTimersDuration = shared {
            TimeInfo.unsubmittedTimers()
                .sumOf { it.accumulatedSeconds }
                .seconds
        }

        suspend fun List<TimeEntry>.toDuration(): Duration =
            sumOf { it.durationMilliseconds }.milliseconds +
                    (TimeInfo.selectedTimer()?.unsaved() ?: ZERO) + unsubmittedTimersDuration()

        class LabeledTimer(
            val name: String,
            duration: suspend CalculationContext.() -> Duration?
        ) : Readable<Duration?> by SharedReadable(action = duration)

        val labeledTimers = listOf(
            LabeledTimer("This Week") { TimeInfo.timeEntriesFromWorkWeek().toDuration() },
            LabeledTimer("Today") { TimeInfo.timeEntriesFromToday().toDuration() },
        )

        val selectedLabeledTimer = LazyProperty<LabeledTimer> {
            currentSession().self().summaryTime.let { selected ->
                labeledTimers.find { it.name == selected } ?: labeledTimers.first()
            }
        }.interceptWrite { timer ->
            val session = handleCurrentSession()
            session.users[session.userId].modify(
                modification { it.summaryTime assign timer.name }
            )

            value = timer
        }

        lightOutline - menuButton {
            preferredDirection = PopoverPreferredDirection.belowLeft

            centered - row {
                icon { source = Icon.timeEntry }

                displayDuration { selectedLabeledTimer()() ?: ZERO }
            }

            opensMenu {
                row {
                    for (timer in labeledTimers) {
                        setTheme { appTheme()() } - toggleButton {
                            col {
                                spacing = 0.5.rem

                                text(timer.name)

                                displayDuration { timer() ?: ZERO }
                            }
                            checked bind selectedLabeledTimer.equalTo(timer)
                        }
                    }
                }
            }
        }
    }
)
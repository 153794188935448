@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareTimerFields() {
    val props: Array<SerializableProperty<Timer, *>> = arrayOf(Timer__id, Timer_user, Timer_organization, Timer_lastStarted, Timer_accumulatedSeconds, Timer_task, Timer_project, Timer_taskSummary, Timer_projectName, Timer_summary, Timer_date)
    Timer.serializer().properties { props }
}
val <K> DataClassPath<K, Timer>._id: DataClassPath<K, UUID> get() = this[Timer__id]
val <K> DataClassPath<K, Timer>.user: DataClassPath<K, UUID> get() = this[Timer_user]
val <K> DataClassPath<K, Timer>.organization: DataClassPath<K, UUID> get() = this[Timer_organization]
val <K> DataClassPath<K, Timer>.lastStarted: DataClassPath<K, Instant?> get() = this[Timer_lastStarted]
val <K> DataClassPath<K, Timer>.accumulatedSeconds: DataClassPath<K, Long> get() = this[Timer_accumulatedSeconds]
val <K> DataClassPath<K, Timer>.task: DataClassPath<K, UUID?> get() = this[Timer_task]
val <K> DataClassPath<K, Timer>.project: DataClassPath<K, UUID?> get() = this[Timer_project]
val <K> DataClassPath<K, Timer>.taskSummary: DataClassPath<K, String?> get() = this[Timer_taskSummary]
val <K> DataClassPath<K, Timer>.projectName: DataClassPath<K, String?> get() = this[Timer_projectName]
val <K> DataClassPath<K, Timer>.summary: DataClassPath<K, String> get() = this[Timer_summary]
val <K> DataClassPath<K, Timer>.date: DataClassPath<K, LocalDate> get() = this[Timer_date]
inline val Timer.Companion.path: DataClassPath<Timer, Timer> get() = path<Timer>()


object Timer__id: SerializableProperty<Timer, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Timer): UUID = receiver._id
    override fun setCopy(receiver: Timer, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("_id")
}
object Timer_user: SerializableProperty<Timer, UUID> {
    override val name: String = "user"
    override fun get(receiver: Timer): UUID = receiver.user
    override fun setCopy(receiver: Timer, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("user")
}
object Timer_organization: SerializableProperty<Timer, UUID> {
    override val name: String = "organization"
    override fun get(receiver: Timer): UUID = receiver.organization
    override fun setCopy(receiver: Timer, value: UUID) = receiver.copy(organization = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("organization")
}
object Timer_lastStarted: SerializableProperty<Timer, Instant?> {
    override val name: String = "lastStarted"
    override fun get(receiver: Timer): Instant? = receiver.lastStarted
    override fun setCopy(receiver: Timer, value: Instant?) = receiver.copy(lastStarted = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("lastStarted")
}
object Timer_accumulatedSeconds: SerializableProperty<Timer, Long> {
    override val name: String = "accumulatedSeconds"
    override fun get(receiver: Timer): Long = receiver.accumulatedSeconds
    override fun setCopy(receiver: Timer, value: Long) = receiver.copy(accumulatedSeconds = value)
    override val serializer: KSerializer<Long> = Long.serializer()
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("accumulatedSeconds")
}
object Timer_task: SerializableProperty<Timer, UUID?> {
    override val name: String = "task"
    override fun get(receiver: Timer): UUID? = receiver.task
    override fun setCopy(receiver: Timer, value: UUID?) = receiver.copy(task = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("task")
}
object Timer_project: SerializableProperty<Timer, UUID?> {
    override val name: String = "project"
    override fun get(receiver: Timer): UUID? = receiver.project
    override fun setCopy(receiver: Timer, value: UUID?) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("project")
}
object Timer_taskSummary: SerializableProperty<Timer, String?> {
    override val name: String = "taskSummary"
    override fun get(receiver: Timer): String? = receiver.taskSummary
    override fun setCopy(receiver: Timer, value: String?) = receiver.copy(taskSummary = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("taskSummary")
}
object Timer_projectName: SerializableProperty<Timer, String?> {
    override val name: String = "projectName"
    override fun get(receiver: Timer): String? = receiver.projectName
    override fun setCopy(receiver: Timer, value: String?) = receiver.copy(projectName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("projectName")
}
object Timer_summary: SerializableProperty<Timer, String> {
    override val name: String = "summary"
    override fun get(receiver: Timer): String = receiver.summary
    override fun setCopy(receiver: Timer, value: String) = receiver.copy(summary = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("summary")
}
object Timer_date: SerializableProperty<Timer, LocalDate> {
    override val name: String = "date"
    override fun get(receiver: Timer): LocalDate = receiver.date
    override fun setCopy(receiver: Timer, value: LocalDate) = receiver.copy(date = value)
    override val serializer: KSerializer<LocalDate> = ContextualSerializer(LocalDate::class)
    override val annotations: List<Annotation> = Timer.serializer().tryFindAnnotations("date")
}

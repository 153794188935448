package com.lightningtime.views.screens.timeEntry

import com.lightingtime.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.sort
import com.lightningtime.sdk.currentSession
import com.lightningtime.theming.*
import com.lightningtime.timeManagement.format
import com.lightningtime.timeManagement.formatRelative
import com.lightningtime.toggle
import com.lightningtime.validation.interceptWrite
import com.lightningtime.views.components.queries.SearchField
import com.lightningtime.views.components.queries.searchBar
import com.lightningtime.views.detail
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedProjectView
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedTaskView
import com.lightningtime.views.tools.tabs.TabScreen
import com.lightningtime.views.tooltip
import kotlin.time.Duration.Companion.milliseconds

@Routable("time-entry/history")
class TimeEntryHistoryScreen: TabScreen {
    override val title: Readable<String> = Constant("History")
    override val icon: Icon = Icon.timeEntry

    private val query = Property("")
    private val searchField = Property(SearchField.TimeEntries.TaskSummary)

    private val timeEntries = shared {
        val session = currentSession()
        session.timeEntries.query(
            Query(
                condition { it.user eq session.userId },
                orderBy = sort {
                    it.date.descending()
                    it.taskSummary.ascending()
                    it.projectName.ascending()
                }
            )
        )()
    }

    private val expandList = ArrayList<Property<Boolean>>()
    private val allExpanded = Property(false).interceptWrite { expanded ->
        expandList.forEach { it.value = expanded }
        value = expanded
    }

    override fun ViewWriter.render() {
        removeOutline - col {
            sizeConstraints(height = 3.rem) - row {
                centered - expanding - h2("History")

                sizeConstraints(aspectRatio = Pair(1, 1)) - lightOutline - button {
                    spacing = 0.5.rem
                    centered - sizeConstraints(height = 1.5.rem) - icon {
                        ::source { if (allExpanded()) Icon.collapseAll else Icon.expandAll }
                    }

                    onClick {
                        allExpanded.toggle()
                        allExpanded().let { expanded -> expandList.forEach { it.value = expanded } }
                    }
                } in tooltip(PopoverPreferredDirection.belowRight) {
                    if (allExpanded()) "Collapse All" else "Expand All"
                }

                centered - weight(0.8f) - searchBar(query, searchField, SearchField.TimeEntries.entries)
            }

            separator()

            expanding - recyclerView {
                children(timeEntries) { entry ->
                    fun TextView.info(info: TimeEntry.() -> String) {
                        ::content { entry().info() }
                    }

                    val showingSummary = Property(false).also { expandList.add(it) }

                    card - col {
                        col {
                            spacing = 0.5.rem
                            row {
                                link {
                                    spacing = 0.rem

                                    h5 { info { taskSummary ?: projectName ?: "Task/Project Name Not Found" } }

                                    ::to {
                                        val timeEntry = entry()

                                        timeEntry.task?.let {
                                            { DetailedTaskView(it) }
                                        } ?: timeEntry.project.let {
                                            { DetailedProjectView(it) }
                                        }
                                    }
                                }
                            }

                            separator()

                            row {
                                expanding - centered - col {
                                    spacing = 0.5.rem

                                    detail("User") { entry().userName ?: "Not Found" }
                                    detail("Date") { entry().date.formatRelative() }
                                }
                                centered - embedded - emphasized - h6 { info { durationMilliseconds.milliseconds.format() } }
                            }

                            embedded - onlyWhen { showingSummary() } - col {
                                emphasized - text("Summary")
                                text { info { summary } }
                            }
                        }

                        button {
                            spacing = 0.rem
                            row {
                                spacing = 0.5.rem

                                centered - expanding - col { separator() }

                                sizeConstraints(height = 1.rem) - centered - icon {
                                    ::source {
                                        if (showingSummary()) Icon.chevronUp
                                        else Icon.chevronDown
                                    }
                                }

                                centered - expanding - col { separator() }
                            }

                            onClick { showingSummary.toggle() }
                        }
                    }
                }
            }
        }
    }
}
@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareAttachmentFields() {
    val props: Array<SerializableProperty<Attachment, *>> = arrayOf(Attachment_name, Attachment_file)
    Attachment.serializer().properties { props }
}
val <K> DataClassPath<K, Attachment>.name: DataClassPath<K, String> get() = this[Attachment_name]
val <K> DataClassPath<K, Attachment>.file: DataClassPath<K, ServerFile> get() = this[Attachment_file]
inline val Attachment.Companion.path: DataClassPath<Attachment, Attachment> get() = path<Attachment>()


object Attachment_name: SerializableProperty<Attachment, String> {
    override val name: String = "name"
    override fun get(receiver: Attachment): String = receiver.name
    override fun setCopy(receiver: Attachment, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Attachment.serializer().tryFindAnnotations("name")
}
object Attachment_file: SerializableProperty<Attachment, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: Attachment): ServerFile = receiver.file
    override fun setCopy(receiver: Attachment, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = Attachment.serializer().tryFindAnnotations("file")
}

package com.lightningtime.views.screens.splitScreens.detailScreens.forms

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.rerunOn
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.spacing
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.uuid
import com.lightningtime.draftModel.*
import com.lightningtime.sdk.currentSession
import com.lightningtime.sdk.handleCurrentSession
import com.lightningtime.theming.edit
import com.lightningtime.theming.lightOutline
import com.lightningtime.theming.removeOutline
import com.lightningtime.views.tooltip
import com.lightningtime.validation.validate
import com.lightningtime.views.screens.splitScreens.detailScreens.DetailedProjectView

@Routable("projects/form/{projectID}")
class ProjectForm(var projectID: UUID = uuid()): FormScreen<Project>() {
    override val id: UUID get() = projectID

    val project = LazyProperty {
        Project(
            _id = projectID,
            organization = currentSession().self().organization,
            name = "",
        )
    }

    override fun setEditing(original: Project): ProjectForm {
        editing = true
        projectID = original._id
        project.value = original

        return this
    }

    val draft = DraftModel(project)

    private suspend fun submit() {
        val session = handleCurrentSession()

        if (editing) {
            session.projects[projectID].modify(draft.serverModification())
                ?: throw NullPointerException("Project not modified correctly")

            draft.commit()
        }
        else {
            draft.commit()
            session.projects.insert(project())() ?: throw NullPointerException("Project not inserted correctly")
        }

        split.navigate(DetailedProjectView(projectID).apply { project.value = this.project() })
    }

    override fun ViewWriter.render() {
        scrolls - col {
            sizeConstraints(height = 3.rem) - row {
                if (editing) centered - icon(Icon.edit, "Editing Project")

                centered - expanding - h2 { ::content { draft.await(Project.path.name).ifBlank { "New Project" } } }

                centered - button {
                    spacing = 0.1.rem
                    icon(Icon.close, "Exit Form")
                    onClick {
                        if (editing) split.replace(DetailedProjectView(projectID)) else split.goBack()
                    }
                }
            }

            separator()

            padded - col {
                spacing = 1.5.rem

                row {
                    expanding - label {
                        content = "Name"

                        val name = draft.prop(Project.path.name).validate { it.isNotBlank() }

                        expanding - fieldTheme - validate(name) - textField {
                            content bind name
                        }
                    }

                    label {
                        content = "Rate"

                        fieldTheme - row {
                            spacing = 0.5.rem

                            centered - text("$")

                            numberField {
                                align = Align.Start

                                hint = "/ Hour"

                                content bind draft.prop(Project.path.rate).withTransform(
                                    inputTransform = { it?.toFloat() },
                                    outputTransform = { it?.toDouble() }
                                )
                            }
                        }
                    }
                }

                label {
                    content = "Notes"

                    sizeConstraints(minHeight = 10.rem) - fieldTheme - textArea {
                        content bind draft.prop(Project.path.notes)
                    }
                }

                label {
                    content = "Tags"

                    val tags = draft.collectionProp(Project.path.projectTags)

                    lightOutline - col {
                        col {
                            ::exists { tags().isNotEmpty() }

                            forEachUpdating(
                                dynamicValidation {
                                    tags.map(onRefresh = ::refreshDynamicConditions) { tag ->
                                        val modified = tag
                                            .withTransform( { it.trim() }, { it } )
                                            .validate { str -> str.isNotBlank() && str.all { it.isLowerCase() || it.isWhitespace() } }
                                            .dynamic()

                                        tag to modified
                                    }
                                }
                            ) { (original, validated) ->
                                sizeConstraints(height = 3.rem) - fieldTheme - validate(validated) - row {
                                    spacing = 0.5.rem

                                    removeOutline - unpadded - expanding - textField {
                                        hint = "Only Lowercase"
                                        content bind validated.flatten()
                                    }

                                    removeOutline - unpadded - button {
                                        spacing = 0.rem
                                        centered - icon(Icon.delete, "Remove Tag")
                                        onClick { tags.remove(original) }
                                    }
                                }
                            }
                        }

                        removeOutline - sizeConstraints(height = 2.rem) - button {
                            spacing = 0.rem
                            centered - icon(Icon.add, "Add Tag")
                            onClick { tags.add(Property("")) }
                        } in tooltip("Add Tag", PopoverPreferredDirection.belowCenter)
                    }
                }

                atEnd - important - button {
                    ::enabled { allValid() && draft.changesMade() }

                    reactiveScope {
                        rerunOn(draft.changesMade)
                        println("changesMade: ${draft.changesMade.state}")
                    }

                    centered - h6 {
                        content = if (editing) "Save Changes" else "Submit"
                    }

                    onClick(::submit)
                }
            }
        }
    }
}
package com.lightningtime.sdk

import com.lightingtime.*
import com.lightningkite.UUID
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.serializerOrContextual
import com.lightningkite.lightningserver.db.ModelCache
import com.lightningkite.lightningserver.db.WritableModel
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.kiteui.Async
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.asyncGlobal
import com.lightningkite.kiteui.navigation.PlatformNavigator
import com.lightningkite.kiteui.navigation.ScreenStack
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningtime.views.screens.auth.LoginScreen
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.serialization.EncodeDefault
import kotlinx.serialization.KSerializer
import kotlin.time.Duration.Companion.minutes

class UserSession(
    private val api: LiveApi,
    private val userToken: String,
    private val userAccessToken: suspend () -> String,
    val userId: UUID,
) {

    val nonCached = object : AbstractUserSession(api, userToken, userAccessToken) {
        override val api: Api = this@UserSession.api
        override val userToken: String = this@UserSession.userToken
        override val userAccessToken: suspend () -> String = this@UserSession.userAccessToken
    }

    val projects = ModelCache(nonCached.project, Project.serializer())
    val tasks = ModelCache(nonCached.task, Task.serializer())
    val users = ModelCache(nonCached.user, User.serializer())
    val timers = ModelCache(nonCached.timer, Timer.serializer())
    val timeEntries = ModelCache(nonCached.timeEntry, TimeEntry.serializer())
    val comments = ModelCache(nonCached.comment, Comment.serializer())
    val organizations = ModelCache(nonCached.organization, Organization.serializer())

    val self = shared {
        users[userId].await()!!
    }

    suspend fun bulkRequest2(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "${api.httpUrl}/meta/bulk",
        method = HttpMethod.POST,
        token = nonCached.userAccessToken,
        body = input
    )

}

val sessionToken = PersistentProperty<String?>("sessionToken", null)

val userToken = shared {
    val refresh = sessionToken.await() ?: return@shared null
    val api = selectedApi.await().api

    var lastRefresh: Instant = now()
    var token: Async<String> = asyncGlobal {
        api.userAuth.getTokenSimple(refresh)
    }

    Triple(api, refresh, suspend {
        if (lastRefresh <= now().minus(4.minutes)) {
            lastRefresh = now()
            token = asyncGlobal {
                api.userAuth.getTokenSimple(refresh)
            }
        }
        token.await()
    })
}

val currentSession = shared<UserSession?> {
    val (api, userToken, access) = userToken.await() ?: return@shared null
    val self = try {
        api.userAuth.getSelf(access)
    } catch (e: Exception) {
        return@shared null
    }
    UserSession(
        api = api,
        userToken = userToken,
        userAccessToken = access,
        userId = self._id,
    )
}

suspend inline fun handleCurrentSession(): UserSession {
    val result = currentSession.await()
    if (result == null) {
        ScreenStack.main.reset(LoginScreen())
        throw CancelledException()
    }
    return result
}

suspend fun CalculationContext.currentSession(): UserSession {
    return handleCurrentSession()
}

suspend fun ViewWriter.currentSession(): UserSession {
    return handleCurrentSession()
}

suspend fun ViewWriter.clearSession() {
    try {
        currentSession.await()?.nonCached?.userSession?.terminateSession()
    } catch (e: Exception) {
        /*squish*/
    }
    sessionToken set null
}


inline fun <reified T> Readable<WritableModel<T>>.flatten(): WritableModel<T> {
    return object : WritableModel<T>, Readable<T?> by shared({ this@flatten.await().await() }) {
        override suspend fun set(value: T?) {
            this@flatten.await().set(value)
        }

        override val serializer: KSerializer<T> = serializerOrContextual()

        override suspend fun delete() {
            this@flatten.await().delete()
        }

        override suspend fun modify(modification: Modification<T>): T? {
            return this@flatten.await().modify(modification)
        }

        override fun invalidate() {
            if(this@flatten.state.ready)
                this@flatten.state.get().invalidate()
        }
    }
}
package com.lightingtime
fun prepareModels() {
        prepareAttachmentFields()
        prepareOrganizationFields()
        prepareProjectFields()
        prepareTaskFields()
        prepareCommentFields()
        prepareTimeEntryFields()
        prepareTimerFields()
        prepareUserFields()
}

@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareTaskFields() {
    val props: Array<SerializableProperty<Task, *>> = arrayOf(Task__id, Task_project, Task_projectName, Task_organization, Task_organizationName, Task_user, Task_userName, Task_state, Task_summary, Task_description, Task_attachments, Task_estimate, Task_emergency, Task_priority, Task_tags, Task_createdAt, Task_createdBy, Task_creatorName, Task_pullRequestLinks)
    Task.serializer().properties { props }
}
val <K> DataClassPath<K, Task>._id: DataClassPath<K, UUID> get() = this[Task__id]
val <K> DataClassPath<K, Task>.project: DataClassPath<K, UUID> get() = this[Task_project]
val <K> DataClassPath<K, Task>.projectName: DataClassPath<K, String?> get() = this[Task_projectName]
val <K> DataClassPath<K, Task>.organization: DataClassPath<K, UUID> get() = this[Task_organization]
val <K> DataClassPath<K, Task>.organizationName: DataClassPath<K, String?> get() = this[Task_organizationName]
val <K> DataClassPath<K, Task>.user: DataClassPath<K, UUID?> get() = this[Task_user]
val <K> DataClassPath<K, Task>.userName: DataClassPath<K, String?> get() = this[Task_userName]
val <K> DataClassPath<K, Task>.state: DataClassPath<K, TaskState> get() = this[Task_state]
val <K> DataClassPath<K, Task>.summary: DataClassPath<K, String> get() = this[Task_summary]
val <K> DataClassPath<K, Task>.description: DataClassPath<K, String> get() = this[Task_description]
val <K> DataClassPath<K, Task>.attachments: DataClassPath<K, List<Attachment>> get() = this[Task_attachments]
val <K> DataClassPath<K, Task>.estimate: DataClassPath<K, Int?> get() = this[Task_estimate]
val <K> DataClassPath<K, Task>.emergency: DataClassPath<K, Boolean> get() = this[Task_emergency]
val <K> DataClassPath<K, Task>.priority: DataClassPath<K, Double> get() = this[Task_priority]
val <K> DataClassPath<K, Task>.tags: DataClassPath<K, Set<String>> get() = this[Task_tags]
val <K> DataClassPath<K, Task>.createdAt: DataClassPath<K, Instant> get() = this[Task_createdAt]
val <K> DataClassPath<K, Task>.createdBy: DataClassPath<K, UUID> get() = this[Task_createdBy]
val <K> DataClassPath<K, Task>.creatorName: DataClassPath<K, String?> get() = this[Task_creatorName]
val <K> DataClassPath<K, Task>.pullRequestLinks: DataClassPath<K, Set<String>> get() = this[Task_pullRequestLinks]
inline val Task.Companion.path: DataClassPath<Task, Task> get() = path<Task>()


object Task__id: SerializableProperty<Task, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Task): UUID = receiver._id
    override fun setCopy(receiver: Task, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("_id")
}
object Task_project: SerializableProperty<Task, UUID> {
    override val name: String = "project"
    override fun get(receiver: Task): UUID = receiver.project
    override fun setCopy(receiver: Task, value: UUID) = receiver.copy(project = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("project")
}
object Task_projectName: SerializableProperty<Task, String?> {
    override val name: String = "projectName"
    override fun get(receiver: Task): String? = receiver.projectName
    override fun setCopy(receiver: Task, value: String?) = receiver.copy(projectName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("projectName")
}
object Task_organization: SerializableProperty<Task, UUID> {
    override val name: String = "organization"
    override fun get(receiver: Task): UUID = receiver.organization
    override fun setCopy(receiver: Task, value: UUID) = receiver.copy(organization = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("organization")
}
object Task_organizationName: SerializableProperty<Task, String?> {
    override val name: String = "organizationName"
    override fun get(receiver: Task): String? = receiver.organizationName
    override fun setCopy(receiver: Task, value: String?) = receiver.copy(organizationName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("organizationName")
}
object Task_user: SerializableProperty<Task, UUID?> {
    override val name: String = "user"
    override fun get(receiver: Task): UUID? = receiver.user
    override fun setCopy(receiver: Task, value: UUID?) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("user")
}
object Task_userName: SerializableProperty<Task, String?> {
    override val name: String = "userName"
    override fun get(receiver: Task): String? = receiver.userName
    override fun setCopy(receiver: Task, value: String?) = receiver.copy(userName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("userName")
}
object Task_state: SerializableProperty<Task, TaskState> {
    override val name: String = "state"
    override fun get(receiver: Task): TaskState = receiver.state
    override fun setCopy(receiver: Task, value: TaskState) = receiver.copy(state = value)
    override val serializer: KSerializer<TaskState> = TaskState.serializer()
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("state")
}
object Task_summary: SerializableProperty<Task, String> {
    override val name: String = "summary"
    override fun get(receiver: Task): String = receiver.summary
    override fun setCopy(receiver: Task, value: String) = receiver.copy(summary = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("summary")
}
object Task_description: SerializableProperty<Task, String> {
    override val name: String = "description"
    override fun get(receiver: Task): String = receiver.description
    override fun setCopy(receiver: Task, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("description")
}
object Task_attachments: SerializableProperty<Task, List<Attachment>> {
    override val name: String = "attachments"
    override fun get(receiver: Task): List<Attachment> = receiver.attachments
    override fun setCopy(receiver: Task, value: List<Attachment>) = receiver.copy(attachments = value)
    override val serializer: KSerializer<List<Attachment>> = ListSerializer(Attachment.serializer())
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("attachments")
}
object Task_estimate: SerializableProperty<Task, Int?> {
    override val name: String = "estimate"
    override fun get(receiver: Task): Int? = receiver.estimate
    override fun setCopy(receiver: Task, value: Int?) = receiver.copy(estimate = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("estimate")
}
object Task_emergency: SerializableProperty<Task, Boolean> {
    override val name: String = "emergency"
    override fun get(receiver: Task): Boolean = receiver.emergency
    override fun setCopy(receiver: Task, value: Boolean) = receiver.copy(emergency = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("emergency")
}
object Task_priority: SerializableProperty<Task, Double> {
    override val name: String = "priority"
    override fun get(receiver: Task): Double = receiver.priority
    override fun setCopy(receiver: Task, value: Double) = receiver.copy(priority = value)
    override val serializer: KSerializer<Double> = Double.serializer()
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("priority")
}
object Task_tags: SerializableProperty<Task, Set<String>> {
    override val name: String = "tags"
    override fun get(receiver: Task): Set<String> = receiver.tags
    override fun setCopy(receiver: Task, value: Set<String>) = receiver.copy(tags = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("tags")
}
object Task_createdAt: SerializableProperty<Task, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Task): Instant = receiver.createdAt
    override fun setCopy(receiver: Task, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("createdAt")
}
object Task_createdBy: SerializableProperty<Task, UUID> {
    override val name: String = "createdBy"
    override fun get(receiver: Task): UUID = receiver.createdBy
    override fun setCopy(receiver: Task, value: UUID) = receiver.copy(createdBy = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("createdBy")
}
object Task_creatorName: SerializableProperty<Task, String?> {
    override val name: String = "creatorName"
    override fun get(receiver: Task): String? = receiver.creatorName
    override fun setCopy(receiver: Task, value: String?) = receiver.copy(creatorName = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("creatorName")
}
object Task_pullRequestLinks: SerializableProperty<Task, Set<String>> {
    override val name: String = "pullRequestLinks"
    override fun get(receiver: Task): Set<String> = receiver.pullRequestLinks
    override fun setCopy(receiver: Task, value: Set<String>) = receiver.copy(pullRequestLinks = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Task.serializer().tryFindAnnotations("pullRequestLinks")
}

@file:UseContextualSerialization(Instant::class, UUID::class, ServerFile::class, LocalDate::class)
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)

package com.lightingtime

import com.lightningkite.UUID
import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.lightningdb.*
import com.lightingtime.permissions.UserRole
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.jvm.JvmInline
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningdb.ServerFile, kotlinx.datetime.LocalDate
fun prepareOrganizationFields() {
    val props: Array<SerializableProperty<Organization, *>> = arrayOf(Organization__id, Organization_name, Organization_createdAt)
    Organization.serializer().properties { props }
}
val <K> DataClassPath<K, Organization>._id: DataClassPath<K, UUID> get() = this[Organization__id]
val <K> DataClassPath<K, Organization>.name: DataClassPath<K, String> get() = this[Organization_name]
val <K> DataClassPath<K, Organization>.createdAt: DataClassPath<K, Instant> get() = this[Organization_createdAt]
inline val Organization.Companion.path: DataClassPath<Organization, Organization> get() = path<Organization>()


object Organization__id: SerializableProperty<Organization, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Organization): UUID = receiver._id
    override fun setCopy(receiver: Organization, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Organization.serializer().tryFindAnnotations("_id")
}
object Organization_name: SerializableProperty<Organization, String> {
    override val name: String = "name"
    override fun get(receiver: Organization): String = receiver.name
    override fun setCopy(receiver: Organization, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Organization.serializer().tryFindAnnotations("name")
}
object Organization_createdAt: SerializableProperty<Organization, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Organization): Instant = receiver.createdAt
    override fun setCopy(receiver: Organization, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Organization.serializer().tryFindAnnotations("createdAt")
}

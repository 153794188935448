package com.lightningtime.validation

import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.Theme
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.themeFromLast

open class Validator(var invalidTheme: Theme.() -> Theme = { warning() }) {
    var debug: Console? = null

    val validConditions: Property<Set<ValidCondition>> = Property(emptySet())

    /**
     * Only reads true when no conditions in the invalidConditions list read invalid
     */
    val allValid: Readable<Boolean> = shared {
        val conditions = validConditions()
        val valid = if (conditions.isEmpty()) true
        else conditions.all { it.valid() }

        debug?.log("Calculating allValid")
        debug?.log("List Size: ${validConditions.value.size}")
        debug?.log("Result: $valid")

        return@shared valid
    }

    /**
     * Creates an InvalidCondition and adds it to the invalidConditions list.
     */
    fun addCondition(condition: Readable<Boolean>): ValidCondition {
        val cond = ValidCondition(condition)
        validConditions.value += cond
        return cond
    }
    fun addCondition(condition: suspend CalculationContext.() -> Boolean): ValidCondition {
        val cond = ValidCondition( shared { condition() } )
        validConditions.value += cond
        return cond
    }

    fun removeCondition(condition: ValidCondition) {
        validConditions.value -= condition
    }

    fun removeCondition(validated: Validated) {
        validConditions.value -= validated.valid
    }
    suspend fun removeCondition(validated: Readable<Validated>) {
        validConditions.value -= validated.awaitOnce().valid
    }

    fun clearConditions() {
        validConditions.value = emptySet()
    }

    @ViewModifierDsl3
    fun ViewWriter.validate(validCondition: ValidCondition): ViewWrapper = themeFromLast { theme ->
        if (validCondition.valid()) theme
        else invalidTheme(theme)    // TODO: This should be changed to invalid once invalid themes are added to KiteUI themes
    }

    fun <T> Writable<T>.validate(
        startsValid: Boolean = false,
        validCondition: suspend (T) -> Boolean
    ): ValidatedWritable<T> = withValidation(this@Validator, startsValid, validCondition)

    inner class DynamicValidator {
        val dynamicConditions = ArrayList<Validated>()

        fun refreshDynamicConditions() {
            dynamicConditions.forEach { it.removeCondition() }
            dynamicConditions.clear()
        }

        fun <V: Validated> V.dynamic(): V = also { dynamicConditions.add(this@dynamic) }
    }

    fun <T> dynamicValidation(block: DynamicValidator.() -> T): T = DynamicValidator().block()

    fun <T> Writable<T>.validateNotNull(startsValid: Boolean = false) = validate(startsValid) { it != null }
}

fun validating(block: Validator.() -> Unit) = Validator().block()
@file:SharedCode
package com.lightningtime.sdk

import com.lightningkite.khrysalis.SharedCode
import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import kotlinx.datetime.*
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import kotlin.String
import kotlin.Unit
import com.lightningkite.lightningserver.auth.old.EmailPinLogin
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightingtime.Comment
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.lightningdb.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningdb.CollectionUpdates
import com.lightingtime.Organization
import com.lightingtime.Project
import com.lightingtime.Task
import com.lightingtime.TimeEntry
import com.lightingtime.Timer
import com.lightingtime.User
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import kotlin.Boolean
import com.lightningkite.lightningserver.auth.proof.SecretMetadata
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.subject.Session
import com.lightningkite.lightningserver.auth.proof.OtpSecret

open class AbstractAnonymousSession(val api: Api) {
    val comment: AbstractAnonymousSessionCommentApi = AbstractAnonymousSessionCommentApi(api.comment)
    val organization: AbstractAnonymousSessionOrganizationApi = AbstractAnonymousSessionOrganizationApi(api.organization)
    val project: AbstractAnonymousSessionProjectApi = AbstractAnonymousSessionProjectApi(api.project)
    val task: AbstractAnonymousSessionTaskApi = AbstractAnonymousSessionTaskApi(api.task)
    val timeEntry: AbstractAnonymousSessionTimeEntryApi = AbstractAnonymousSessionTimeEntryApi(api.timeEntry)
    val timer: AbstractAnonymousSessionTimerApi = AbstractAnonymousSessionTimerApi(api.timer)
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val auth: AbstractAnonymousSessionAuthApi = AbstractAnonymousSessionAuthApi(api.auth)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val oneTimePasswordProof: AbstractAnonymousSessionOneTimePasswordProofApi = AbstractAnonymousSessionOneTimePasswordProofApi(api.oneTimePasswordProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    val otpSecretForUser: AbstractAnonymousSessionOtpSecretForUserApi = AbstractAnonymousSessionOtpSecretForUserApi(api.otpSecretForUser)
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    open class AbstractAnonymousSessionCommentApi(val api: Api.CommentApi) {
    }
    open class AbstractAnonymousSessionOrganizationApi(val api: Api.OrganizationApi) {
    }
    open class AbstractAnonymousSessionProjectApi(val api: Api.ProjectApi) {
    }
    open class AbstractAnonymousSessionTaskApi(val api: Api.TaskApi) {
    }
    open class AbstractAnonymousSessionTimeEntryApi(val api: Api.TimeEntryApi) {
    }
    open class AbstractAnonymousSessionTimerApi(val api: Api.TimerApi) {
    }
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
    }
    open class AbstractAnonymousSessionAuthApi(val api: Api.AuthApi) {
        suspend fun anonymousToken(): String = api.anonymousToken()
        suspend fun emailLoginLink(input: String): Unit = api.emailLoginLink(input)
        suspend fun emailPINLogin(input: EmailPinLogin): String = api.emailPINLogin(input)
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi) {
        suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi) {
        suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi) {
        suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        suspend fun openSession(input: String): String = api.openSession(input)
        suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
    open class AbstractAnonymousSessionOtpSecretForUserApi(val api: Api.OtpSecretForUserApi) {
    }
}

abstract class AbstractUserSession(api: Api, userToken: String, userAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val userToken: String
    abstract val userAccessToken: suspend () -> String
    val comment: UserSessionCommentApi = UserSessionCommentApi(api.comment, userToken, userAccessToken)
    val organization: UserSessionOrganizationApi = UserSessionOrganizationApi(api.organization, userToken, userAccessToken)
    val project: UserSessionProjectApi = UserSessionProjectApi(api.project, userToken, userAccessToken)
    val task: UserSessionTaskApi = UserSessionTaskApi(api.task, userToken, userAccessToken)
    val timeEntry: UserSessionTimeEntryApi = UserSessionTimeEntryApi(api.timeEntry, userToken, userAccessToken)
    val timer: UserSessionTimerApi = UserSessionTimerApi(api.timer, userToken, userAccessToken)
    val user: UserSessionUserApi = UserSessionUserApi(api.user, userToken, userAccessToken)
    val auth: UserSessionAuthApi = UserSessionAuthApi(api.auth, userToken, userAccessToken)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, userToken, userAccessToken)
    val oneTimePasswordProof: UserSessionOneTimePasswordProofApi = UserSessionOneTimePasswordProofApi(api.oneTimePasswordProof, userToken, userAccessToken)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, userToken, userAccessToken)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, userToken, userAccessToken)
    val otpSecretForUser: UserSessionOtpSecretForUserApi = UserSessionOtpSecretForUserApi(api.otpSecretForUser, userToken, userAccessToken)
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth(userAccessToken)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    class UserSessionCommentApi(val api: Api.CommentApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Comment, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Comment, UUID> {
        override suspend fun default(): Comment = api.default(userAccessToken)
        override suspend fun query(input: Query<Comment>): List<Comment> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Comment>): List<Partial<Comment>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Comment = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Comment>): List<Comment> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Comment): Comment = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Comment): Comment = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Comment>): List<Comment> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Comment): Comment = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Comment>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Comment>): EntryChange<Comment> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Comment>): Comment = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Comment>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Comment>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Comment>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Comment>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Comment>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Comment>, CollectionUpdates<Comment, UUID>> =
            api.updates(userToken)
    }
    class UserSessionOrganizationApi(val api: Api.OrganizationApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Organization, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Organization, UUID> {
        override suspend fun default(): Organization = api.default(userAccessToken)
        override suspend fun query(input: Query<Organization>): List<Organization> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Organization>): List<Partial<Organization>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Organization = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Organization>): List<Organization> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Organization): Organization = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Organization): Organization = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Organization>): List<Organization> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Organization): Organization = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Organization>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Organization>): EntryChange<Organization> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Organization>): Organization = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Organization>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Organization>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Organization>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Organization>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Organization>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Organization>, CollectionUpdates<Organization, UUID>> =
            api.updates(userToken)
    }
    class UserSessionProjectApi(val api: Api.ProjectApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Project, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Project, UUID> {
        override suspend fun default(): Project = api.default(userAccessToken)
        override suspend fun query(input: Query<Project>): List<Project> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Project>): List<Partial<Project>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Project = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Project>): List<Project> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Project): Project = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Project): Project = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Project>): List<Project> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Project): Project = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Project>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Project>): EntryChange<Project> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Project>): Project = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Project>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Project>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Project>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Project>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Project>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Project>, CollectionUpdates<Project, UUID>> =
            api.updates(userToken)
    }
    class UserSessionTaskApi(val api: Api.TaskApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Task, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Task, UUID> {
        override suspend fun default(): Task = api.default(userAccessToken)
        override suspend fun query(input: Query<Task>): List<Task> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Task>): List<Partial<Task>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Task = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Task>): List<Task> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Task): Task = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Task): Task = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Task>): List<Task> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Task): Task = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Task>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Task>): EntryChange<Task> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Task>): Task = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Task>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Task>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Task>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Task>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Task>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Task>, CollectionUpdates<Task, UUID>> = api.updates(userToken)
    }
    class UserSessionTimeEntryApi(val api: Api.TimeEntryApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<TimeEntry, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<TimeEntry, UUID> {
        override suspend fun default(): TimeEntry = api.default(userAccessToken)
        override suspend fun query(input: Query<TimeEntry>): List<TimeEntry> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<TimeEntry>): List<Partial<TimeEntry>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): TimeEntry = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<TimeEntry>): List<TimeEntry> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: TimeEntry): TimeEntry = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: TimeEntry): TimeEntry = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<TimeEntry>): List<TimeEntry> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: TimeEntry): TimeEntry = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<TimeEntry>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<TimeEntry>): EntryChange<TimeEntry> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<TimeEntry>): TimeEntry = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<TimeEntry>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<TimeEntry>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<TimeEntry>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<TimeEntry>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<TimeEntry>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<TimeEntry>, CollectionUpdates<TimeEntry, UUID>> =
            api.updates(userToken)
    }
    class UserSessionTimerApi(val api: Api.TimerApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Timer, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Timer, UUID> {
        override suspend fun default(): Timer = api.default(userAccessToken)
        override suspend fun query(input: Query<Timer>): List<Timer> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Timer>): List<Partial<Timer>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Timer = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Timer>): List<Timer> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Timer): Timer = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Timer): Timer = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Timer>): List<Timer> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Timer): Timer = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Timer>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Timer>): EntryChange<Timer> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Timer>): Timer = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Timer>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Timer>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Timer>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Timer>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Timer>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Timer>, CollectionUpdates<Timer, UUID>> =
            api.updates(userToken)
    }
    class UserSessionUserApi(val api: Api.UserApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<User, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<User, UUID> {
        override suspend fun default(): User = api.default(userAccessToken)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): User = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: User): User = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<User>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<User>, CollectionUpdates<User, UUID>> = api.updates(userToken)
    }

    class UserSessionAuthApi(val api: Api.AuthApi, val userToken: String, val userAccessToken: suspend () -> String) {
        suspend fun refreshToken(): String = api.refreshToken(userAccessToken)
        suspend fun getSelf(): User = api.getSelf(userAccessToken)
        suspend fun anonymousToken(): String = api.anonymousToken()
        suspend fun emailLoginLink(input: String): Unit = api.emailLoginLink(input)
        suspend fun emailPINLogin(input: EmailPinLogin): String = api.emailPINLogin(input)
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val userToken:String, val userAccessToken: suspend () -> String) {
        suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class UserSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val userToken:String, val userAccessToken: suspend () -> String) {
        suspend fun establishAnOneTimePassword(input: EstablishOtp): String = api.establishAnOneTimePassword(input)
        suspend fun confirmOneTimePassword(input: String): Unit = api.confirmOneTimePassword(input)
        suspend fun disableOneTimePassword(): Boolean = api.disableOneTimePassword()
        suspend fun checkOneTimePassword(): SecretMetadata? = api.checkOneTimePassword()
        suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val userToken:String, val userAccessToken: suspend () -> String) {
        suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        suspend fun openSession(input: String): String = api.openSession(input)
        suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, userAccessToken)
        suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        suspend fun getSelf(): User = api.getSelf(userAccessToken)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun terminateSession(): Unit = api.terminateSession(userAccessToken)
        suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, userAccessToken)
    }
    class UserSessionOtpSecretForUserApi(val api: Api.OtpSecretForUserApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<OtpSecret<UUID>, UUID> {
        override suspend fun query(input: Query<OtpSecret<UUID>>): List<OtpSecret<UUID>> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<OtpSecret<UUID>>): List<Partial<OtpSecret<UUID>>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): OtpSecret<UUID> = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<OtpSecret<UUID>>): List<OtpSecret<UUID>> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: OtpSecret<UUID>): OtpSecret<UUID> = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: OtpSecret<UUID>): OtpSecret<UUID> = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<OtpSecret<UUID>>): List<OtpSecret<UUID>> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: OtpSecret<UUID>): OtpSecret<UUID> = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<OtpSecret<UUID>>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret<UUID>>): EntryChange<OtpSecret<UUID>> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<OtpSecret<UUID>>): OtpSecret<UUID> = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<OtpSecret<UUID>>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<OtpSecret<UUID>>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret<UUID>>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<OtpSecret<UUID>>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret<UUID>>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
}


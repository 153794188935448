package com.lightningtime.draftModel

import com.lightningkite.kiteui.reactive.*

interface Draft<C> {
    val original: Readable<C>
    val current: Readable<C>
    val changesMade: Readable<Boolean> get() = shared { original() != current() }
    fun cancel()
    suspend fun commit()
}

interface DraftWritable<T, V>: Draft<T>, Writable<V> {
    val writable: Writable<V>

    override val state: ReadableState<V> get() = writable.state
    override fun addListener(listener: () -> Unit): () -> Unit = writable.addListener(listener)
    override suspend fun set(value: V) = writable.set(value)
}
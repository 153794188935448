package com.lightningtime.views.screens.auth

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningtime.sdk.selectedApi

interface AuthScreen

@Routable("/auth/login")
class LoginScreen: Screen, AuthScreen {

    val email = Property("")

    override fun ViewWriter.render() {
        suspend fun sendEmail() {
            val passcodeToken = selectedApi().api.emailProof.beginEmailOwnershipProof(email())

            navigator.navigate(PasscodeScreen().apply {
                enteredEmail = email()
                this@apply.passcodeToken = passcodeToken
            })
        }
        stack {
            atTopEnd - button {
                centered - h6 { ::content { selectedApi().name } }

                onClick {
                    selectedApi.value = selectedApi.awaitOnce().next()
                }
            }

            centered - padded - card - sizeConstraints(width = 28.rem) - col {
                spacing = 1.5.rem

                bold - h2("Lightning Time")

                text {
                    content = "We'll send you a code to sign in and get started!"
                }

                fieldTheme - textField {
                    hint = "Email"
                    keyboardHints = KeyboardHints.email
                    content bind email

                    action = Action(
                        title = "Log In",
                        icon = Icon.login,
                        onSelect = ::sendEmail
                    )
                }

                important - button {
                    spacing = 0.5.rem
                    centered - text("Send Code")
                    onClick(::sendEmail)
                }
            }
        }
    }
}
package com.lightningtime.views.components.queries

import com.lightingtime.*
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.debounce
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.lightningdb.*

sealed interface SearchField<T> {
    val displayName: String
    val condition: (DataClassPath<T, T>, String) -> Condition<T>

    enum class Projects(
        override val condition: (DataClassPath<Project, Project>, String) -> Condition<Project>
    ): SearchField<Project> {
        Name ({ p, q -> p.name.contains(q) }),
        Tags ({ p, q -> p.projectTags.any { it.contains(q) } }),
        Notes ({ p, q -> p.notes.contains(q) })

        ;
        override val displayName: String = name
    }

    enum class Tasks(
        override val condition: (DataClassPath<Task, Task>, String) -> Condition<Task>
    ): SearchField<Task> {
        Summary ({ p, q -> p.summary.contains(q) }),
        Tags ({ p, q -> p.tags.any { it.contains(q) } }),
        Description ({ p, q -> p.description.contains(q) })

        ;
        override val displayName: String = name
    }

    enum class TimeEntries(
        override val condition: (DataClassPath<TimeEntry, TimeEntry>, String) -> Condition<TimeEntry>
    ): SearchField<TimeEntry> {
        TaskSummary ({ p, q -> p.taskSummary.notNull.contains(q) }) {
            override val displayName: String = "Task Summary"
        },
        Summary ({ p, q -> p.summary.contains(q) }),
        User ({ p, q -> p.userName.notNull.contains(q) })

        ;
        override val displayName: String = name
    }

    enum class Comments(
        override val condition: (DataClassPath<Comment, Comment>, String) -> Condition<Comment>
    ): SearchField<Comment> {
        Content ({ p, q -> p.content.contains(q) }),
        User ({ p, q -> p.userName.notNull.contains(q) })
        ;

        override val displayName: String = name
    }
}

suspend inline fun <T> Readable<String>.ifNotBlank(milliseconds: Long = 500, block: (String) -> T): T? =
    debounce(milliseconds)().takeUnless { it.isBlank() }?.let(block)

suspend inline fun <reified T, SEARCH: SearchField<T>> Readable<String>.queryCondition(
    field: Readable<SEARCH>
): Condition<T>? =
    ifNotBlank { query ->
        condition { field().condition(it, query) }
    }
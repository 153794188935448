package com.lightningtime.theming

import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.h6
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.l2.icon
import com.lightningtime.draftModel.LazyProperty
import com.lightningtime.sdk.currentSession
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.time.Duration.Companion.seconds

object AppColors {
    val lightningYellow = Color.fromHexString("FBB811")
    val twilightViolet = Color.fromHexString("11101c")
    val dangerColor = Color.red
    fun dangerAverage(color: Color): Color = Color.interpolate(dangerColor, color, 0.5f)
}

val appAccentColor = shared {
    currentSession.await()?.self()?.themeColor?.let { Color.fromHexString(it) } ?: AppColors.lightningYellow
}

val appTheme = shared {
    currentSession.await()?.self()?.theme?.let { theme -> AppThemes.entries.find { it.name == theme } }
        ?: AppThemes.Base
}

data class AppTheme (
    val name: String,
    val icon: Icon,
    val accent: Readable<Color> = appAccentColor,
    val theme: (accent: Color) -> Theme,
) {
    /*
    * DO NOT MAKE THIS A READABLE. If you make it a readable by shared or some other mechanism it ends up filtering out
    * changes to the accent, since it judges equality of themes by their internal properties.
    * */
    suspend fun await(): Theme = theme(accent.await())
    suspend operator fun invoke(): Theme = await()

    fun alterTheme(calculation: Theme.(accent: Color) -> Theme): (Color) -> Theme = { theme(it).calculation(it) }

    fun alter(
        title: String,
        icon: Icon,
        accent: Readable<Color> = appAccentColor,
        themeAlteration: Theme.(accent: Color) -> Theme
    ) = AppTheme(title, icon, accent, theme = alterTheme(themeAlteration))

    fun ViewWriter.render() {
        row {
            icon(icon, "${name.removeSuffix(" Theme")} Theme Option")

            centered - h6(name)
        } in setTheme { await().copy(iconOverride = accent()) }
    }
}

object AppThemes {
    val Base = AppTheme("Dark Theme", Icon.pin) { accent ->
        Theme(
            background = AppColors.twilightViolet,
            foreground = Color.gray(0.9f),
            cornerRadii = CornerRadii.RatioOfSpacing(0.3f),
            transitionDuration = 0.1.seconds,

            bar = {
                copy(
                    background = background.closestColor().highlight(0.075f)
                )
            },
            nav = {
                copy(
                    outlineWidth = 0.1.rem,
                    outline = background.closestColor().highlight(0.075f)
                )
            },

            unselected = {
                lightOutlineTheme(this).copy(
                    elevation = 0.rem
                )
            },
            selected = {
                copy(
                    background = Color.interpolate(background.closestColor(), accent, 0.4f),
                    elevation = elevation / 2f,
                )
            },

            card = {
                copy(
                    background = background.closestColor().highlight(0.1f)
                )
            },

            danger = {
                copy(
                    background = AppColors.dangerColor
                )
            },

            warning = {
                copy(
                    outline = AppColors.dangerAverage(accent),
                    outlineWidth = 1.px,
                )
            },

            field = {
                copy(
                    outline = accent,
                    outlineWidth = 1.px,
                    background = background.closestColor().darken(0.1f)
                )
            },

            important = {
                copy(
                    foreground = background.closestColor().darken(0.2f),
                    background = Color.interpolate(accent, foreground.closestColor(), 0.2f)
                )
            }
        )
    }

    val Darker = Base.alter("Dark High Contrast", Icon.darkMode) {
        copy(
            background = Color.black,

            card = {
                copy(
                    background = background.closestColor().highlight(0.12f)
                )
            }
        )
    }

    val Light = Base.alter("Light Theme", Icon.lightMode) { accent ->
        copy(
            background = Color.gray(0.9f),
            foreground = Color.black,

            card = {
                copy(
                    background = background.closestColor().highlight(0.075f)
                )
            },

            important = {
                copy(
                    background = accent
                )
            },

            field = {
                copy(
                    outline = accent,
                    outlineWidth = 2.px,
                    background = background.closestColor().lighten(0.2f)
                )
            }
        )
    }

    val entries = listOf(Base, Darker, Light)
}